// import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Component, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FloatLabelType } from '@angular/material/form-field';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { PATTERNS } from 'src/app/constants/constants/constants';
import { AddDialogboxComponent } from 'src/app/shared/component/add-dialogbox/add-dialogbox.component';
import { ViewDialogboxComponent } from 'src/app/shared/component/view-dialogbox/view-dialogbox.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import * as XLSX from 'xlsx';
import { OrderModel, StatusModel } from '../../master/constants/constants';
import { PageEvent } from '@angular/material/paginator';
import * as moment from 'moment';
import * as feather from 'feather-icons';
import { CreateUserComponent } from 'src/app/setting/create-user/create-user.component';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent {


  dataSource = new MatTableDataSource<userTable>();
  allCheckedChange: Subject<boolean> = new Subject<boolean>();
  allChecked: boolean = false;
  FilterForm = new FormGroup({
    OrderStatus: new FormControl(''),
    OrderNumber: new FormControl(''),
    ExOrdernumber: new FormControl(''),
    dateRange: new FormControl('')
  });
  floatLabel: FloatLabelType = 'auto';
  // floatLabel: FloatLabelType = 'auto' as FloatLabelType;


  activeClass: boolean = false;
  orderData: any = [];
  showFilter: any;

  length = 50;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = false;
  showFirstLastButtons = false;
  disabled = false;

  pageEvent: any;

  userData: any = [];

  fromDateObj: any = {};
  toDateObj: any = {};
  customDate: any;
  AllStatusList: any = [];
  filterOrderstatus: any;
  allCheckedOrderNumbers: string = '';
  activeParam: any;
  pendingParam: any;
  loader: boolean = false;
  getpayload: any = []
  PATTERNS = PATTERNS
  userForm: FormGroup | any;


  constructor(public dialog: MatDialog, private renderer: Renderer2, private auth: AuthService, private datePipe: DatePipe,
    private route: ActivatedRoute, private fb: FormBuilder) {
    this.allCheckedChange.subscribe((value: boolean) => {
      this.allChecked = value;
    });

    this.userForm = this.fb.group({
      UserName: ['', Validators.required],
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      // userType: ['', Validators.required],
      Email: ['', [Validators.email, Validators.required]],
      Contact: ['', [Validators.required, Validators.pattern(/^[1-9]{1}[0-9]{9}$/)]],
      // orgId: ['', Validators.required],
      Status: ['', Validators.required],
      RoleId: ['', Validators.required],
      UserType: ['', Validators.required],
      OrganizationId: ['', Validators.required]
    });
  }

  getUser() {
    this.loader = true;
    this.auth.getUserDetail().subscribe((data:any) => {
      // console.log(data);
      if (data != null) {
        this.userData = data;
       
      }
      this.loader = false;
    })
  }

  ngOnInit(): void {
    // this.getOderData(this.getpayload, this.pageIndex, this.pageSize);

    // this.getUser();
    this.getUserList(this.obj, this.pageIndex, this.pageSize);
  }

  userList: any;
   obj = [{
    "key": "PLATFORM",
    "value": "ptl"
   }];

  // constructor(public dialog: MatDialog, private AuthService: AuthService) {}

 
  addUser() {
    // console.log(oderData)
    const dialogRef = this.dialog.open(CreateUserComponent, {
      // data: { Orderdata: 'oderData' },
      panelClass: 'custom-modalbox',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.getUserList(this.obj, this.pageIndex, this.pageSize);

    });
  }

  getUserList(getpayload: {}, pageIndex: number, pageSize: number) {
    this.auth.getUserList(getpayload, pageIndex, pageSize).subscribe(
      (res:any) => {
        this.userList = res?.responseObject
        console.log(this.userList);
        this.length = res?.responseObject?.totalElements
      }
    );
  }

  editUser(user:any){
    console.log(user)
    const dialogRef = this.dialog.open(CreateUserComponent, {
      data: { userData: user , edituser: true},
      panelClass: 'custom-modalbox',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.getUserList(this.obj, this.pageIndex, this.pageSize);

    });
  }

  
pageNavigations(e: PageEvent) {
  // console.log(this.activeParam, this.pendingParam)
  this.pageEvent = e;
  this.length = e.length;
  this.pageSize = e.pageSize;
  this.pageIndex = e.pageIndex;
  console.log(this.pageEvent, this.length, this.pageIndex, this.pageIndex)
  // this.allChecked = false;
  this.getUserList(this.obj, this.pageIndex, this.pageSize);

  // this.getOderData(obj, this.pageIndex, this.pageSize);


}
  ngAfterViewInit() {
    feather.replace();
  }

}
export interface userTable {
  userId: any;
  userName: any;
  firstName: any;
  lastName: any;
  userType: any;
  contactNo: any;
  email: any;
  organizationId: any;
  activeInd: any;
  createdBy: any;
  createdOn: any;
  modifiedBy: any;
  modifiedOn: any;
}



