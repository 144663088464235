import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CamelcasePipe } from '../camelcase.pipe';
import { Date } from '../date.pipe';
import { TimePipe } from '../time.pipe';
import { CustomDatePipe } from '../datepipe.pipe';
import { DatetimePipe } from './datetime.pipe';



@NgModule({
  declarations: [CamelcasePipe, Date, TimePipe, CustomDatePipe, DatetimePipe],
  imports: [
    CommonModule
  ],
  exports:[CamelcasePipe, Date, TimePipe, CustomDatePipe]
})
export class PipeModule { }
