
import { Injectable } from '@angular/core';

import { Configuration } from 'src/app/constants/constants/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { error } from 'jquery';
import { environment } from 'src/environments/environment';
import * as feather from 'feather-icons';

@Injectable({
  providedIn: 'root'
})
export class RolebaseService {
  private activeModules: string[] = [];
  private activeSubmodules: string[] = [];

  constructor(private http: HttpClient, private API_URL: Configuration) {
    feather.icons
    feather.replace()
  }

  fetchRoleList(): void {
    const role = localStorage.getItem('role');
    const url = `${environment.LOGIN_DOMAIN}${this.API_URL.CONFIG_URL.ROLE_MAPING}?platform=ptl`;
    this.activeModules = []
    this.activeSubmodules = []
    this.http.get<any>(url).subscribe((res: any) => {
        if (res?.responseObject?.modules) {
          const modules = res.responseObject.modules;
          modules.forEach((element: any) => {
            if (
              element.moduleName === 'Master' ||
              element.moduleName === 'Journey Management' ||
              element.moduleName === 'Reports' ||
              element.moduleName === 'Settings'
              ) {
              this.activeModules.push(element.moduleName);
            }
            element.subModules.forEach((item: any) => {
              if (
                item.subModuleName === 'Customer' ||
                item.subModuleName === 'Vendor' ||
                item.subModuleName === 'Customer Mapping' ||
                item.subModuleName === 'Docket Status' ||
                item.subModuleName === 'Customer MIS' ||
                item.subModuleName === 'ASC MIS' ||
                item.subModuleName === 'User'
              ) {
                this.activeSubmodules.push(item.subModuleName);
              }
            });
          });
        }
      },
      (error) => {
        console.error('Failed to fetch role list', error);
      }
    );
  }

  isActiveModule(moduleName: string): boolean {
    // console.log(moduleName)
    return this.activeModules.includes(moduleName);
  }

  isActiveSubmodule(submoduleName: string): boolean {
    // console.log('Orders Report',submoduleName)
    return this.activeSubmodules.includes(submoduleName);
  }
}
