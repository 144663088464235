import { HttpClient, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileUploader } from 'ng2-file-upload';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import * as feather from 'feather-icons';
import { SnackbarComponent } from 'src/app/shared/component/snackbar/snackbar.component';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { param } from 'jquery';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { MasterService } from 'src/app/shared/services/master.service';

@Component({
  selector: 'app-add-vendor-files',
  templateUrl: './add-vendor-files.component.html',
  styleUrls: ['./add-vendor-files.component.css']
})
export class AddVendorFilesComponent {
  durationInSeconds = 3;
  selectedFile: any;
  uploadForm: FormGroup | any;
  uploader: FileUploader | any;
  hasBaseDropZoneOver: any;
  hasAnotherDropZoneOver: any;
  response: any;
  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  loader: boolean = false;
  loaders: boolean = false;
  fileName = 'Select File';

  startDate: any;
  endDate: any;
  customStartDate: any | null = null;
  customEndDate: any | null = null;

  fromDateObj: any = {};
  toDateObj: any = {};
  customDate: any;

  vendorBranchDetails: any[] | any;
  vendorData: any = [];
  vendorName: any = [];
  customerData: any = [];
  selectedCustomerBranches: any[] = [];
  customerBranches: any[] = [];
  selectedCustomer: any;

  selectedVendorBranch: any;
  selectedCustomerBranch: any;
  custBranchName: any;
  vendorBranchName: any;
  custName: any;
  vendorId: any;
  createdBy: any;

  vendorBranchIds: any;

  pageSize = 10;
  pageIndex = 0;

  vendorFilter: any = [];
  customerFilter: any = [];
  vendorDetails: any;
  constructor(private auth: AuthService, private http: HttpClient, private snackbarService: SnackbarService,
    private errorHandlingService: ErrorHandlingService, private cdr: ChangeDetectorRef, private datePipe: DatePipe,
    private route: ActivatedRoute, private masterService: MasterService) {
    feather.replace();
    this.uploadForm = new FormGroup({
      dateRange: new FormControl('', [Validators.required]),
      fileType: new FormControl('', [Validators.required]),
      fileUpload: new FormControl('', [Validators.required]),
      vendorBranch: new FormControl('', [Validators.required]),
      customer: new FormControl('', [Validators.required]),
      customerBranch: new FormControl('', [Validators.required])
    });
  }

  getVendorBranchList() {
    this.masterService.getVendorDetails(this.pageIndex, 200, this.vendorFilter).subscribe((data: any) => {
      if (data != null) {
        this.vendorData = data?.responseObject?.content;
        console.log(data);
        const vendorDetails = data?.responseObject?.content.find((vendor: any) => vendor.id === this.vendorId);
        if (vendorDetails) {
          this.vendorName = [vendorDetails.vendorName];
          console.log(this.vendorName)
          this.vendorBranchDetails = vendorDetails.branchDetailsList;
          console.log(this.vendorBranchDetails);
        } else {
          console.log("Vendor not found");
        }
      }
      this.loaders = false;
    });
  }

  getCustomerList() {
    this.masterService.getCustomerDetails(this.pageIndex, 200, this.customerFilter).subscribe((data) => {
      // console.log(data);
      if (data != null) {
        this.customerData = data?.responseObject?.content;
      }
      this.loaders = false;
    })
  }


  onCustomerChange(customerId: any): void {
    // debugger
    console.log('Customer selection changed:', customerId);

    const selectedCustomer = this.customerData?.find((customer: any) => customer.id === customerId);

    if (selectedCustomer) {
      this.customerBranches = selectedCustomer.branchDetailsList;
    } else {
      this.customerBranches = [];
    }
  }



  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }

  openSnackBar(message: string) {
    this.snackbarService.showSnackbar(message);
  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.vendorId = + params['id'];
      // this.createdBy = + params['createdBy'];

    });
    this.loaders = true;
    this.getVendorBranchList();
    this.getCustomerList();
  }

  // selectedVendorBranch: any[] = []; // Initialize selectedVendorBranch as an array

  onVendorBranchSelectionChange() {
    // If "All" is selected, select all branches
    if (this.selectedVendorBranch.includes(0)) {
      // this.selectedVendorBranch = [0];
      this.vendorBranchIds = '0';
    } else {
      // If "All" is not selected, remove it from selection
      // const index = this.selectedVendorBranch.indexOf(0);
      // if (index !== -1) {
      //     this.selectedVendorBranch.splice(index, 1);
      // }
      this.vendorBranchIds = this.selectedVendorBranch.join(',');
    }
  }

  onCustomerSelectionChange() {
    if (this.selectedCustomer.includes(0)) {
      this.selectedCustomer = '0';
    } else {
      this.selectedCustomer = this.selectedCustomer.join(',');
    }
  }

  onCustomerBranchSelectionChange() {
    const allSelected = this.selectedCustomerBranch.includes(0);

    if (allSelected) {
      this.selectedCustomerBranch = [0];
    } else {
      this.selectedCustomerBranch = this.selectedCustomerBranch.filter((branchId: any) => branchId !== 0);
    }
  }


  upload(): void {

    this.createdBy = localStorage.getItem('userId');
    this.loader = true;
    if (this.uploadForm.valid) {
      let fromDate: any;
      let toDate: any;
      const filePayloads: any[] = [];

      // Check if custom date range is selected
      if (this.customStartDate && this.customEndDate) {
        fromDate = this.formatDate(this.customStartDate);
        toDate = this.formatDate(this.customEndDate);
      }

      // Handle "All" option
      // let vendorBranchIds[] = this.selectedVendorBranch.includes(0) ? '0' : this.selectedVendorBranch.join(',');

      if (this.selectedFiles) {
        const filesArray = Array.from(this.selectedFiles);

        const formData: FormData = new FormData();
        formData.append('file', this.selectedFiles[0]);
        console.log(formData)
        // formData.append('filesFor', this.uploadForm.value.fileType);
        // formData.append('fromDates', fromDate);
        // formData.append('toDates', toDate);
        // formData.append('vendorBranchIds', this.vendorBranchIds); // Use updated vendorBranchIds
        // formData.append('custIds', this.selectedCustomer);
        // formData.append('custBranchIds', this.selectedCustomerBranch);
        // formData.append('vendorId', this.vendorId);
        // formData.append('createdBy', '3');

        this.masterService.uploadVendorFiles(formData, this.selectedCustomer, this.vendorId, this.selectedCustomerBranch, this.vendorBranchIds, toDate, fromDate, this.uploadForm.value.fileType).subscribe({
          next: (response: any) => {
            console.log(response);
            this.loader = false;
            const snackbarMessage = 'Files Uploaded Successfully';
            this.openSnackBar(snackbarMessage);
            this.uploadForm.reset();
          },
          error: (err: any) => {
            console.log(err);
            const snackbarMessage = 'File Upload Failed';
            this.openSnackBar(snackbarMessage);
            if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.message = 'Could not upload the file!';
            }
            this.loader = false;
            this.currentFile = undefined;
          },
        });
      }
    }
    else {
      this.loader = false;
      this.markFormGroupTouched(this.uploadForm);
      const snackbarMessage = 'please enter details'
      this.openSnackBar(snackbarMessage);

    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  ngAfterViewInit() {

  }

  dateOptionsList = [
    {
      viewValue: 'Today',
      value: 'today'
    },
    {
      viewValue: 'Yesterday',
      value: 'yesterday'
    },
    {
      viewValue: 'Last 7 days',
      value: 'last7days'
    },
    {
      viewValue: 'Last 30 days',
      value: 'last30days'
    },
    {
      viewValue: 'This month',
      value: 'thismonth'
    },
    {
      viewValue: 'Last month',
      value: 'lastmonth'
    }
  ];

  /**@description Method To Autofill Dates*/
  public onChangeCondition(dateKey: any) {
    console.log('Selected value:', dateKey);

    let fromDate: any;
    let toDate: any;
    // this.filterOptionsList.filter(x => x.value === filterCondition)[0].customDates = {};
    switch (dateKey) {
      case 'today':
        fromDate = new Date();
        toDate = new Date(); // Assign current date to both fromDate and toDate
        break;
      case 'yesterday':
        fromDate = new Date(
          moment()
            .subtract(1, 'days')
            .startOf('day')
            .valueOf()
        );
        toDate = new Date(
          moment()
            .subtract(1, 'days')
            .startOf('day')
            .valueOf()
        );
        break;
      case 'last7days':
        fromDate = new Date(
          moment()
            .subtract(6, 'days')
            .startOf('day')
            .valueOf()
        );
        toDate = new Date();
        break;
      case 'last30days':
        fromDate = new Date(
          moment()
            .subtract(29, 'days')
            .startOf('day')
            .valueOf()
        );
        toDate = new Date();
        break;
      case 'thismonth':
        fromDate = new Date(
          moment()
            .startOf('month')
            .valueOf()
        );
        toDate = new Date();
        break;
      case 'lastmonth':
        fromDate = new Date(
          moment()
            .subtract(1, 'months')
            .startOf('month')
            .valueOf()
        );
        toDate = new Date(
          moment()
            .subtract(1, 'months')
            .endOf('month')
            .valueOf()
        );
        break;

      case 'custom': // Added case for custom date range
        // Call handleCustomDateRange
        this.handleCustomDateRange(this.startDate, this.endDate);
        return;
    }

    // Transform dates to the desired format
    const FROM_DATE = this.datePipe.transform(fromDate, 'yyyy-MM-dd Z', '+0530');
    const TO_DATE = this.datePipe.transform(toDate, 'yyyy-MM-dd Z', '+0530');

    // Update fromDateObj and toDateObj
    this.fromDateObj = FROM_DATE;
    this.toDateObj = TO_DATE;

    // Call handleCustomDateRange to handle custom date range
    this.handleCustomDateRange(fromDate, toDate);

    console.log("FROM_DATE", FROM_DATE, "TO_DATE", TO_DATE);
    console.log("FROM_DATE", this.fromDateObj, "TO_DATE", this.toDateObj);
  }

  handleCustomDateRange(fromDate: any, toDate: any) {
    // Set custom start and end dates
    this.customStartDate = fromDate;
    this.customEndDate = toDate;

    console.log(this.customStartDate, this.customEndDate)
    // Call getDocketData with formatted dates
    // this.getDocketData(this.pageIndex, this.pageSize);
  }


  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd') || '';
  }


}
