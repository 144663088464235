
    <div class="overlay" *ngIf="loader">
        <div class="container-loader ">
            <mat-spinner [diameter]="25" [strokeWidth]="2"></mat-spinner>
        </div>
    </div>

<div class="container-fluid">

    <div class="card scroll-main" >
        
            <div class="card-body">
                <div class="row mt-2">
                    <div class="col">
                        <div class="page-title">
                            <h4 class="page-header d-flex justify-content-start">Tracking</h4>
                         </div>
                    </div>
                    <div class="col">
                        <span class="close-icon d-flex justify-content-end"><img src="../../../../assets/img/icons/close-icon.svg"  (click)="closeDialog()"></span>
                    </div>
                </div>

                <div class="tracking-details">
                 
                    <ul>
                        <li class="row-heading">
                            <span>Docket No.</span>
                            <span>Status</span>
                            <span>Origin</span>
                            <span>Destination</span>
                            <!-- <button (click)="getDocket()">Check</button> -->
                        </li>

                        <li class="row-data">
                            <span>{{trackData?.docketNo}}</span>
                            <span>{{trackData?.docketStatus}}</span>
                            <span>{{trackData?.bookingCity | camelcase}}</span>
                            <span>{{trackData?.deliveryCity | camelcase}}</span>
                        </li>
                    </ul>
                </div>

                <div class="row ">
                    <div class="col-sm-4">
                        <div class="shipment-details">
                            <h2>Shipments Details</h2>
                            <ul>
                                <li>
                                    <b>Docket No.</b>
                                    <span>{{trackData?.docketNo}}</span>
                                </li>
                                <li>
                                    <b>Pickup Date</b>
                                    <span>{{trackData?.pickupDate | date:'dd/MM/yy' }}</span>
                                </li>
                                <li>
                                    <b>Current Docket Status</b>
                                    <span>{{trackData?.docketCurrentStatus | camelcase}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-sm-8 scroll">
                        <div class="tracking-timeline">
                            <h3>Shipment History</h3>

                            <ng-container>
                                <div *ngIf="trackData?.statusDetailsList?.length > 0; else noTrackingMessage">
                                    <div class="tracking"
                                        *ngFor="let item of trackData?.statusDetailsList.slice().reverse()">
                                        <div class="left-icon">
                                            <img [src]="getIcon(item?.vendorStatusMaster?.commonStatusName)">
                                        </div>
                                        <div class="right-content">
                                            <h4>{{item?.vendorStatusMaster?.statusName}} </h4><h3>[{{item?.vendorStatusMaster?.commonStatusName}}] </h3>
                                            <p>{{item?.intransitDate }}  {{item?.intransitTime}}</p>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #noTrackingMessage>
                                    <p>shippment history is unvailable</p>
                                </ng-template>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        
    </div>

</div>