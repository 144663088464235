import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../component/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  // openFromComponent(SnackbarComponent: SnackbarComponent, arg1: { data: { message: string; }; duration: number; }) {
  //   throw new Error('Method not implemented.');
  // }

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private snackBar: MatSnackBar) { }

  showSnackbar(message: string, duration: number = 3000, panelClass: string = '') {
    this.snackBar.open(message, '', {
      duration: duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  showSuccess(message: string, duration: number = 3000) {
    this.showSnackbar(message, duration, 'success-snackbar');
  }

  showError(message: string, duration: number = 3000) {
    this.showSnackbar(message, duration);
  }

  showWarning(message: string, duration: number = 3000) {
    this.showSnackbar(message, duration);
  }
}
