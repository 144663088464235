
<div class="page-wrapper">
	<div class="content">
		<div class="order-details">
			<div class="content p-0">
				<div class="page-header">
					<div class="page-title">
						<h4>Vendor</h4>
					</div>
				</div>

				<div class="card">
					<div class="card-body">
						<div class="table-top position-relative">
							<div class="search-set justify-content-between w-100">
								<div class="search-path page-header ">
									<!-- <a class="btn btn-added" id="filter_search" >
										Search
									</a> -->
									<a class="btn btn-added"  (click)="addVendorForm()">
										<!-- <img src="assets/img/icons/plus.svg" alt="img" class="me-1"> -->
										<svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
										Add Vendor</a>
									<a class="btn btn-cancel ms-3 disabled" >
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12 5V19" stroke="#7367F0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											<path d="M5 12H19" stroke="#7367F0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											</svg>
											
										<!-- <img src="assets/img/icons/plus.svg" alt="img" class="me-1"> -->
										Add Tariff</a>
									<!-- <a class="btn btn-cancel ms-3" (click)="downloadAllExcel()">
										Export
									</a> -->

								</div>
								<div class="pagination search-path page-header d-flex justify-content-end">
                                    <a class="btn btn-added" (click)="downloadAllExcel()" >
										<svg width="18px" height="18px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#fff" transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)" stroke-width="1.056">
											<g id="SVGRepo_bgCarrier" stroke-width="0"/>
											<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#fff" stroke-width="0.672"/>
											<g id="SVGRepo_iconCarrier"> <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M3 14.25C3.41421 14.25 3.75 14.5858 3.75 15C3.75 16.4354 3.75159 17.4365 3.85315 18.1919C3.9518 18.9257 4.13225 19.3142 4.40901 19.591C4.68577 19.8678 5.07435 20.0482 5.80812 20.1469C6.56347 20.2484 7.56459 20.25 9 20.25H15C16.4354 20.25 17.4365 20.2484 18.1919 20.1469C18.9257 20.0482 19.3142 19.8678 19.591 19.591C19.8678 19.3142 20.0482 18.9257 20.1469 18.1919C20.2484 17.4365 20.25 16.4354 20.25 15C20.25 14.5858 20.5858 14.25 21 14.25C21.4142 14.25 21.75 14.5858 21.75 15V15.0549C21.75 16.4225 21.75 17.5248 21.6335 18.3918C21.5125 19.2919 21.2536 20.0497 20.6517 20.6516C20.0497 21.2536 19.2919 21.5125 18.3918 21.6335C17.5248 21.75 16.4225 21.75 15.0549 21.75H8.94513C7.57754 21.75 6.47522 21.75 5.60825 21.6335C4.70814 21.5125 3.95027 21.2536 3.34835 20.6517C2.74643 20.0497 2.48754 19.2919 2.36652 18.3918C2.24996 17.5248 2.24998 16.4225 2.25 15.0549C2.25 15.0366 2.25 15.0183 2.25 15C2.25 14.5858 2.58579 14.25 3 14.25Z" fill="#fff"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 16.75C12.2106 16.75 12.4114 16.6615 12.5535 16.5061L16.5535 12.1311C16.833 11.8254 16.8118 11.351 16.5061 11.0715C16.2004 10.792 15.726 10.8132 15.4465 11.1189L12.75 14.0682V3C12.75 2.58579 12.4142 2.25 12 2.25C11.5858 2.25 11.25 2.58579 11.25 3V14.0682L8.55353 11.1189C8.27403 10.8132 7.79963 10.792 7.49393 11.0715C7.18823 11.351 7.16698 11.8254 7.44648 12.1311L11.4465 16.5061C11.5886 16.6615 11.7894 16.75 12 16.75Z" fill="#CCCCCC"/> </g>
											</svg>
                                       Export <img src="../../../../assets/img/dowload-excel.svg" class="ms-2">
									</a>
                                </div>
							</div>
						</div>

						<div class="table-responsive">
							<ul class="order-data">
								<li class="table-head">
									<!-- <span class="selctorder">
										<ng-container *ngIf="vendorData?.result?.length > 0">
											<mat-checkbox [checked]="allChecked"
											(change)="toggleAllCheckboxes($event.checked)"></mat-checkbox>
										</ng-container>
									</span> -->
									<!-- <span >S.No.</span> -->
									<span class="action-column d-flex justify-content-center">Action</span>
									<span >Vendor Name</span>
									<span >Email</span>
									<span >CW Group Id </span>
									<span class="report-mail">Report email</span>
									<span class="d-flex justify-content-center">Status</span>
								</li>

								<ng-container *ngIf="loader" >
									<div class="container loader d-flex justify-content-center">
										<mat-spinner [diameter]="25" [strokeWidth]="2"
											class="centre-align"></mat-spinner>
									</div>
								</ng-container>

								<ng-container *ngIf="!loader" class="scroll">
									<li *ngFor="let vendor of vendorData; let i = index"  routerLinkActive="active"
									[routerLinkActiveOptions]="{ exact: true }"  class="scroll">
										<span class="action-column d-flex justify-content-center">
											<span class="eye-icon" (click)="viewEditVendorForm(vendor)"  matTooltip="edit vendor" [matTooltipPosition]="'above'"><img src="../../../../assets/img/icons/edit-5.svg" width="18px"></span>
											<span  class="upload-icon ms-4"  matTooltip="upload" [matTooltipPosition]="'above'" [routerLink]="['/master/vendor/upload-vendor-files/' + vendor.id]"><img src="../../../../assets/img/icons/upload.svg" width="18px"></span>
											<span  class="download-icon ms-4"  matTooltip="vendor files" [matTooltipPosition]="'above'" [routerLink]="['/master/vendor/vendor-files/' + vendor.id]"><img src="../../../../assets/img/icons/download.svg" width="18px"></span>
										</span>
										<span [routerLink]="['/master/vendor/vendor-branch/' + vendor.id]" class="tracking">{{vendor.vendorName | camelcase}}</span>
										<span >{{vendor.email }}</span>
										<span >{{vendor.cwGroupCode}}</span>
										<span class="report-mail">{{vendor.reportsEmail}}</span>
										<span class="d-flex justify-content-center">
											<span class="badges bg-lightgreen" *ngIf="vendor?.status == 1">Active</span>
                                   			<span class="badges bg-lightred" *ngIf="vendor?.status == 0">Inactive</span>
										</span>
										<!-- <span class="d-flex justify-content-center">{{vendor.status.toString() === '1' ? 'Active' : 'Inactive'}}</span> -->
									</li>

									<ng-container *ngIf="vendorData && vendorData?.length == 0">
										<div class="no-order text-center pt-4 pb-4">
											<p class="margin-0">No vendors found</p>
											<a class="btn btn-primary" (click)="getVendor(pageIndex, pageSize)">View all vendors</a>
										</div>
									</ng-container>
								</ng-container>
							</ul>

						</div>

					</div>
				</div>
				<div class="pagination search-set justify-content-end flex-end w-100">
					<mat-paginator #paginator class="demo-paginator" (page)="pageNavigations($event)"
					[length]="totalCount" [pageSize]="pageSize"
					[pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
					[pageIndex]="pageIndex" [showFirstLastButtons]="showFirstLastButtons" aria-label="Select page">
				</mat-paginator>
                </div>
			</div>
		</div>
	</div>
</div>
