import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { VendorComponent } from './container/vendor/vendor.component';
import { CustomerComponent } from './container/customer/customer.component';
import { UserComponent } from '../setting/user/user.component';
import { CustomerMappingComponent } from '../journey-management/container/customer-mapping/customer-mapping.component';
import { UploadCustomerMappingComponent } from '../journey-management/container/upload-customer-mapping/upload-customer-mapping.component';
import { DocketStatusComponent } from '../Track and Trace/docket-status/docket-status.component';
import { VendorBranchComponent } from './container/vendor-branch/vendor-branch.component';
import { CustomerBranchComponent } from './container/customer-branch/customer-branch.component';
import { AddVendorFilesComponent } from './container/add-vendor-files/add-vendor-files.component';
import { AddCustomerFilesComponent } from './container/add-customer-files/add-customer-files.component';
import { VendorFilesComponent } from './container/vendor-files/vendor-files.component';
import { authGuard } from '../_guard/auth.guard';
import { UserGuard } from '../_guard/user.guard';
import { CustomerMisComponent } from '../Reports/customer-mis/customer-mis.component';
import { AscMisComponent } from '../Reports/asc-mis/asc-mis.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'docket-status',
    pathMatch: 'full',
    data: { title: 'Opollo WMS' }
  },
  {
    path: 'vendor',
    component: VendorComponent,
    canActivate: [UserGuard],
    data: { title: 'PTL - Vendor' }
  },
  {
    path: 'vendor/vendor-branch/:id',
    component: VendorBranchComponent,
    canActivate: [UserGuard],
    data: { title: 'PTL - Vendor Branch' }
  },
  {
    path: 'vendor/upload-vendor-files/:id',
    component: AddVendorFilesComponent,
    canActivate: [UserGuard],
    data: { title: 'PTL - Upload Vendor Files' }
  },
  {
    path: 'vendor/vendor-files/:id',
    component: VendorFilesComponent,
    canActivate: [UserGuard],
    data: { title: 'PTL - Vendor Files' }
  },
  {
    path: 'customer',
    component: CustomerComponent,
    canActivate: [UserGuard],
    data: { title: 'PTL - Customer' }
  },
  {
    path: 'customer/customer-branch/:id',
    component: CustomerBranchComponent,
    canActivate: [UserGuard],
    data: { title: 'PTL - Customer Branch' }
  },
  {
    path: 'customer/upload-customer-files/:id',
    component: AddCustomerFilesComponent,
    canActivate: [UserGuard],
    data: { title: 'PTL - Upload Customer File' }
  },

  {
    path: 'user',
    component: UserComponent,
    canActivate: [UserGuard],
    data: { title: 'PTL - User' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MasterRoutingModule {
  navigate(arg0: string[]) {
    throw new Error('Method not implemented.');
  }
}
