import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { environment } from 'src/environments/environment';
import { SnackbarService } from '../../services/snackbar.service';
import { MasterService } from '../../services/master.service';
import { error } from 'jquery';

@Component({
  selector: 'app-view-dialogbox',
  templateUrl: './view-dialogbox.component.html',
  styleUrls: ['./view-dialogbox.component.css']
})
export class ViewDialogboxComponent {
  viewDialogForm: FormGroup | any;
  viewEdit: number = 1;
  enableEdit: boolean = false;
  setForm: FormGroup | any;
  userRoleId: FormControl = new FormControl();
  loader: boolean = false;
  durationInSeconds = 3;
  constructor(private dialog: MatDialogRef<ViewDialogboxComponent>, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient, private snackbarService: SnackbarService,
    private masterService: MasterService) { }

  openSnackBar(message: string) {
    this.snackbarService.showSnackbar(message);
  }

  onEdit() {


  }
  closeDialog() {
    this.dialog.close();
    this.viewDialogForm.reset();
  }

  onSave() {
    this.loader = true;
    if (this.data['component'] === 'customer') {
      console.log(this.viewDialogForm);
      this.setForm = this.formBuilder.group(this.data['customerForm'].setValue);

      const requestBody = {
        id: this.setForm.value.id,
        customerName: this.setForm.value.customerName,
        email: this.viewDialogForm.value.email,
        cwGroupCode: this.viewDialogForm.value.cwGroupCode,
        status: this.viewDialogForm.value.status,

      };

      this.masterService.updateCustomer(requestBody).subscribe(response => {
        if (response) {
          const snackbarMessage = 'Customer Edit Successfully'
          this.openSnackBar(snackbarMessage);
          this.loader = false;
          this.closeDialog();
          console.log('Customer edited successfully:', response);
        }
        else {
          const snackbarMessage = 'Customer Edit failed'
          this.openSnackBar(snackbarMessage);
          this.loader = false;
        }
      },
        (error) => {
          const snackbarMessage = 'Customer Edit failed'
          this.openSnackBar(snackbarMessage);
          this.loader = false;
          console.error('Error editing customer:', error);
        })
    }

    // VENDOR
    if (this.data['component'] === 'vendor') {

      this.setForm = this.formBuilder.group(this.data['vendorForm'].setValue);

      const requestBody = {
        id: this.setForm.value.id,
        vendorName: this.setForm.value.vendorName,
        email: this.viewDialogForm.value.email,
        reportsEmail: this.viewDialogForm.value.reportsEmail,
        cwGroupCode: this.setForm.value.cwGroupCode,
        status: this.viewDialogForm.value.status,
        // modifiedBy: localStorage.getItem('id'),
        // modifiedBy: '1',
        // branchAddress: [
        //   {
        //     branchId: this.setForm.value.branchDetailsList.id,
        //     branchName: this.setForm.value.branchDetailsList.branchName,
        //     address: this.setForm.value.branchDetailsList.branchAddress,
        //     phoneNo: this.setForm.value.branchDetailsList.mobileNo,
        //     stateId: this.setForm.value.branchDetailsList.stateId,
        //     city: this.setForm.value.branchDetailsList.city,
        //     pincode: this.setForm.value.branchDetailsList.pincode,
        //     erpId: this.setForm.value.branchDetailsList.cwId,
        //     addressCode: '',
        //     panNo: this.setForm.value.branchDetailsList.panNo,
        //     gstNo: this.setForm.value.branchDetailsList.gstNo,
        //     branchActiveStatus: this.setForm.value.branchDetailsList.activeInd
        //   }
        // ]
      };
      // console.log(requestBody);
      // // Make API call
      // this.http.put(`${environment.BASE_DOMAIN}editVendor`, requestBody)
      //   .subscribe(response => {
      //     const snackbarMessage = 'Vendor edited successfully'
      //     this.openSnackBar(snackbarMessage);
      //     this.loader = false;
      //     this.closeDialog();
      //     console.log('Vendor edited successfully:', response);
      //     // Optionally handle success response here
      //   }, error => {
      //     console.error('Error editing vendor:', error);
      //     // Optionally handle error here
      //   });
      this.masterService.updateVendor(requestBody).subscribe(response => {
        if (response) {
          const snackbarMessage = 'Vendor edited successfully'
          this.openSnackBar(snackbarMessage);
          this.loader = false;
          this.closeDialog();
        }
        else {
          const snackbarMessage = 'Vendor Edit failed'
          this.openSnackBar(snackbarMessage);
          this.loader = false;
        }
      },
        (error) => {
          const snackbarMessage = 'Vendor Edit failed'
          this.openSnackBar(snackbarMessage);
          this.loader = false;
          console.error('Error editing vendor:', error);
        })
    }
  }

  ngOnInit(): void {

    // VENDOR
    if (this.data['component'] === 'vendor') {
      this.viewDialogForm = this.formBuilder.group(this.data['vendorForm'].setValue);
    }
    // CUSTOMER
    if (this.data['component'] === 'customer') {
      this.viewDialogForm = this.formBuilder.group(this.data['customerForm'].setValue);
    }

    // USER
    if (this.data['component'] === 'user') {
      this.viewDialogForm = this.formBuilder.group(this.data['userForm'].setValue);

      const value = this.viewDialogForm.value.roleDetailsList.roleId;
      this.userRoleId = new FormControl(value)
    }

  }
}
