
import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FloatLabelType } from '@angular/material/form-field';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { PATTERNS } from 'src/app/constants/constants/constants';
import { AddDialogboxComponent } from 'src/app/shared/component/add-dialogbox/add-dialogbox.component';

import { AuthService } from 'src/app/shared/services/auth.service';
import * as XLSX from 'xlsx';
import { PageEvent } from '@angular/material/paginator';
import * as moment from 'moment';
import * as feather from 'feather-icons';
import * as $ from 'jquery';
import * as daterangepicker from 'daterangepicker';
import { TrackingPopupComponent } from '../../component/tracking-popup/tracking-popup.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/shared/component/snackbar/snackbar.component';
import { formatDate } from '@angular/common';
import { subDays } from 'date-fns';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { ViewDialogboxComponent } from 'src/app/shared/component/view-dialogbox/view-dialogbox.component';
import { MasterService } from 'src/app/shared/services/master.service';

@Component({
  selector: 'app-customer-mapping',
  templateUrl: './customer-mapping.component.html',
  styleUrls: ['./customer-mapping.component.css']
})
export class CustomerMappingComponent implements AfterViewInit {

  dataSource = new MatTableDataSource<customerMappingTable>();
  allCheckedChange: Subject<boolean> = new Subject<boolean>();
  allChecked: boolean = false;
  FilterForm = new FormGroup({
    customerName: new FormControl(''),
    OrderStatus: new FormControl(''),
    // OrderNumber: new FormControl(''),
    // ExOrdernumber: new FormControl(''),
    dateRange: new FormControl(''),
    docketNo: new FormControl(''),
    docketSearch: new FormControl('')
  });
  floatLabel: FloatLabelType = 'auto';
  // floatLabel: FloatLabelType = 'auto' as FloatLabelType;
  noDockets: boolean = true;

  activeClass: boolean = false;
  orderData: any = [];
  showFilter: any;

  length = 10000;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = false;
  showFirstLastButtons = false;
  disabled = false;

  pageEvent: any;

  custMappData: any = [];
  searchDocketNo: any = [];

  dateOptionsList = [
    {
      viewValue: 'Today',
      value: 'today'
    },
    {
      viewValue: 'Yesterday',
      value: 'yesterday'
    },
    {
      viewValue: 'Last 7 days',
      value: 'last7days'
    },
    {
      viewValue: 'Last 30 days',
      value: 'last30days'
    },
    {
      viewValue: 'This month',
      value: 'thismonth'
    },
    {
      viewValue: 'Last month',
      value: 'lastmonth'
    }
  ];

  fromDateObj: any = {};
  toDateObj: any = {};
  customDate: any;
  AllStatusList: any = [];
  filterOrderstatus: any;
  allCheckedOrderNumbers: string = '';
  activeParam: any;
  pendingParam: any;
  loader: boolean = false;
  getpayload: any = []
  PATTERNS = PATTERNS
  userForm: FormGroup | any;
  loaderMail: boolean = false;
  startDate: any;
  endDate: any;
  customStartDate: any | null = null;
  customEndDate: any | null = null;
  durationInSeconds = 3;
  totalCount: number = 0;
  selectedStatusName: any;
  customerList: any[] = [];
  docketNo: any = [];
  showSelectedStartDate: any;
  showSelectedEndDate: any;
  customerFilter: any = [];
  selectedDateRange: string = 'Last 7 days';
  constructor(public dialog: MatDialog, private renderer: Renderer2, private auth: AuthService, private datePipe: DatePipe,
    private route: ActivatedRoute, private fb: FormBuilder, private cdr: ChangeDetectorRef, private snackbarService: SnackbarService,
    private masterService: MasterService) {
    this.allCheckedChange.subscribe((value: boolean) => {
      this.allChecked = value;
    });

    this.userForm = this.fb.group({
      userName: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      // userType: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      mobileNo: ['', [Validators.required, Validators.pattern(/^[1-9]{1}[0-9]{9}$/)]],
      // orgId: ['', Validators.required],
      status: ['', Validators.required]
    })

    feather.replace();
  }

  getDocketData(pageIndex: any, pageSize: any, ids: any, docketNo: any): void {
    let startDate: any;
    let endDate: any;
    this.loader = true;

    // Check if custom start and end dates are set
    if (this.customStartDate && this.customEndDate) {
      startDate = this.formatDate(this.customStartDate);
      endDate = this.formatDate(this.customEndDate);
    } else {
      // Default start and end dates if custom dates are not set
      startDate = this.formatDate(subDays(new Date(), 7)); // 7 days before today
      endDate = this.formatDate(new Date());
    }

    this.masterService.getDocketList(startDate, endDate, pageIndex, pageSize, this.FilterForm.value.customerName?.toString(), this.FilterForm.value.docketNo)
      .subscribe((data) => {
        console.log(data);
        if (data != null && data.responseObject != null) {
          this.noDockets = false;
          this.custMappData = data?.responseObject?.content || [];
          this.totalCount = data?.responseObject?.totalElements || 0;
          this.custMappData.sort((a: any, b: any) => {
            const dateA = new Date(a.pickupDate).getTime();
            const dateB = new Date(b.pickupDate).getTime();
            if (this.sortOrder === 'asc') {
              return dateA - dateB;
            } else {
              return dateB - dateA;
            }
          });
        } else {
          this.custMappData = [];
        }
        this.loader = false;
        this.cdr.detectChanges();
      });
  }

  openSnackBar(message: string) {
    this.snackbarService.showSnackbar(message);
  }

  sendMailtoCustomer() {
    this.loader = true;

    // Format custom dates or set default dates
    if (this.customStartDate && this.customEndDate) {
      this.startDate = this.formatDate(this.customStartDate);
      this.endDate = this.formatDate(this.customEndDate);
    } else {
      this.startDate = this.formatDate(subDays(new Date(), 7)); // 7 days before today
      this.endDate = this.formatDate(new Date());
    }

    // Check if any filter criteria are provided
    const customerName = this.FilterForm.value.customerName;
    const docketNo = this.FilterForm.value.docketNo;

    if (!customerName && !docketNo && !this.customStartDate && !this.customEndDate) {
      this.loader = false;
      const snackbarMessage = 'Please provide at least one filter criteria.';
      this.openSnackBar(snackbarMessage);
      return;
    }

    // Call the API based on provided filter criteria
    this.masterService.sendMailToCustomer(this.startDate, this.endDate, customerName?.toString() || '', docketNo || '')
      .subscribe(
        (data: any) => {
          const snackbarMessage = 'Mail Sent Successfully';
          this.openSnackBar(snackbarMessage);
          console.log("Success", data);
          this.loader = false;
        },
        (error: any) => {
          const snackbarMessage = 'Failure sending mail';
          this.openSnackBar(snackbarMessage);
          console.log("Failed", error);
          this.loader = false;
        }
      );
  }


  handleCustomDateRange(startDate: any, endDate: any) {
    // Set custom start and end dates
    this.customStartDate = startDate;
    this.customEndDate = endDate;

    const selectedOptionControl = this.FilterForm.get('dateRange');
    selectedOptionControl?.setValue('custom');
    this.getDocketData(this.pageIndex, this.pageSize, this.filterOrderstatus, this.docketNo);
  }


  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy +0000') || '';
  }


  ngOnInit(): void {
    this.getDocketData(this.pageIndex, this.pageSize, this.filterOrderstatus, this.docketNo);

    this.getCustomerList();

  }
  logOut() {
    // console.log("logout")
    this.auth.logOut()
  }

  getCustomerList() {
    this.masterService.getCustomerDetails(this.pageIndex, this.pageSize, this.customerFilter).subscribe((res: any) => {
      this.customerList = res?.responseObject?.content?.sort((a: any, b: any) => {
        const nameA = a.customerName?.toLowerCase();
        const nameB = b.customerName?.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }, error => {
      console.log('error', error)
      this.orderData = ''
    })
  }


  pageNavigations(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.allChecked = false;
    this.getDocketData(this.pageIndex, this.pageSize, this.filterOrderstatus, this.docketNo); // Fetch data for the new page
  }

  // SORTING
  sortOrder: 'asc' | 'desc' = 'asc';

  toggleSortOrder() {
    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    this.sortByDate(this.sortOrder);
    this.sortByDocketNumber(this.sortOrder);
    this.sortByCustomerName(this.sortOrder)
  }

  sortByDate(order: 'asc' | 'desc' = 'asc') {
    this.custMappData.sort((a: any, b: any) => {
      const dateA = new Date(a.pickupDate).getTime();
      const dateB = new Date(b.pickupDate).getTime();
      if (order === 'asc') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });
  }

  sortByDocketNumber(order: 'asc' | 'desc' = 'asc') {
    this.custMappData.sort((a: any, b: any) => {
      const docketNumberA = a.docketNo?.toLowerCase(); // Assuming docketNumber is a string
      const docketNumberB = b.docketNo?.toLowerCase();
      if (order === 'asc') {
        return docketNumberA.localeCompare(docketNumberB); // Ascending order
      } else {
        return docketNumberB.localeCompare(docketNumberA); // Descending order
      }
    });
  }

  sortByCustomerName(order: 'asc' | 'desc' = 'asc') {
    this.custMappData.sort((a: any, b: any) => {
      const nameA = a.customerName?.toLowerCase(); // Convert to lowercase for case-insensitive comparison
      const nameB = b.customerName?.toLowerCase();

      if (order === 'asc') {
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      } else {
        if (nameA < nameB) return 1;
        if (nameA > nameB) return -1;
        return 0;
      }
    });
  }




  /**@description Method To Autofill Dates*/
  public onChangeCondition(dateKey: any) {

    let fromDate: any;
    let toDate: any;
    // this.filterOptionsList.filter(x => x.value === filterCondition)[0].customDates = {};
    switch (dateKey) {
      case 'today':
        fromDate = new Date();
        toDate = new Date();
        // toDate = new Date(); // Assign current date to both fromDate and toDate
        this.showSelectedStartDate = ''
        this.showSelectedEndDate = ''
        break;
      case 'yesterday':
        fromDate = new Date(
          moment()
            .subtract(1, 'days')
            .startOf('day')
            .valueOf()
        );
        toDate = new Date(
          moment()
            .subtract(1, 'days')
            .startOf('day')
            .valueOf()
        );
        this.showSelectedStartDate = ''
        this.showSelectedEndDate = ''
        break;
      case 'last7days':
        fromDate = new Date(
          moment()
            .subtract(7, 'days')
            .startOf('day')
            .valueOf()
        );
        // toDate = new Date();
        this.showSelectedStartDate = ''
        this.showSelectedEndDate = ''
        break;
      case 'last30days':
        fromDate = new Date(
          moment()
            .subtract(29, 'days')
            .startOf('day')
            .valueOf()
        );
        toDate = new Date();
        this.showSelectedStartDate = ''
        this.showSelectedEndDate = ''
        break;
      case 'thismonth':
        fromDate = new Date(
          moment()
            .startOf('month')
            .valueOf()
        );
        toDate = new Date();
        this.showSelectedStartDate = ''
        this.showSelectedEndDate = ''
        break;
      case 'lastmonth':
        fromDate = new Date(
          moment()
            .subtract(1, 'months')
            .startOf('month')
            .valueOf()
        );
        toDate = new Date(
          moment()
            .subtract(1, 'months')
            .endOf('month')
            .valueOf()
        );
        this.showSelectedStartDate = ''
        this.showSelectedEndDate = ''
        break;

      case 'custom': // Added case for custom date range
        // Call handleCustomDateRange
        // this.handleCustomDateRange(this.startDate, this.endDate);
        return;
    }

    // Transform dates to the desired format
    const FROM_DATE = this.datePipe.transform(fromDate, 'YYYY-MM-dd');
    const TO_DATE = this.datePipe.transform(toDate, 'YYYY-MM-dd');

    // Update fromDateObj and toDateObj
    this.fromDateObj = FROM_DATE;
    this.toDateObj = TO_DATE;

    this.customStartDate = FROM_DATE;
    this.customEndDate = TO_DATE;
  }



  resetFilter() {
    this.FilterForm.reset();
    // Clear any filter-related properties or variables
    this.customStartDate = ''; // Reset custom start date
    this.customEndDate = ''; // Reset custom end date
    this.fromDateObj = ''; // Reset fromDateObj
    this.toDateObj = ''; // Reset toDateObj
    this.searchDocketNo = '';
    this.filterOrderstatus = '';
    this.pageIndex = 0;
    this.noDockets = false;

    this.getDocketData(this.pageIndex, this.pageSize, '', '');
    if(this.custMappData.length === 0){
      this.noDockets = true;
    }
  }

  viewAllOrder() {
    this.resetFilter()
  }

  submitFilter() {
    var array = this.FilterForm.value;
    if (Array.isArray(array)) {
      // Convert the array to a string with values separated by commas
      this.filterOrderstatus = array.join(',');

    } else {
      console.log('OrderStatus is not an array.');
    }

    let startDate: any;
    let endDate: any;
    this.loader = true;

    if (this.customStartDate && this.customEndDate) {
      startDate = this.formatDate(this.customStartDate);
      endDate = this.formatDate(this.customEndDate);
    } else {
      startDate = this.formatDate(subDays(new Date(), 7)); // 7 days before today
      endDate = this.formatDate(new Date());
    }

    // Call getDocketData with appropriate dates
    this.getDocketData(this.pageIndex, this.pageSize, this.filterOrderstatus, this.docketNo);
  }


  // emitDates(customDates: any) {
  //   console.log("ssss", customDates)
  //   this.customDate = customDates
  //   // this.fromDateObj = customDates.startDate
  //   // this.toDateObj = customDates.endDate

  //   this.fromDateObj = this.datePipe.transform(customDates.startDate, 'dd-MM-yyyy Z', '+0530');
  //   this.toDateObj = this.datePipe.transform(customDates.endDate, 'dd-MM-yyyy Z', '+0530');
  //   const selectedOptionControl = this.FilterForm.get('dateRange');
  //   selectedOptionControl?.setValue('custom')
  //   console.log("FROM_DATE", this.fromDateObj, "TO_DATE", this.toDateObj)
  //   setTimeout(() => {
  //     this.showSelectedStartDate = this.datePipe.transform(customDates.startDate, 'dd-MM-yyyy');
  //     this.showSelectedEndDate = this.datePipe.transform(customDates.endDate, 'dd-MM-yyyy');
  //   }, 100);

  //   this.submitFilter()
  // }


  ngAfterViewInit() {
    feather.replace();
  }

  addUserForm(): void {
    let dialogRef = this.dialog.open(AddDialogboxComponent, {
      width: '648px',
      data: {
        "component": "user",
        "userForm": this.userForm
      }
    })
  }

  viewEditUserForm(): void {
    let dialogRef = this.dialog.open(ViewDialogboxComponent, {
      width: '648px',
      data: {
        "component": "user",
        // "addlocationForm": this.locationForm
      }
    })
  }

  viewDocketTracking(data: any): void {
    // debugger
    let dialogRef = this.dialog.open(TrackingPopupComponent, {
      width: '790px',
      // height: '480px',
      data: {
        component: 'tracking',
        docket: data
      }
    });
  }
  // vendorId:any
  searchByDocketNo(): void {
    if (this.searchDocketNo) {
      this.loader = true;
      this.masterService.getDocketList('', '', 0, 10, '', this.searchDocketNo).subscribe((data: any) => {
        if (data && data?.responseObject) {
          this.custMappData = [data?.responseObject?.content[0]];
          this.totalCount = data?.responseObject?.totalElements || 0;
          this.loader = false;
        } else {
          this.custMappData = [];
          this.loader = false;
        }
        this.loader = false;
      }, error => {
        console.error('Error fetching docket details', error);
        this.loader = false;
        this.custMappData = [];
      });
    }
  }


  downloadAllExcel(startDate: string, endDate: string) {
    // debugger
    this.loader = true;
    let ids: any;
    if (this.customStartDate && this.customEndDate) {
      startDate = this.formatDate(this.customStartDate);
      endDate = this.formatDate(this.customEndDate);
    } else {
      // Default start and end dates if custom dates are not set
      startDate = this.formatDate(subDays(new Date(), 7)); // 7 days before today
      endDate = this.formatDate(new Date()); // Use today's date for endDate
    }

    let filterApplied = false;
    if (this.FilterForm.value === null) {
      this.masterService.getDocketList(startDate, endDate, 0, this.totalCount, this.filterOrderstatus, this.docketNo).subscribe((data: any) => {
        if (data != null && data?.responseObject && data?.responseObject?.content) {
          const name = this.generateExcelData(data?.responseObject?.content);
          this.downloadExcelFile(name);
        } else {
          console.log('No data available');
        }
        this.loader = false;
      }, error => {
        console.error('Error fetching docket list', error);
        this.loader = false;
      });
    }
    else {
      // Check if a docket number is entered for search in FilterForm
      if (this.FilterForm.value.docketNo) {
        this.masterService.getDocketList('', '', 0, this.totalCount, '', this.FilterForm.value.docketNo).subscribe((data: any) => {
          if (data && data.responseObject && data.responseObject.content) {
            const name = this.generateExcelData(data.responseObject.content);
            this.downloadExcelFile(name);
          } else {
            console.log('No data available');
          }
          this.loader = false;
        }, error => {
          console.error('Error fetching docket list', error);
          this.loader = false;
        });
        filterApplied = true;
      } else {

        this.masterService.getDocketList(startDate, endDate, 0, this.totalCount, this.FilterForm.controls.customerName.value?.toString(), '').subscribe((data:any) => {
          if (data && data.responseObject && data.responseObject.content) {
            const name = this.generateExcelData(data.responseObject.content);
            this.downloadExcelFile(name);
            this.loader = false;
          } else {
            console.log('No data available');
          }
          this.loader = false;
        })
      }
    }
  }

  generateExcelData(data: any[]): any[][] {
    const name = [
      ['NO.', 'Upload Status', 'customer cagrowise code', 'Docket Id', 'Order No.', 'Docket No.', 'Customer Id', 'Customer Code', 'Customer Name', 'Vendor Id', 'Pickup Date', 'Assured Delivery Date',
        'Actual Delivery Date', 'Booking City', 'Booking Zone', 'Delivery City', 'Delivery Zone', 'Consignor Name', 'Consignor Mobile No.', 'Consignor Address',
        'Consignor City', 'Consignor State', 'Consignor Pincode', 'Consignee Name', 'Consignee Mobile', 'Consignee Address', 'Consignee City', 'Consignee State',
        'Consignee Pincode', 'Goods Name', 'Actual Weight', 'Charged Weight', 'Total Packages', 'Cargo Value', 'Amt Excl GST', 'Amt Incl GST', 'Revise Delivery Date',
        'Revision Reason', 'Rebooked Docket No', 'Rebooked Docket Date', 'Delivered To', 'Transit Days', 'CustRemarks', 'Reason Desc', 'Invoice No', 'Invoice Date',
        'Deps', 'Connection Status', 'Connection Date', 'Remarks', 'Docket Status', 'Docket Current Status', 'POD', 'Active Status', 'Created By', 'Created On', 'Modified By',
        'Modified On', 'Status Details List']
    ];

    data.forEach((docket: any, index: number) => {
      name.push([
        index + 1,
        '',
        docket.customerName,
        docket.docketId,
        docket.orderNo,
        docket.docketNo,
        docket.customerId,
        docket.customerCode,
        docket.customerName,
        docket.vendorId,
        docket.pickupDate,
        docket.assuredDeliveryDate,
        docket.actualDeliveryDate,
        docket.bookingCity,
        docket.bookingZone,
        docket.deliveryCity,
        docket.deliveryZone,
        docket.consignorName,
        docket.consignorMobile,
        docket.consignorAddress,
        docket.consignorCity,
        docket.consignorState,
        docket.consignorPincode,
        docket.consigneeName,
        docket.consigneeMobile,
        docket.consigneeAddress,
        docket.consigneeCity,
        docket.consigneeState,
        docket.consigneePincode,
        docket.goodsName,
        docket.actualWeight,
        docket.chargedWeight,
        docket.totalPackages,
        docket.cargoValue,
        docket.amtExclGST,
        docket.amtInclGST,
        docket.reviseDeliveryDate,
        docket.revisionReason,
        docket.rebookedDocketNo,
        docket.rebookedDocketDate,
        docket.deliveredTo,
        docket.transitDays,
        docket.custRemarks,
        docket.reasonDesc,
        docket.invoiceNo,
        docket.invoiceDate,
        docket.deps,
        docket.connectionStatus,
        docket.connectionDate,
        docket.remarks,
        docket.docketStatus,
        docket.docketCurrentStatus,
        docket.pod,
        docket.status?.toString() === '1' ? 'Active' : 'Inactive',
        docket.createdBy,
        docket.createdOn,
        docket.modifiedBy,
        docket.modifiedOn,
        docket.statusDetailsList
      ]);
    });

    return name;
  }

  downloadExcelFile(data: any[][]) {

    const todayDateTime = new Date().toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });

    const formattedDateTime = todayDateTime.replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+)/, function (match, day, month, year, hour, minute) {
      return `${day.padStart(2, '0')}_${month.padStart(2, '0')}_${year.substr(-2)}, ${hour.padStart(2, '0')}_${minute.padStart(2, '0')}`;
    });

    console.log(formattedDateTime);

    const filename = `Docket Details ${formattedDateTime}.xlsx`;
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Docket Detail');
    XLSX.writeFile(wb, filename);
  }

  selectStatus(event: any) {
    const selectValue = this.FilterForm.get('customerName')?.value?.[0]
    console.log(selectValue, this.FilterForm)
    this.customerList.forEach((el: any) => {
      // console.log(el)
      if (el.id === selectValue) {
        this.selectedStatusName = el.customerName
      }
    })
  }

}

export interface customerMappingTable {
  docketId: any;
  orderNo: any;
  docketNo: any;
  custId: any;
  custCode: any;
  custName: any;
  vendorId: any;
  pickupDate: any;
  assuredDeliveryDate: any;
  actualDeliveryDate: any;
  bookingCity: any;
  bookingZone: any;
  deliveryCity: any;
  deliveryZone: any;
  consignorName: any;
  consignorMobile: any;
  consignorAddress: any;
  consignorCity: any;
  consignorState: any;
  consignorPincode: any;
  consigneeName: any;
  consigneeMobile: any;
  consigneeAddress: any;
  consigneeCity: any;
  consigneeState: any;
  consigneePincode: any;
  goodsName: any;
  actualWeight: any;
  chargedWeight: any;
  totalPackages: any;
  cargoValue: any;
  amtExclGST: any;
  amtInclGST: any;
  reviseDeliveryDate: any;
  revisionReason: any;
  rebookedDocketNo: any;
  rebookedDocketDate: any;
  deliveredTo: any;
  transitDays: any;
  custRemarks: any;
  reasonDesc: any;
  invoiceNo: any;
  invoiceDate: any;
  deps: any;
  connectionStatus: any;
  connectionDate: any;
  remarks: any;
  docketStatus: any;
  docketCurrentStatus: any;
  pod: any;
  activeInd: any;
  createdBy: any;
  createdOn: any;
  modifiedBy: any;
  modifiedOn: any;
  statusDetailsList: any;

}



