<div class="container-fluid" *ngIf="data['component']==='changePassword'">
    <div class="main-content mt-2">
        <div class="row">
            <div class="col">
                <p mat-dialog-title class="title">Change Password
                </p>
            </div>
            <div class="col-auto"> <span class="close-icon d-flex justify-content-end"><img
                        src="../../../../assets/img/icons/close-icon.svg" (click)="closeDialog()"></span></div>
        </div>
    </div>

    <form [formGroup]="changePasswordForm" class="custom-form">
        <div *ngIf="loader">
            <div class=" loader">
                <mat-spinner [diameter]="25" [strokeWidth]="2" class="centre-align"></mat-spinner>
            </div>
        </div>

        <div class="col-lg-12">
            <label for="newPass" class="required">New Password</label><br>
            <input type="text" class="form-control" formControlName="newPassword" placeholder="abc@123">
            <mat-error
                *ngIf="changePasswordForm.get('newPassword').hasError('required') && changePasswordForm.get('newPassword').touched">
                New password is required.
            </mat-error>
        </div>
        <div class="col-lg-12 mt-4">
            <label for="confirmPassword" class="required">Confirm Password</label>
            <input type="password" class="form-control" formControlName="confirmPassword" placeholder="abc@123">
            <mat-error *ngIf="changePasswordForm.get('confirmPassword').hasError('required') && changePasswordForm.get('confirmPassword').touched">
              Confirm password is required.
            </mat-error>
            <mat-error *ngIf="changePasswordForm.get('confirmPassword').hasError('mismatch')">
              Passwords do not match.
            </mat-error>
          </div>
        <div class="row mt-4 mb-3">
            <div class="col d-flex justify-content-center">
                <button class="btn btn-submit me-2" [disabled]="changePasswordForm.invalid" (click)="submit()">Submit</button>
                <a (click)="closeDialog()" class="btn btn-cancel">Cancel</a>
            </div>
        </div>
    </form>
</div>