import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { HeaderComponent } from './component/header/header.component';
import { CustomDatePipe } from '../_pipe/datepipe.pipe';
import { AppMaterialModule } from '../_material/material/material.module';
import { CustomDateRangeComponent } from './component/custom-date-range/custom-date-range.component';
import { dateRangeComponent } from './component/date-range/date-range.component';
import { searchDropDownFilter } from '../_pipe/search-filter.pipe';
import { AddDialogboxComponent } from './component/add-dialogbox/add-dialogbox.component';

import { SnackbarComponent } from './component/snackbar/snackbar.component';
import { EditBranchDetailsComponent } from './component/edit-branch-details/edit-branch-details.component';

// @NgModule({
//   declarations: [CustomDateRangeComponent,dateRangeComponent,searchDropDownFilter, AddDialogboxComponent, ViewDialogboxComponent, SnackbarComponent, EditBranchDetailsComponent],
import { ViewDialogboxComponent } from './component/view-dialogbox/view-dialogbox.component';

@NgModule({
  declarations: [CustomDateRangeComponent,dateRangeComponent,searchDropDownFilter, AddDialogboxComponent, ViewDialogboxComponent, SnackbarComponent, EditBranchDetailsComponent],
  exports: [CustomDateRangeComponent,dateRangeComponent,searchDropDownFilter], 
  imports: [CommonModule,AppMaterialModule],
})

// @NgModule({
//   declarations: [
//     HeaderComponent,
//     SidebarComponent
//   ],
//   imports: [
//     CommonModule
//   ],
//   exports:[
//     HeaderComponent,
//     SidebarComponent
//   ]
// })
export class SharedModule { }
