import { AfterViewInit, Component, HostListener } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { RolebaseService } from '../../services/rolebase.service';
import * as feather from 'feather-icons';
import { MasterService } from '../../services/master.service';
import { ChangePasswordComponent } from 'src/app/setting/change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements AfterViewInit{
  isMobile: boolean = false;
  totalCount: number = 0;
  loader: boolean = false;
  pageSize = 10;
  pageIndex = 0;
  //Customer
  customerFilter: any = [];
  customerData: any = [];

  //Vendor
  vendorData: any = [];
  vendorFilter: any = [];

  changePasswordForm: FormGroup | any;

  // USER
  userList: any;
  obj = [{
   "key": "PLATFORM",
   "value": "ptl"
  }];
  length = 50;
  payLoad:any
  constructor(private auth: AuthService, public roleService: RolebaseService, private masterService: MasterService, public dialog: MatDialog,private fb: FormBuilder) {
    this.checkIfMobile();
    feather.replace();
    
    this.changePasswordForm = this.fb.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

  ngAfterViewInit() {
    feather.replace();
  }

  ngOnInit() {
    this.roleService.fetchRoleList();
    // this.getVendor(this.pageIndex, this.pageSize);
    this.getCustomer(this.pageIndex, 1000);
    this.getUserList(this.obj, this.pageIndex, 1000)
  }

  // CUSTOMER
  getCustomer(pageIndex: any, pageSize: any) {
    this.loader = true;
    this.masterService.getCustomerDetails(pageIndex, pageSize, this.customerFilter).subscribe((data) => {
      console.log(data);
      if (data != null) {
        this.customerData = data?.responseObject?.content;
        this.totalCount = data?.responseObject?.totalElements;
        localStorage.setItem('customer', JSON.stringify(this.customerData));
      }
      this.loader = false;
    })
  }

  // VENDOR
  getVendor(pageIndex: any, pageSize: any) {
    this.loader = true;
    this.masterService.getVendorDetails(pageIndex, pageSize, this.vendorFilter).subscribe((data: any) => {
      // debugger
      if (data != null) {
        this.vendorData = data?.responseObject?.content;
      }
      this.loader = false;
    })
  }

  getUserList(getpayload: {}, pageIndex: number, pageSize: number) {
    this.auth.getUserList(getpayload, pageIndex, pageSize).subscribe(
      (data:any) => {
        if(data!=null){
          this.userList = data?.responseObject?.content
          console.log(this.userList)
          localStorage.setItem('userlist', JSON.stringify(this.userList));
        }
      }
    );
  }

  changePassword(): void {
    const body = document.body;
    if (body.classList.contains('menu-opened')) {
      document.querySelector(".main-wrapper")?.classList.remove("slide-nav");
      body.classList.remove('menu-opened');
    }
    let dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '500px',
      data: {
        "component": "changePassword",
        "changePasswordForm": this.changePasswordForm
      }
    })
  }

  logOut() {
    this.auth.logOut();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobile(); // Check whenever the window is resized
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth < 769;
  }

  toggleNavMobile() {
    if (this.isMobile) {
      const toggleBtn: any = document.getElementById('mobile_btn');

      const body = document.body;
      // const wrapper = document.querySelector(".main-wrapper");

      if (document.querySelector(".slide-nav")) {
        document.querySelector(".main-wrapper")?.classList.remove("slide-nav");

        body.classList.remove('menu-opened');
        // toggleBtn.classList.add('active');
        // localStorage.setItem(screenModeNightTokenState, 'night');
        setTimeout(() => {
          body.classList.remove('mini-sidebar');
          document.querySelector(".header-left")?.classList.add("active");
        }, 100);
      } else {
        document.querySelector(".main-wrapper")?.classList.add("slide-nav");

        body.classList.add('menu-opened');
        toggleBtn.classList.remove('active');
        // localStorage.removeItem(screenModeNightTokenState);
        setTimeout(() => {
          body.classList.add('mini-sidebar');
          document.querySelector(".header-left")?.classList.remove("active");
        }, 100);
      }
    }
  }
}
