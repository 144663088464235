import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as feather from 'feather-icons';
import { RouterService } from './shared/services/router.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit{
  title = 'PTL';

  constructor(private _route: Router,private routerService: RouterService){
    const user_details = localStorage.getItem('user_details');
    if(user_details){
      localStorage.setItem('isloggedin','true')
    }else{
      localStorage.setItem('isloggedin','false')
    }
    // const isloggedIn =  localStorage.getItem('isloggedin')
    // console.log("isloggedIn ppppp",isloggedIn)
    // if(isloggedIn === 'true'){
      // this._route.navigate(['/order']);
    // }else{
      // this._route.navigate([''])
    // }
  }
  ngOnInit(): void {
    this.routerService.ActivateRouteService();
  }

  ngAfterViewInit() {
    feather.replace();
  }
}
