import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { auth2Guard } from './_guard/auth2.guard';

const routes: Routes = [
  // { path: '', redirectTo: 'master', pathMatch: 'full' },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  // {
  //   path: '',
  //   // canLoad: [auth2Guard],
  //   loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  // },
  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      // {
      //   path: 'import-order',
      //   component: ImportOrderComponent,
      //   // canActivate: [AuthGuard],
      //   data: { title: 'Opollo WMS' }
      // },
      {
        path: 'master',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./master/master.module').then(m => m.MasterModule)
      },
      {
        path:'journey-management',
        loadChildren: () => import('./journey-management/journey-management.module').then(m => m.JourneyManagementModule)
      },
      {
        path:'reports',
        loadChildren: () => import('./Reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path:'setting',
        loadChildren: () => import('./master/master.module').then(m => m.MasterModule)
      },

    ]
  },
  { path: '**', redirectTo: '' },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
