import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FloatLabelType } from '@angular/material/form-field';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { PATTERNS } from 'src/app/constants/constants/constants';
import { AddDialogboxComponent } from 'src/app/shared/component/add-dialogbox/add-dialogbox.component';
import { ViewDialogboxComponent } from 'src/app/shared/component/view-dialogbox/view-dialogbox.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import * as XLSX from 'xlsx';
import { OrderModel, StatusModel } from '../../constants/constants';
import { PageEvent } from '@angular/material/paginator';
import * as moment from 'moment';
import * as feather from 'feather-icons';
import { MasterService } from 'src/app/shared/services/master.service';
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent {

  dataSource = new MatTableDataSource<customerTable>();
  allCheckedChange: Subject<boolean> = new Subject<boolean>();
  allChecked: boolean = false;
  FilterForm = new FormGroup({
    OrderStatus: new FormControl(''),
    OrderNumber: new FormControl(''),
    ExOrdernumber: new FormControl(''),
    dateRange: new FormControl('')
  });
  floatLabel: FloatLabelType = 'auto';
  // floatLabel: FloatLabelType = 'auto' as FloatLabelType;


  activeClass: boolean = false;
  orderData: any = [];
  showFilter: any;

  totalCount: number = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = false;
  showFirstLastButtons = false;
  disabled = false;

  pageEvent: any;

  customerData: any = [];


  dateOptionsList = [
    {
      viewValue: 'Today',
      value: 'today'
    },
    {
      viewValue: 'Yesterday',
      value: 'yesterday'
    },
    {
      viewValue: 'Last 7 days',
      value: 'last7days'
    },
    {
      viewValue: 'Last 30 days',
      value: 'last30days'
    },
    // {
    //   viewValue: 'Last 90 days',
    //   value: 'last90days'
    // },
    // {
    //   viewValue: 'This week',
    //   value: 'thisweek'
    // },
    {
      viewValue: 'This month',
      value: 'thismonth'
    },
    {
      viewValue: 'Last month',
      value: 'lastmonth'
    }
  ];

  fromDateObj: any = {};
  toDateObj: any = {};
  customDate: any;
  AllStatusList: any = [];
  filterOrderstatus: any;
  allCheckedOrderNumbers: string = '';
  activeParam: any;
  pendingParam: any;
  loader: boolean = false;
  getpayload: any = []
  PATTERNS = PATTERNS
  customerForm: FormGroup | any;
  customerBranchForm: FormGroup | any;
  customerFilter: any = [];
  constructor(public dialog: MatDialog, private renderer: Renderer2, private auth: AuthService, private datePipe: DatePipe,
    private route: ActivatedRoute, private fb: FormBuilder, private cdr: ChangeDetectorRef, private masterService: MasterService) {
    this.allCheckedChange.subscribe((value: boolean) => {
      this.allChecked = value;
    });

    this.customerForm = this.fb.group({
      custId:[''],
      custName: ['', Validators.required],
      email: ['',[Validators.required,Validators.pattern(PATTERNS.EMAIL)]],
      // reportsEmail: ['', [Validators.email, Validators.required]],
      groupId: [''],
      activeStatus: ['', Validators.required],
      // Branch details
      branchName: ['', Validators.required],
      phoneNo: ['', [Validators.required, Validators.pattern(/^[1-9]{1}[0-9]{9}$/)]],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      pincode: ['', [Validators.required, Validators.pattern(/^\d{6}$/), Validators.maxLength(6)]],
      erpId: [''],
      addressCode: ['', Validators.required],
      gstNo: ['', [Validators.required, Validators.pattern(/^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}[Z]{1}[0-9]{1})$/)]],
      panNo: ['', [Validators.required, Validators.pattern(/^[A-Z]{5}[0-9]{4}[A-Z]$/)]],
    });

    this.customerBranchForm = this.fb.group({
      custBranchName: ['', Validators.required],
      custBranchAddress: ['', Validators.required],
      city:['', Validators.required],
      state:['', Validators.required],
      pincode: ['', [Validators.required, Validators.pattern(/^\d{6}$/), Validators.maxLength(6)]],
      gst: ['', [Validators.required, Validators.pattern(/^([0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9]{1}[zZ]{1}[0-9]{1})$/)]],
      pan: ['', [Validators.required, Validators.pattern(/^[A-Z]{5}[0-9]{4}[A-Z]$/)]],
      cwId: ['', Validators.required],
      status:['', Validators.required]
    })
  }

  getCustomer(pageIndex: any, pageSize: any){
    this.loader = true; 
    this.masterService.getCustomerDetails(pageIndex, pageSize, this.customerFilter).subscribe((data)=>{
      console.log(data);
      if(data != null){
        this.customerData = data?.responseObject?.content;
        this.totalCount = data?.responseObject?.totalElements;
      }
      this.loader = false;
    })
  }

  ngOnInit(): void {
    // this.getOderData(this.getpayload, this.pageIndex, this.pageSize);

    this.getCustomer(this.pageIndex, this.pageSize);

  }
  logOut() {
    // console.log("logout")
    this.auth.logOut()
  }

  pageNavigations(e: PageEvent) {
    this.pageEvent = e;
    this.totalCount = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.allChecked = false
    this.getCustomer(this.pageIndex, this.pageSize);
  }
  
  /**@description Method To Autofill Dates*/
  public onChangeCondition(dateKey: any) {
    console.log('Selected value:', dateKey);

    let fromDate: any;
    let toDate: any;
    // this.filterOptionsList.filter(x => x.value === filterCondition)[0].customDates = {};
    switch (dateKey) {
      case 'today':
        fromDate = new Date();
        // toDate = new Date();
        break;
      case 'yesterday':
        fromDate = new Date(
          moment()
            .subtract(1, 'days')
            .startOf('day')
            .valueOf()
        );
        toDate = new Date(
          moment()
            .subtract(1, 'days')
            .startOf('day')
            .valueOf()
        );
        break;
      case 'last7days':
        fromDate = new Date(
          moment()
            .subtract(6, 'days')
            .startOf('day')
            .valueOf()
        );
        toDate = new Date();
        break;
      case 'last30days':
        fromDate = new Date(
          moment()
            .subtract(29, 'days')
            .startOf('day')
            .valueOf()
        );
        toDate = new Date();
        break;

      case 'thismonth':
        fromDate = new Date(
          moment()
            .startOf('month')
            .valueOf()
        );
        toDate = new Date();
        break;
      case 'lastmonth':
        fromDate = new Date(
          moment()
            .subtract(1, 'months')
            .startOf('month')
            .valueOf()
        );
        toDate = new Date(
          moment()
            .subtract(1, 'months')
            .endOf('month')
            .valueOf()
        );
        break;
    }
    const FROM_DATE = this.datePipe.transform(fromDate, 'dd-MM-yyyy Z', '+0530');
    const TO_DATE = this.datePipe.transform(toDate, 'dd-MM-yyyy Z', '+0530');
    // console.log("dateKey",dateKey,TO_DATE)

    this.fromDateObj = FROM_DATE
    if (dateKey != 'today') {
      this.toDateObj = TO_DATE
    }

    console.log("FROM_DATE", FROM_DATE, "TO_DATE", TO_DATE)
    console.log("FROM_DATE", this.fromDateObj, "TO_DATE", this.toDateObj)
  }


  resetFilter() {
    // this.showFilter = !this.showFilter
    this.FilterForm.reset();
    this.filterOrderstatus = '';
    this.fromDateObj = '';
    this.toDateObj = '';
    // this.getOderData(this.getpayload, this.pageIndex, this.pageSize)

  }
  viewAllOrder() {
    this.resetFilter()
  }
  submitFilter() {
    console.log(this.fromDateObj);
    console.log(this.toDateObj);
    console.log(this.FilterForm.value.OrderStatus)

    var array = this.FilterForm.value.OrderStatus;
    if (Array.isArray(array)) {
      // Convert the array to a string with values separated by commas
      this.filterOrderstatus = array.join(',');

      console.log(this.filterOrderstatus);
    } else {
      console.log('OrderStatus is not an array.');
    }

    const obj = [];

    if (this.filterOrderstatus) {
      obj.push({
        "key": "STATUS_IN",
        "value": this.filterOrderstatus
      });
    }

    if (this.FilterForm.value.OrderNumber) {
      obj.push({
        "key": "ORDER_NUMBER_IN",
        "value": this.FilterForm.value.OrderNumber
      });
    }

    if (Object.keys(this.fromDateObj).length > 0) {
      obj.push({
        "key": "FROM_DATE",
        "dateValue": this.fromDateObj
      });
    }

    if (Object.keys(this.toDateObj).length > 0) {
      obj.push({
        "key": "TO_DATE",
        "dateValue": this.toDateObj
      });
    }

    // obj now contains only the objects with values

    console.log(obj)
    // this.getOderData(obj, this.pageIndex, this.pageSize)

  }

  emitDates(customDates: any) {
    console.log("ssss", customDates)
    this.customDate = customDates
  }


  ngAfterViewInit() {
    feather.replace();
  }

  addCustomerForm(): void {
    let dialogRef = this.dialog.open(AddDialogboxComponent, {
      width: '648px',
        data: {
          "component": "customer",
          "customerForm": this.customerForm
        }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getCustomer(this.pageIndex, this.pageSize);
    })
  }

  viewEditCustomerForm(data:any): void {
    this.customerForm.setValue = data;
    let dialogRef = this.dialog.open(ViewDialogboxComponent, {
      width: '648px',
        data: {
          "component": "customer",
          "customerForm": this.customerForm
        }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getCustomer(this.pageIndex, this.pageSize);
    })
  }

  downloadExcel(customer: any) {
    // Create Excel data
    const data = [
      ['NO.', 'NAME','Email', 'CW Group Code', 'Created By','Created On', 'Status'],
      [1, customer.customerName,
        customer.email,
        customer.cwGroupCode,
        customer.createdBy,
        customer.createdAt,
        customer.status?.toString() === '1' ? 'Active' : 'Inactive']
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Vendors');
    XLSX.writeFile(wb, 'customer-detail.xlsx');
  }

  downloadAllExcel() {
    const data = [
      ['NO.', 'NAME','Email', 'CW Group Id', 'Created By','Created On', 'Status']
    ];

    this.customerData?.forEach((customer: any, index: number) => {
      data.push([
        index + 1,
        customer.customerName,
        customer.email,
        customer.cwGroupCode,
        customer.createdBy,
        customer.createdAt,
        customer.status.toString() === '1' ? 'Active' : 'Inactive'
      ]);
    });

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'AllCustomers');
    XLSX.writeFile(wb, 'all-customers.xlsx');
  }
}

export interface customerTable{
  id:any;
  branchDetailsList:any;
  modified_On:any;
  active_Ind:any;
  modified_By:any;
  cust_Code:any;
  cust_Name:any;
  email:any;
  cw_Group_Id:any;
  created_By:any;
  created_On:any;
}


