import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { SnackbarService } from '../../services/snackbar.service';
import { MasterService } from '../../services/master.service';

@Component({
  selector: 'app-add-dialogbox',
  templateUrl: './add-dialogbox.component.html',
  styleUrls: ['./add-dialogbox.component.css']
})
export class AddDialogboxComponent {
  addDialogForm: FormGroup | any;
  loader: boolean = false;
  branches: any[] = [];
  states: any[] = []; 
  pageIndex = 0; 
  pageSize = 10;
  vendorData: any = [];
  customerData: any = [];
  vendorBranchDetails: any[] | any;
  customerBranchDetails: any[] | any;
  
  // customerForm: FormGroup | any;
  constructor(private dialog: MatDialogRef<AddDialogboxComponent>, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, private auth: AuthService,
    private snackbarService: SnackbarService, private masterService: MasterService) { }


  addBranch() {
    this.branches.push({}); // Add an empty object to branches array
  }

  openSnackBar(message: string) {
    this.snackbarService.showSnackbar(message);
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  onSubmit() {
    this.loader = true;
    if (this.data['component'] === 'vendor') {
      if (this.addDialogForm.valid) {
        console.log("submit------");
        this.masterService.addVendorDetails(this.addDialogForm.value).subscribe((data) => {
          if (data != null) {
            console.log(data);
            this.loader = false;
            const snackbarMessage = 'Vendor added successfully'
            this.openSnackBar(snackbarMessage);
            this.addDialogForm.reset();
            this.closeDialog();
            this.dialog.close();
          }
        }, error => {
          this.loader = false;
          console.error('API error:', error);
          const snackbarMessage = 'Vendor failed'
          this.openSnackBar(snackbarMessage);

          // Handle error scenario
        });
      }
      else {
        this.loader = false;
        this.markFormGroupTouched(this.addDialogForm);
        const snackbarMessage = 'please enter details'
        this.openSnackBar(snackbarMessage);
      }
    }

    // CUSTOMER
    if (this.data['component'] === 'customer') {
      console.log("submit------");
      if (this.addDialogForm.valid) {
        this.masterService.addCustomerDetails(this.addDialogForm.value).subscribe((data) => {
          if (data != null) {
            console.log(data);
            this.loader = false;
            const snackbarMessage = 'Customer added successfully'
            this.openSnackBar(snackbarMessage);
            this.addDialogForm.reset();
            this.closeDialog();
            this.dialog.close();
          }
        }, error => {
          console.error('API error:', error);
          const snackbarMessage = 'Customer failed'
          this.openSnackBar(snackbarMessage);
          // Handle error scenario
        });
      }
      else {
        this.loader = false;
        this.markFormGroupTouched(this.addDialogForm);
        const snackbarMessage = 'please enter details'
        this.openSnackBar(snackbarMessage);
      }
    }

    // VENDOR-BRANCH
    if (this.data['component'] === 'vendorBranch') {
      console.log("submit------");
      if (this.addDialogForm.valid) {
        const vendorId = this.data['vendorId']; // Retrieve the vendorId
        this.masterService.addVendorBranchDetails(this.addDialogForm.value, vendorId).subscribe((data) => {
          if (data != null) {
            console.log(data);
            this.loader = false;
            const snackbarMessage = 'Vendor branch added successfully'
            this.openSnackBar(snackbarMessage);
            this.addDialogForm.reset();
            this.closeDialog();
            this.dialog.close();
          }
        }, error => {
          console.error('API error:', error);
          const snackbarMessage = 'Vendor branch failed'
          this.openSnackBar(snackbarMessage);
          // Handle error scenario
        });
      }
      else {
        this.loader = false;
        this.markFormGroupTouched(this.addDialogForm);
        const snackbarMessage = 'please enter details'
        this.openSnackBar(snackbarMessage);
      }
    }

    // CUSTOMER
    if (this.data['component'] === 'customerBranch') {
      console.log("submit------");
      if (this.addDialogForm.valid) {
        const custId = this.data['custId'];
        this.masterService.addCustomerBranchDetails(this.addDialogForm.value, custId).subscribe((data) => {
          if (data != null) {
            console.log(data);
            this.loader = false;
            const snackbarMessage = 'Customer branch added successfully'
            this.openSnackBar(snackbarMessage);
            this.addDialogForm.reset();
            this.closeDialog();
            this.dialog.close();
          }
        }, error => {
          console.error('API error:', error);
          const snackbarMessage = 'Customer branch failed'
          this.openSnackBar(snackbarMessage);
          // Handle error scenario
        });
      }
      else {
        this.loader = false;
        this.markFormGroupTouched(this.addDialogForm);
        const snackbarMessage = 'please enter details'
        this.openSnackBar(snackbarMessage);
      }
    }

  }

  ngOnInit(): void {

    this.masterService.getStateDetails().subscribe((response: any) => {
      if (response && response.responseObject) {
        this.states = response?.responseObject?.filter((state:any) => state.id);
      }
    });

    if (this.data['component'] === 'vendor') {
      this.addDialogForm = this.formBuilder.group(this.data['vendorForm'].controls);
    }

    if (this.data['component'] === 'customer') {
      this.addDialogForm = this.formBuilder.group(this.data['customerForm'].controls);
    }

    if (this.data['component'] === 'user') {
      this.addDialogForm = this.formBuilder.group(this.data['userForm'].controls);
    }

    if (this.data['component'] === 'vendorBranch') {
      this.addDialogForm = this.formBuilder.group(this.data['vendorBranchForm'].controls);
    }

    if (this.data['component'] === 'customerBranch') {
      this.addDialogForm = this.formBuilder.group(this.data['customerBranchForm'].controls);
    }
  }

  closeDialog() {
    this.dialog.close();
    this.addDialogForm.reset();
  }
}
