<div class="page-wrapper">
    <div class="content">
        <div class="order-details">
            <div class="content p-0">
                <div class="page-header">
                    <div class="page-title">
                        <h4>Customer Mapping</h4>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <div class="table-top position-relative">
                            <div class="search-set justify-content-between w-100">
                                <div class="search-path page-header ">

                                    <a class="btn btn-added" id="filter_search" (click)="submitFilter()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                        Search
                                    </a>

                                    <a class="btn btn-added ms-3" id="filter_search" (click)="resetFilter()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-cw"><polyline points="23 4 23 10 17 10"></polyline><polyline points="1 20 1 14 7 14"></polyline><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path></svg>
                                        Reset
                                    </a>

                                    <a class="btn btn-added ms-3" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        routerLink="upload-customer-mapping">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                        Upload
                                    </a>

                                    <a class="btn btn-cancel ms-3" (click)="sendMailtoCustomer()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                        Mail
                                    </a>

                                </div>
                                <div class="search search-path page-header d-flex justify-content-end">
                                    <!-- <span>
                                        <input placeholder="docket number" class="form-control search-field"
                                            [(ngModel)]="searchDocketNo" (keyup.enter)="searchByDocketNo()" >
                                    </span> -->
                                  
                                    <div class="pagination  ms-3">
                                        <a class="btn btn-added" (click)="downloadAllExcel(startDate,endDate)" [class.disabled]="noDockets"
                                        [attr.disabled]="noDockets ? true : null">
                                            <svg width="18px" height="18px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#fff" transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)" stroke-width="1.056">
                                                <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#fff" stroke-width="0.672"/>
                                                <g id="SVGRepo_iconCarrier"> <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M3 14.25C3.41421 14.25 3.75 14.5858 3.75 15C3.75 16.4354 3.75159 17.4365 3.85315 18.1919C3.9518 18.9257 4.13225 19.3142 4.40901 19.591C4.68577 19.8678 5.07435 20.0482 5.80812 20.1469C6.56347 20.2484 7.56459 20.25 9 20.25H15C16.4354 20.25 17.4365 20.2484 18.1919 20.1469C18.9257 20.0482 19.3142 19.8678 19.591 19.591C19.8678 19.3142 20.0482 18.9257 20.1469 18.1919C20.2484 17.4365 20.25 16.4354 20.25 15C20.25 14.5858 20.5858 14.25 21 14.25C21.4142 14.25 21.75 14.5858 21.75 15V15.0549C21.75 16.4225 21.75 17.5248 21.6335 18.3918C21.5125 19.2919 21.2536 20.0497 20.6517 20.6516C20.0497 21.2536 19.2919 21.5125 18.3918 21.6335C17.5248 21.75 16.4225 21.75 15.0549 21.75H8.94513C7.57754 21.75 6.47522 21.75 5.60825 21.6335C4.70814 21.5125 3.95027 21.2536 3.34835 20.6517C2.74643 20.0497 2.48754 19.2919 2.36652 18.3918C2.24996 17.5248 2.24998 16.4225 2.25 15.0549C2.25 15.0366 2.25 15.0183 2.25 15C2.25 14.5858 2.58579 14.25 3 14.25Z" fill="#fff"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 16.75C12.2106 16.75 12.4114 16.6615 12.5535 16.5061L16.5535 12.1311C16.833 11.8254 16.8118 11.351 16.5061 11.0715C16.2004 10.792 15.726 10.8132 15.4465 11.1189L12.75 14.0682V3C12.75 2.58579 12.4142 2.25 12 2.25C11.5858 2.25 11.25 2.58579 11.25 3V14.0682L8.55353 11.1189C8.27403 10.8132 7.79963 10.792 7.49393 11.0715C7.18823 11.351 7.16698 11.8254 7.44648 12.1311L11.4465 16.5061C11.5886 16.6615 11.7894 16.75 12 16.75Z" fill="#CCCCCC"/> </g>
                                                </svg>
                                            Export <img src="../../../../assets/img/dowload-excel.svg" class="ms-2">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <ul class="order-data">
                                


                                <li class="table-head1"  [formGroup]="FilterForm">
                    
                                    <span class="d-flex justify-content-center"></span>
                                    <span><input placeholder="Docket No." class="form-control" formControlName="docketNo" [ngModel]="docketNo"></span>
                                    <span class="custom-field">
                                        <ng-container>
                                        <mat-form-field class="custom-select-ui status-field">
                                            <mat-select formControlName="customerName" placeholder="Customer" [panelWidth]="185" (selectionChange)="selectStatus($event)"
                                                multiple panelClass="status-field-option">
                                                <mat-select-trigger>
                                                    <span *ngIf="(FilterForm.get('customerName')?.value?.length || 0) === 1">{{selectedStatusName ? selectedStatusName :selectedStatusName}}</span>
                                                    <ng-container
                                                        *ngIf="(FilterForm.get('customerName')?.value?.length || 0) > 1">
                                                        <span class="example-additional-selection">
                                                            (+{{(FilterForm.get('customerName')?.value?.length ||
                                                            0) - 1}}
                                                            {{FilterForm.get('customerName')?.value?.length === 1
                                                            ?
                                                            'more' : 'more'}})
                                                        </span>
                                                    </ng-container>
                                                </mat-select-trigger>
                                                
                                                    <mat-option *ngFor="let customer of customerList"
                                                        [value]="customer.id">{{customer.customerName}}</mat-option>
                                                
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                    </span>
                                    <span class="date-width">
                                        <mat-form-field class="custom-select-ui date-range">
                                            <mat-select  (selectionChange)="onChangeCondition($event.value)" #matSelect 
                                                placeholder="{{selectedDateRange}}" panelClass="filter-option order" formControlName="dateRange">
                                                <mat-select-trigger  *ngIf="showSelectedStartDate">
                                                    <span>Custom</span>
                                                </mat-select-trigger>
                                                <ng-container *ngFor="let dateOption of dateOptionsList">
                                                    <mat-option [value]="dateOption.value">
                                                        {{ dateOption.viewValue }}
                                                    </mat-option>

                                                </ng-container>
                                                <mat-option value="custom" class="custom customClass" disabled>
                                                    Custom <svg class="right-arrow-option" viewBox="0 0 24 24" width="20" height="20" stroke="#637381" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="right-arrow-option"><polyline points="9 18 15 12 9 6"></polyline></svg>
                                                    <div class="custom-date-rang">
                                                        <custom-date-range
                                                        (emitDates)="handleCustomDateRange($event.startDate, $event.endDate)"></custom-date-range>
                                                    </div>
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </span>
                                    <span class="custom-field"></span>
                                    <span></span>
                                    <span class="custom-field"></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span class="custom-field"></span>
                                    <span></span>
                                    <span class="custom-field"></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span class="custom-field"></span>
                                    <span></span>
                                    <span class="custom-field"></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span class="custom-field"></span>
                                    <!-- <span>Docket Current Status</span> -->
                                    <span></span>
                                </li>

                                <li class="table-head">
                                    <span class="d-flex justify-content-center">Docket ID</span>
                                    <span class="action-column cursor">Docket No.<a (click)="toggleSortOrder()"><svg class="sort-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" id="sort"><path fill="#444" d="M11 7H5l3-4zM5 9h6l-3 4z"></path></svg></a></span>
                                    <span class="custom-field">Customer Name <a (click)="toggleSortOrder()"><svg class="sort-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" id="sort"><path fill="#444" d="M11 7H5l3-4zM5 9h6l-3 4z"></path></svg></a></span>
                                    <span class="date-width action-column cursor">Pickup Date<a (click)="toggleSortOrder()"><svg class="sort-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" id="sort"><path fill="#444" d="M11 7H5l3-4zM5 9h6l-3 4z"></path></svg></a></span>
                                    <span class="custom-field">Docket Current Status</span>
                                    <span>Order No.</span>
                                    <span class="custom-field">Booking City</span>
                                    <span>Booking Zone</span>
                                    <span>Delivery City</span>
                                    <span>Delivery Zone</span>
                                    <span class="custom-field">Consignor Name</span>
                                    <span>Consignor Mobile No.</span>
                                    <span class="custom-field">Consignor Address</span>
                                    <span>Consignor City</span>
                                    <span>Consignor State</span>
                                    <span>Consignor Pincode</span>
                                    <span class="custom-field">Consignee Name</span>
                                    <span>Consignee Mobile</span>
                                    <span class="custom-field">Consignee Address</span>
                                    <span>Consignee City</span>
                                    <span>Consignee State</span>
                                    <span>Consignee Pincode</span>
                                    <span>Good Name</span>
                                    <span>Actual Weight</span>
                                    <span>Charged Weight</span>
                                    <span>Total Packages</span>
                                    <span>Cargo Value</span>
                                    <span class="custom-field">Docket Status</span>
                                    <!-- <span>Docket Current Status</span> -->
                                    <span>Active Status</span>
                                </li>

                                <!-- {{loader}} -->
                                <ng-container *ngIf="loader">
                                    <div>
                                        <div class="container loader d-flex justify-content-center">
                                            <mat-spinner [diameter]="25" [strokeWidth]="2"
                                                class="centre-align"></mat-spinner>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!loader" class="scroll">

                                    <li *ngFor="let docket of custMappData; let i = index" class="scroll">
                                        <span class="d-flex justify-content-center">{{docket.docketId}}</span>
                                        <span class="tracking action-column cursor"><a
                                                (click)="viewDocketTracking(docket)">{{docket.docketNo}}</a></span>
                                        <span class="custom-field">{{docket.customerName }}</span>
                                        <span class="date-width action-column cursor">{{docket.pickupDate | date:'dd/MM/yy'}}</span>
                                        <span class="custom-field">{{docket.docketCurrentStatus}}</span>
                                        <span>{{docket.orderNo}}</span>
                                        <span class="custom-field">{{docket.bookingCity}}</span>
                                        <span>{{docket.bookingZone}}</span>
                                        <span>{{docket.deliveryCity}}</span>
                                        <span>{{docket.deliveryZone}}</span>
                                        <span class="custom-field">{{docket.consignorName}}</span>
                                        <span>{{docket.consignorMobile}}</span>
                                        <span class="custom-field">{{docket.consignorAddress}}</span>
                                        <span>{{docket.consignorCity}}</span>
                                        <span>{{docket.consignorState}}</span>
                                        <span>{{docket.consignorPincode}}</span>
                                        <span class="custom-field">{{docket.consigneeName}}</span>
                                        <span>{{docket.consigneeMobile}}</span>
                                        <span class="custom-field">{{docket.consigneeAddress}}</span>
                                        <span>{{docket.consigneeCity}}</span>
                                        <span>{{docket.consigneeState}}</span>
                                        <span>{{docket.consigneePincode}}</span>
                                        <span>{{docket.goodsName}}</span>
                                        <span>{{docket.actualWeight}}</span>
                                        <span>{{docket.chargedWeight}}</span>
                                        <span>{{docket.totalPackages}}</span>
                                        <span>{{docket.cargoValue}}</span>
                                        <span class="custom-field">{{docket.docketStatus}}</span>
                                        <!-- <span>{{docket.docketCurrentStatus}}</span> -->
                                        <span class="d-flex justify-content-center">
											<span class="badges bg-lightgreen" *ngIf="docket?.status == 1">Active</span>
                                   			<span class="badges bg-lightred" *ngIf="docket?.status == 0">Inactive</span>
										</span>
                                        <!-- <span>{{docket.status?.toString() === '1' ? 'Active' : 'Inactive'}}</span> -->

                                    </li>
                                </ng-container>


                                <div *ngIf="!custMappData || custMappData.length === 0"
                                    class="no-order text-center pt-4 pb-4">
                                    <p class="margin-0">No docket found</p>
                                    <a class="btn btn-primary" (click)="getDocketData(0, 10, startDate, endDate)">View all
                                        dockets</a>
                                </div>

                            </ul>

                        </div>

                    </div>
                </div>
                <div class="pagination search-set justify-content-end flex-end w-100">
                    <mat-paginator #paginator class="demo-paginator" (page)="pageNavigations($event)"
                        [length]="totalCount" [pageSize]="pageSize"
                        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                        [pageIndex]="pageIndex" [showFirstLastButtons]="showFirstLastButtons" aria-label="Select page">
                    </mat-paginator>

                </div>
            </div>
        </div>
    </div>
</div>
