
<div class="main-wrapper">

    <div class="page-wrapper">
        <div class="content">
            <div class="page-header">
                <div class="page-title">
                    <h4>Upload Customer Files</h4>
                    <h6>Bulk upload your files</h6>
                </div>
            </div>

            <!-- /product list -->
            <div class="card">
                <div class="card-body">
                    <div class="requiredfield">
                        <h4>Field must be in .xlsx format</h4>
                    </div>
                    <form [formGroup]="uploadForm">
                        <div class="row w-50">
                                <div class="col">
                                    <label>File Type</label>
                                    <mat-select class="form-control mt-1" formControlName="fileType">
                                        <mat-option class="custom-mat-option" value="Contracts">Contracts</mat-option>
                                        <mat-option class="custom-mat-option" value="Extension">Extension</mat-option>
                                        <mat-option class="custom-mat-option" value="NDAs">NDAs</mat-option>
                                    </mat-select>
                                </div>
                            <div class="col">
                                <label >Date</label>
                                <span class=" date-width pt-3">
                                    <mat-form-field class="custom-select-ui date-range">
                                        <mat-select (selectionChange)="onChangeCondition($event.value)"
                                            placeholder="Select date" panelClass="filter-option order">
                                            <ng-container *ngFor="let dateOption of dateOptionsList">
                                                <mat-option [value]="dateOption.value">
                                                    {{ dateOption.viewValue }}
                                                </mat-option>

                                            </ng-container>
                                            <mat-option value="custom" class="custom" disabled>
                                                Custom
                                                <div class="custom-date-rang">
                                                    <custom-date-range
                                                        (emitDates)="handleCustomDateRange($event.startDate, $event.endDate)"></custom-date-range>
                                                </div>
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </span>
                            </div>
                        </div>
                        <div class="row mt-3">
                            
                                <div class="col-lg-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="file">
                                            Upload Xlsx File
                                            <div class="image-upload mt-1">
                                                <div class="image-uploads">
                                                    <img src="../../../../assets/img/icons/upload.svg" alt="img">
                                                    <h4>Drag and drop a file to upload</h4>
                                                    <input #fileUpload type="file" formControlName="fileUpload"
                                                        (change)="selectFile($event)" [multiple]="true" accept=".xlsx">
                                                    <div *ngIf="uploadForm.get('fileUpload').hasError('required') && uploadForm.get('fileUpload').touched"
                                                        class="error-message text-danger">&nbsp;File upload is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>

                            <div class="col-lg-12">
                                <div class="form-group mb-0">
                                    <a href="javascript: void(0);" id="alert-success"
                                        class="btn btn-submit waves-effect waves-light me-2"
                                        (click)="upload()" *ngIf="!loader">Submit</a>
                                    <a href="javascript:void(0);" class="btn btn-cancel"
                                        routerLink="/mis/customer-mapping">Cancel</a>
                                </div>
                            </div>
                            <ng-container *ngIf="loader">
                                <div>
                                    <div class="container loader d-flex justify-content-center">
                                        <mat-spinner [diameter]="25" [strokeWidth]="2" class="centre-align"></mat-spinner>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>