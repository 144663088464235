  <!-- Header -->
  <div class="header">
    
    <!-- Logo -->
     <div class="header-left active">
        <!-- <a href="index.html" class="logo logo-normal">
            <img class="ascLogo" style="margin-left: 20px;" src="assets/login/apollo-new-logo.png" width="140px" height="80px">
        </a> -->
        <a href="/journey-management/docket-status" class="logo logo-normal">
            <img src="../../../../assets/img/apolloLogo.png"  alt="">
        </a>
        <a href="index.html" class="logo logo-white">
            <img src="../../../../assets/img/logo-white.png"  alt="">
        </a>
        <a href="index.html" class="logo-small">
            <img src="../../../../assets/img/logo-small.png"  alt="">
        </a>
        <a id="toggle_btn" (click)="toggleNav()" href="javascript:void(0);">
            <i data-feather="chevrons-left" class="feather-16"></i>
        </a>
    </div>
    <!-- /Logo -->
    
    <a id="mobile_btn" class="mobile_btn" (click)="toggleNavMobile()">
        <span class="bar-icon">
            <span></span>
            <span></span>
            <span></span>
        </span>
    </a>
    
    <!-- Header Menu -->
    <ul class="nav user-menu">
    
        <!-- Search -->
        <li class="nav-item nav-searchinputs">
            <div class="top-nav-search">
                
                <a href="javascript:void(0);" class="responsive-search">
                    <i class="fa fa-search"></i>
                </a>
                <!-- <form action="index.html# ">
                    <div class="searchinputs">
                        <input type="text" placeholder="Search">
                        <div class="search-addon">
                            <span><i data-feather="search" class="feather-14"></i></span>
                        </div>
                    </div>
                    <a class="btn"  id="searchdiv"><img src="../../../../assets/img/icons/search.svg" alt="img"></a>
                </form> -->
            </div>
        </li>
       
        <li class="nav-item dropdown has-arrow main-drop">
            <a href="javascript:void(0);" class="dropdown-toggle nav-link userset" data-bs-toggle="dropdown">
                <span class="user-info">
                    <span class="user-letter">
                        <img src="../../../../assets/img/profiles/avatar.svg" alt="" class="img-fluid">
                    </span>
                    <span class="user-detail">
                        <span class="user-name">{{firstName}} <span class="ms-1">{{lastName}}</span></span>
                        <span >{{role}}</span>
                    </span>
                </span>
            </a>
            <div class="dropdown-menu menu-drop-user">
                <div class="profilename">
                    <div class="profileset">
                        <span class="user-img"><img src="../../../../assets/img/profiles/avatar.svg" alt="">
                        <span class="status online"></span></span>
                        <div class="profilesets">
                            <h6>{{firstName}} <span>{{lastName}}</span></h6>
                            <h6 >{{role}}</h6>
                        </div>
                    </div>
                    <hr class="m-0">
                    <a class="dropdown-item"> <i class="me-2"  data-feather="user"></i> My Profile</a>
                    <a class="dropdown-item" (click)="changePassword()" > <i class="me-2"  data-feather="frown" ></i> Change Password</a>
                    <a class="dropdown-item" ><i class="me-2" data-feather="settings"></i>Settings</a>
                    <hr class="m-0">
                    <a (click)="logOut()" class="dropdown-item logout pb-0" ><img src="../../../../assets/img/icons/log-out.svg" class="me-2" alt="img">Logout</a>
                </div>
            </div>
        </li>
    </ul>
    <!-- /Header Menu -->
    
    <!-- Mobile Menu -->
    <div class="dropdown mobile-user-menu">
        <a href="javascript:void(0);" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
        <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" href="profile.html">My Profile</a>
            <a class="dropdown-item"  href="profile.html">Forgot Password</a>
            <a class="dropdown-item" href="generalsettings.html">Settings</a>
            <a class="dropdown-item" (click)="logOut()" >Logout</a>
        </div>
    </div>
    <!-- /Mobile Menu -->
</div>
<!-- Header -->