import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { CardsComponent } from './component/cards/cards.component';
import { MasterRoutingModule } from './master-routing.module';
import { AppMaterialModule } from '../_material/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VendorComponent } from './container/vendor/vendor.component';
import { CustomerComponent } from './container/customer/customer.component';
import { UserComponent } from '../setting/user/user.component';
import { PipeModule } from "../_pipe/pipe/pipe.module";
import { VendorBranchComponent } from './container/vendor-branch/vendor-branch.component';
import { CustomerBranchComponent } from './container/customer-branch/customer-branch.component';
import { AddVendorFilesComponent } from './container/add-vendor-files/add-vendor-files.component';
import { AddCustomerFilesComponent } from './container/add-customer-files/add-customer-files.component';
import { VendorFilesComponent } from './container/vendor-files/vendor-files.component';

@NgModule({
    declarations: [
        VendorComponent,
        CardsComponent,
        CustomerComponent,
        UserComponent,
        VendorBranchComponent,
        CustomerBranchComponent,
        AddVendorFilesComponent,
        AddCustomerFilesComponent,
        VendorFilesComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        MasterRoutingModule, SharedModule, AppMaterialModule, ReactiveFormsModule,
        PipeModule
    ]
})
export class MasterModule { }
