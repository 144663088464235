import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'date'
})
export class Date implements PipeTransform {

  transform(value: string, format: any): any {
    const dateformat = 'DD-MM-YYYY HH:mm:ss'; // Specify the original date format
    const outputFormat = 'DD/MM/YY'; // Specify the desired output format
    
    if (!value) {
      return '';
    }
    
    // Parse the input date string using the specified format
    const parsedDate = moment(value, dateformat);
    
    // Format the parsed date into the desired output format
    return parsedDate.format(outputFormat);
  }


}
