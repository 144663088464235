<!-- Vendor -->
<!-- <ng-container *ngIf="loader" >
    <div>
        <div class="container loader d-flex justify-content-center">
            <mat-spinner [diameter]="25" [strokeWidth]="2"
                class="centre-align"></mat-spinner>
        </div>
    </div>
</ng-container> -->

<div class="container-fluid scroll" *ngIf="data['component']==='vendor'">

    <div class="main-content mt-2">
        <div class="row">
            <div class="col">
                <p mat-dialog-title class="title">Edit Vendor</p>
                <p class="sub-heading mr-3">editing vendors to the list</p>
            </div>
            <div class="col-auto"> <span class="close-icon d-flex justify-content-end"><img
                        src="../../../../assets/img/icons/close-icon.svg" (click)="closeDialog()"></span></div>
        </div>

        <form [formGroup]="viewDialogForm">
            <div *ngIf="loader">
                <div class=" loader">
                    <mat-spinner [diameter]="25" [strokeWidth]="2" class="centre-align"></mat-spinner>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label for="vendorName">Vendor Name</label><br>
                    <input type="text" formControlName="vendorName" class="form-control w-100 disabled" readonly>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col">
                    <label for="emailId">Email Id</label><br>
                    <input type="email" class="form-control " formControlName="email">

                </div>
                <div class="col">
                    <label for="emailId">Reports Email</label><br>
                    <input type="email" class="form-control" formControlName="reportsEmail">
                </div>

            </div>

            <div class="row">
                <div class="col">
                    <label for="cw_Group_Id">CW Group Id</label><br>
                    <input type="text" formControlName="cwGroupCode" class="form-control disabled" readonly>
                </div>
                <div class="col">
                    <label for="createdBy">Created By</label>
                    <input type="text" formControlName="createdBy" class="form-control disabled" readonly>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-sm-6">
                    <label for="active_Ind" class="required">Active Status</label>
                    <mat-select class="form-control" formControlName="status">
                        <mat-option [value]="1" class="custom-mat-option">Active</mat-option>
                        <mat-option [value]="0" class="custom-mat-option">Inactive</mat-option>
                    </mat-select>
                </div>
            </div>
        </form>
        <div class="row mt-3 mb-2">
            <div class="col d-flex justify-content-start">
                <a class="btn btn-submit" (click)="onSave()">Save</a>
                <!-- <a class="btn btn-submit me-2" *ngIf="enableEdit === true" (click)="onEdit()">Edit</a> -->
            </div>
        </div>

    </div>
</div>

<!-- CUSTOMER -->
<div class="container-fluid scroll" *ngIf="data['component']==='customer'">
    <div class="main-content mt-2">
        <div class="row">
            <div class="col">
                <p mat-dialog-title class="title">Edit Customer</p>
                <p class="sub-heading mr-3">editing customer to the list</p>
            </div>
            <div class="col-auto"> <span class="close-icon d-flex justify-content-end"><img
                        src="../../../../assets/img/icons/close-icon.svg" (click)="closeDialog()"></span></div>
        </div>
        <form [formGroup]="viewDialogForm">
            <div *ngIf="loader">
                <div class=" loader">
                    <mat-spinner [diameter]="25" [strokeWidth]="2" class="centre-align"></mat-spinner>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label for="custName">Customer Name</label><br>
                    <input type="text" formControlName="customerName" class="form-control w-100 disabled" readonly>
                </div>

                <div class="col">
                    <label for="email">Email Id</label><br>
                    <input type="email" class="form-control" formControlName="email">
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <label for="cw_Group_Id">CW Group Id</label><br>
                    <input type="text" formControlName="cwGroupCode" class="form-control">
                </div>
                <div class="col">
                    <label for="createdBy">Created By</label>
                    <input type="text" formControlName="createdBy" class="form-control disabled" readonly>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-sm-6">
                    <label for="activeStatus" class="required">Active Status</label>
                    <mat-select class="form-control" formControlName="status">
                        <mat-option [value]="1" class="custom-mat-option">Active</mat-option>
                        <mat-option [value]="0" class="custom-mat-option">Inactive</mat-option>
                    </mat-select>
                </div>
            </div>
        </form>
        <div class="row mt-3 mb-2">
            <div class="col d-flex justify-content-start">
                <a class="btn btn-submit" (click)="onSave()">Save</a>
            </div>
        </div>
    </div>
</div>

