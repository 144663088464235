export const environment = {
  production: true,
  // BASE_DOMAIN:'https://dev.goalfa.in/ptl/',
  LOGIN_DOMAIN:'https://dev.goalfa.in/',
  COOKIE_DOMAIN: 'localhost',
  ORDER_URL: 'https://dev.goalfa.in/',
  BASE_DOMAIN:'https://ptl.apollosupplychain.com/ptl/api/v1/ptl/',
  BASE_URL:'https://ptl.apollosupplychain.com/ptl-service/'

};
