<div class="page-wrapper">
    <div class="content">
      <div class="order-details">
        <div class="content p-0">
          <div class="page-header">
            <div class="page-title">
              <h4>Users list</h4>
            </div>
            <div class="choose-warehouse">
              <!-- <h3>Select Warehouse</h3> -->
              <!-- <mat-form-field class="custom-select-ui status-field">
                <mat-select
                  placeholder="Warehouse"
                  (selectionChange)="onwarehouseChange($event)"
                  [panelWidth]="185"
                  multiple
                  panelClass="status-field-option"
                >
                  <mat-option
                    *ngFor="let Warehouse of wareHouseList"
                    [value]="Warehouse.id"
                    >{{ Warehouse?.warehouseName }}</mat-option
                  >
                </mat-select>
              </mat-form-field> -->
            </div>
            <div class="page-btn">
                      <a (click)="addUser()" class="btn btn-added">
                        <!-- <img src="assets/img/icons/plus.svg" alt="img" class="me-1"> -->
                        Add User</a>
                      </div>
          </div>
  
          <div class="card">
              <div class="card-body">
                <!-- <div class="table-top pt-0"> -->
                    <!-- <div class="search-set">
                        <div class="search-input">
                            <a class="btn btn-searchset"><img src="assets/img/icons/search-white.svg" alt="img"></a>
                        </div>
                    </div> -->
                    <!-- <div class="wordset">
                        <ul>
                            <li>
                                <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img src="assets/img/icons/pdf.svg" alt="img"></a>
                            </li>
                            <li>
                                <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img src="assets/img/icons/excel.svg" alt="img"></a>
                            </li>
                            <li>
                                <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img src="assets/img/icons/printer.svg" alt="img"></a>
                            </li>
                        </ul>
                    </div> -->
                <!-- </div> -->
                <div class="table-responsive">
                    <table class="table  datanew">
                        <thead>
                            <tr>
                                <th>User name</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Role</th>
                                <th>Status</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of userList?.content; let i = index;">
                                <td>{{user.userName}}</td>
                                <td>{{user.fullName}}</td>
                                <td>{{user.email}}</td>
                                <td>{{user.mobile}}</td>
                                <td>{{user.userRole}}</td>
                                <td>
                                    <span class="badges bg-lightgreen" *ngIf="user?.status == 1">Active</span>
                                    <span class="badges bg-lightred" *ngIf="user?.status == 0">Inactive</span>
                                </td>
                                <td class="text-end">
                                    <a class="me-3" (click)="editUser(user)">
                                        <img src="assets/img/icons/edit.svg" alt="img">
                                    </a>
                                    <!-- <a class="me-3 confirm-text" href="javascript:void(0);">
                                        <img src="assets/img/icons/delete.svg" alt="img">
                                    </a> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                  </div>
                  <div class="pagination search-set justify-content-end flex-end w-100">
                    <mat-paginator class="demo-paginator" (page)="pageNavigations($event)" [length]="length" [pageSize]="pageSize"
                      
                      aria-label="Select page">
                    </mat-paginator>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  