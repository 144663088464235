
<!-- USER ROLE LIST -->
<div class="sidebar" id="sidebar ">
    <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu">
            <ul>
                <li class="submenu-open" *ngIf="roleService?.isActiveModule('Master')">
                    <h6 class="submenu-hdr">Master</h6>
                    <ul>
                        <li *ngIf="roleService?.isActiveSubmodule('Customer')" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }" (click)="toggleNavMobile()"><a routerLink="/master/customer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                <span>Customer</span></a></li>
                        <li *ngIf="roleService?.isActiveSubmodule('Vendor')" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }" (click)="toggleNavMobile()"><a routerLink="/master/vendor">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-truck"><rect x="1" y="3" width="15" height="13"></rect><polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon><circle cx="5.5" cy="18.5" r="2.5"></circle><circle cx="18.5" cy="18.5" r="2.5"></circle></svg>
                                <span>Vendor</span></a></li>

                    </ul>
                </li>


                <li class="submenu-open" *ngIf="roleService?.isActiveModule('Journey Management')">
                    <h6 class="submenu-hdr">Journey Management</h6>
                    <ul>
                        <li *ngIf="roleService?.isActiveSubmodule('Customer Mapping')" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }" (click)="toggleNavMobile()"><a routerLink="/journey-management/customer-mapping">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                                <span>Customer Mapping</span></a></li>
                        <li *ngIf="roleService?.isActiveSubmodule('Docket Status')" routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }" (click)="toggleNavMobile()"><a routerLink="/journey-management/docket-status">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-box"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                                <span>Docket Status</span></a></li>
                    </ul>
                </li>


                <!-- <li class="submenu-open"> -->
                <li class="submenu-open" *ngIf="roleService?.isActiveModule('Reports')">
                    <h6 class="submenu-hdr">Reports</h6>
                    <ul>
                        <li  *ngIf="roleService?.isActiveSubmodule('Customer MIS')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="toggleNavMobile()"><a routerLink="/reports/customer-mis">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-book"><path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path><path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path></svg>
                            <span>Customer MIS</span></a></li>
                        <li *ngIf="roleService?.isActiveSubmodule('ASC MIS')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="toggleNavMobile()"><a routerLink="/reports/asc-mis">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                            <span>ASC MIS</span></a></li>
                    </ul>
                </li>

                <li class="submenu-open" *ngIf="roleService?.isActiveModule('Settings')">
                    <h6 class="submenu-hdr">Settings</h6>
                    <ul>
                        <li  *ngIf="roleService?.isActiveSubmodule('User')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="toggleNavMobile()"><a
                                routerLink="/setting/user">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                                <span>User</span></a></li>
                    </ul>
                </li>

                <li class="submenu-open mobile-only">
                    <!-- <h6 class="submenu-hdr">Settings</h6> -->
                    <ul>
                        <li class="mobile-only"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a (click)="changePassword()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-3"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>
                                <span>Change Password </span></a>
                        </li>
                    </ul>
                </li>

                <li class="submenu-open">
                    <ul>
                        <li>
                            <a (click)="logOut()"><i data-feather="log-out"></i><span>Logout</span> </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>