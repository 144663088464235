import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { environment } from 'src/environments/environment';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  getMethod(endpoint: string) {
    const url = `${environment.BASE_DOMAIN}${endpoint}`;
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.get(url);
  }

  public postMethod(endpoint: string, data: any) {
    const url = `${environment.BASE_DOMAIN}${endpoint}`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    // headers.append('Content-Type', 'application/json');
    return this.http.post(url, data, { headers, responseType: 'text' });
  }


  public putMethod(endpoint: string, data: any): Observable<Object>  {
    const url = `${environment.BASE_DOMAIN}${endpoint}`;
    // const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let headers = new HttpHeaders().set('Content-Type', 'multipart/form-data');
    headers = headers.set('Access-Control-Allow-Origin', '*');
    console.log("header" + headers)
    return this.http.put(url, data, { headers: headers });
}


  get(url: any, options = {}) {
    return this.http.get(url, options);
  }

  post(url: any, data: any) {
    let headers: any = new Headers();
    headers.append('Content-Type', 'application/json');
    return this.http.post(url, data,{headers:headers});
    // return this.http.post(url, data, httpOptions);
  }

  put(url: any, data: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(url, data,{headers:headers});
  }

  // delete(url, httpOptions = {}) {
  //   return this.http.delete(url, httpOptions);
  // }

  // getFile(url) {
  //   return this.http.get(url, { responseType: 'blob' });
  // }

}
