import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from './shared/services/auth.service';
import { Configuration } from './constants/constants/config';
import { SharedModule } from './shared/shared.module';
import { ResponseInterceptor, responseProvider } from './_interceptor/response.interceptor';
import { TokenInterceptor, tokenProvider } from './_interceptor/token.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { AppMaterialModule } from './_material/material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { SidebarComponent } from './shared/component/sidebar/sidebar.component';
import { HeaderComponent } from './shared/component/header/header.component';
import { TimePipe } from './_pipe/time.pipe';
import { FileSelectDirective } from 'ng2-file-upload';
import { PipeModule } from './_pipe/pipe/pipe.module';
import { DocketStatusComponent } from './Track and Trace/docket-status/docket-status.component';
import { MasterModule } from './master/master.module';
import { ReportsModule } from './Reports/reports.module';
import { JourneyManagementModule } from './journey-management/journey-management.module';
import { CustomerMisComponent } from './Reports/customer-mis/customer-mis.component';
import { AscMisComponent } from './Reports/asc-mis/asc-mis.component';
import { CreateUserComponent } from './setting/create-user/create-user.component';
import { ChangePasswordComponent } from './setting/change-password/change-password.component';

// import { MasterComponent } from './master/master.component';

// import { DatepipePipe } from './_pipe/datepipe.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    SidebarComponent,
    HeaderComponent,
    DocketStatusComponent,
    // CustomerMisComponent,
    // AscMisComponent,
    CreateUserComponent,
    ChangePasswordComponent
    // MasterComponent
    // DatepipePipe,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    AppMaterialModule,
    BrowserAnimationsModule,
    PipeModule,
    MasterModule,
    // ReportsModule,
    JourneyManagementModule
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,

  ],
  providers: [AuthService, Configuration, DatePipe, TimePipe,
    tokenProvider, responseProvider, CookieService, FileSelectDirective,
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
