export class Configuration {
  public CONFIG_URL = {
    USER_LOGIN: 'auth-service/auth/login',
    SIGNUP: 'auth-service/auth/login',
    ROLE_MAPING:'auth-service/role/getRoleMapping',
    GET_ORDER: 'order-service/order/getOrders',
    GET_ALL_STATUS: 'order-service/master/getOrderStatusMaster',
    CREATE_ORDER:'order-service/order/createOrder',
    UPDATE_ORDER: 'order-service/order/updateOrder',
    GET_ORDER_BY_ID:'order-service/order/getOrder?orderNumber=',
    GET_CLIENTS_BY_ID:'client-service/info/getClientById?clientId=',
    GET_MULTI_CLIENTS_BY_ID:'client-service/info/getMultiClientById',
    GET_MULTI_WAREHOUSE_BY_ID:'warehouse-service/info/getMultiWarehouseById?warehouseList=',
    GET_TRACKING_STATUS_BY_ID:'order-service/order/createLabel?orderNumber=',
    MERGE_LABELS:'/carrier-service/labels/mergeLabels',
    PENDING_MERGE_LABELS:'order-service/order/createMultiLabel?orderNumber=',
    GET_TRACKING_ORDER:'order-service/order/getOrderTrackingStatus?orderNumber=',
    GET_RETURN_REASON:'order-service/master/getReturnsReasonMaster',
    BULK_ORDER_UPLOAD:'utils-service/uploads/uploadOrders?Id=1',
    CREATE_ORDER_RETURN:'order-service/order/createReturnOrder',
    ORDER_SUMMERY:'order-service/dashboard/orderSummary',
    
    // PTL
    VENDOR_DETAILS:'getVendorList',
    CUSTOMER_DETAILS:'getCustomerList',
    ADD_VENDOR_DETAILS:'addVendor',
    ADD_CUSTOMER_DETAILS:'addCustomer',
    GET_DOCKET_DETAILS:'getDocketDetails?docketId=',
    GET_USER_DETAILS:'getUserList',
    GET_DOCKET_LIST:'getDocketList',
    ADD_USER_DETAILS:'addUser',
    UPLOAD:'UpdateDocketCustomer',
    SEND_MAIL_TO_CUSTOMER:'createMISReport',
    GET_DOCKET_DETAILS_BY_DOCKET_NO:'getDocketDetailsByNoAndVendorId',
    ADD_VENDOR_BRANCH_DETAILS:'addVendorBranches',
    ADD_CUSTOMER_BRANCH_DETAILS:'addCustomerBranches',
    UPLOAD_VENDOR_FILES:'addVendorFiles',
    USER_LIST:'auth-service/auth/getUsers',
    CREATE_USER:'auth-service/auth/createUser',
    UPDATE_USER:'auth-service/auth/updateUser',
    CHECK_USER:'auth-service/auth/checkUserName?userName=',
    UPDATE_PASSWORD:'auth-service/auth/updatePassword',


    // PTL - REVAMP
    GET_VENDOR:'vendor/getVendors',
    GET_CUSTOMER:'customer/getCustomersByFilters',
    ADD_VENDOR:'vendor/addVendorDetails',
    ADD_CUSTOMER:'customer/addCustomerDetails',
    GET_DOCKET_STATUS_BY_NO:'docket/getDockets',
    ADD_VENDOR_BRANCH:'vendor/addVendorDetails',
    ADD_CUSTOMER_BRANCH:'customer/addCustomerDetails',
    GET_STATE:'state/getStates',
    UPLOAD_CUSTOMER_MAPPING: 'docket/uploadDocketCustomer',
    SEND_MIS_TO_CUSTOMER:'docket/createMisReport',
    UPLOAD_VENDOR_FILE:'/upload/uploadFile',
    GET_VENDOR_FILE: 'vendor/getVendorFiles'
  };
}