import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {
  changePasswordForm: FormGroup | any;
  loader: boolean = false;
  passwordDoesNotMatch = false;
  constructor(private dialog: MatDialogRef<ChangePasswordComponent>, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, private auth: AuthService,
    private snackbarService: SnackbarService) {
    this.changePasswordForm = this.formBuilder.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

  openSnackBar(message: string) {
    this.snackbarService.showSnackbar(message);
  }

  public checkPasswordMatch() {
    const passwordControl = this.changePasswordForm.get('password');
    const confirmPasswordControl = this.changePasswordForm.get('confirmPassword');

    if (passwordControl && confirmPasswordControl) {
      this.passwordDoesNotMatch = passwordControl.value !== confirmPasswordControl.value;

      if (!this.passwordDoesNotMatch) {
        confirmPasswordControl.setErrors(null);
      }
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  submit(): void {
    // debugger
    if (this.changePasswordForm.invalid) {
      return;
    }

    const newPassword = this.changePasswordForm.value.newPassword;
    const confirmPassword = this.changePasswordForm.value.confirmPassword;

    if (newPassword !== confirmPassword) {
      this.changePasswordForm.get('confirmPassword').setErrors({ mismatch: true });
      return;
    }

    const updateobj = {
      "user": localStorage.getItem('user'),
      "password": this.changePasswordForm.value.newPassword,
      "platform":'ptl'
    }

    if (this.changePasswordForm.valid) {
      // this.submittedloader = true

      this.auth.changePassword(updateobj).subscribe((res: any) => {
        if (res) {
          this.dialog.close();
          console.log(res)
        }
      })

    }


  }

  ngOnInit(): void {
    if (this.data['component'] === 'changePassword') {
      this.changePasswordForm = this.formBuilder.group(this.data['changePasswordForm'].controls);
    }

  }

  closeDialog() {
    this.dialog.close();
    this.changePasswordForm.reset();
  }
}
