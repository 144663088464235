import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(value: string): string {
    const inputFormat = 'HH:mm:ss';
    const outputFormat = 'hh:mm a';

    if (!value) {
      return '';
    }

    return moment(value, inputFormat).format(outputFormat);
  }
}
