import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JourneyManagementRoutingModule } from './journey-management-routing.module';
import { CustomerMappingComponent } from './container/customer-mapping/customer-mapping.component';
import { UploadCustomerMappingComponent } from './container/upload-customer-mapping/upload-customer-mapping.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MasterRoutingModule } from '../master/master-routing.module';
import { AppMaterialModule } from '../_material/material/material.module';
import { SharedModule } from '../shared/shared.module';
import { PipeModule } from '../_pipe/pipe/pipe.module';
import { TrackingPopupComponent } from './component/tracking-popup/tracking-popup.component';


@NgModule({
  declarations: [
    CustomerMappingComponent,
    UploadCustomerMappingComponent,
    TrackingPopupComponent,
  ],
  imports: [
    JourneyManagementRoutingModule,
    FormsModule,
    CommonModule,
    MasterRoutingModule, SharedModule, AppMaterialModule, ReactiveFormsModule,
    PipeModule
  ]
})
export class JourneyManagementModule { }
