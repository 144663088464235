// response.interceptor.ts

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modifiedReq = request.clone({
      headers: request.headers.set('Cache-Control', 'no-cache, no-store, must-revalidate')
                         .set('Pragma', 'no-cache')
                         .set('Expires', '0')
    });
    return next.handle(modifiedReq);
  }
    // return next.handle(request).pipe(
    //   tap(
    //     (event: HttpEvent<any>) => {
    //       // You can handle the response here
    //       // console.log('Response Interceptor:', event);
    //     },
    //     (error: any) => {
    //       // You can handle errors here
    //       // console.error('Response Error Interceptor:', error);
    //     }
    //   )
    // );
  }

export let responseProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ResponseInterceptor, 
  multi: true,
};