<div class="container-fluid scroll" *ngIf="data['component']==='vendor'">
    <div class="main-content mt-2">
        <div class="row">
            <div class="col">
                <p mat-dialog-title class="title">Add Vendors
                </p>
                <p class="sub-heading mr-3">Adding vendors to the list</p>
            </div>
            <div class="col-auto"> <span class="close-icon d-flex justify-content-end"><img
                        src="../../../../assets/img/icons/close-icon.svg" (click)="closeDialog()"></span></div>
        </div>
        <form [formGroup]="addDialogForm">
            <div *ngIf="loader">
                <div class=" loader">
                    <mat-spinner [diameter]="25" [strokeWidth]="2" class="centre-align"></mat-spinner>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label for="vendorName" class="required">Vendor Name</label><br>
                    <input type="text" formControlName="vendorName" class="form-control w-100" required>
                    <mat-error
                        *ngIf="addDialogForm.get('vendorName').hasError('required')  && addDialogForm.get('vendorName').touched">
                        Vendor name is required.
                    </mat-error>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <label for="emailId" class="required">Email Id</label><br>
                    <input type="email" class="form-control" formControlName="email" placeholder="info@gati.com">
                    <mat-error
                        *ngIf="addDialogForm.get('email').hasError('required') && addDialogForm.get('email').touched">
                        Email is required.
                    </mat-error>
                    <mat-error
                        *ngIf="addDialogForm.get('email').hasError('pattern') && addDialogForm.get('email').touched">
                        Valid email is required.
                    </mat-error>
                </div>
                <div class="col">
                    <label for="emailId" class="required">Reports Email </label><br>
                    <input type="email" class="form-control" formControlName="reportsEmail" placeholder="info@gati.com">
                    <mat-error
                        *ngIf="addDialogForm.get('reportsEmail').hasError('required') && addDialogForm.get('reportsEmail').touched">
                        Email is required.
                    </mat-error>
                    <mat-error
                        *ngIf="addDialogForm.get('reportsEmail').hasError('pattern') && addDialogForm.get('reportsEmail').touched">
                        Valid email is required.
                    </mat-error>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <label for="groupId">Group ID</label>
                    <input type="text" class="form-control" formControlName="groupId">

                </div>

                <div class="col">
                    <label for="activeStatus" class="required">Active Status</label>
                    <mat-select class="form-control" formControlName="activeStatus" placeholder="Active">
                        <mat-option value="1" class="custom-mat-option">Active</mat-option>
                        <mat-option value="0" class="custom-mat-option">Inactive</mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="addDialogForm.get('activeStatus').hasError('required') && addDialogForm.get('activeStatus').touched">
                        Status is required.
                    </mat-error>
                </div>
            </div>

            <!-- <p class="sub-heading mr-3">Branch details</p> -->
            <mat-expansion-panel hideToggle class="mt-2">
                <mat-expansion-panel-header>
                    <mat-panel-title class="pt-2">
                        <span class="sub-title col d-flex justify-content-start">Branch details</span>
                        <span class="d-flex justify-content-end col">
                            <img src="../../../../assets/img/icons/dropdown.svg">
                        </span>
                    </mat-panel-title>

                </mat-expansion-panel-header>
                <div class="row mt-2">
                    <div class="col">
                        <label class="required">Branch Name</label>
                        <input class="form-control" type="text" formControlName="branchName">
                        <mat-error
                            *ngIf="addDialogForm.get('branchName').hasError('required')  && addDialogForm.get('branchName').touched">
                            Branch name is required.
                        </mat-error>
                    </div>
                    <div class="col">
                        <label for="phoneNo" class="required">Phone No.</label>
                        <input class="form-control no-spinner" type="number" formControlName="phoneNo">
                        <mat-error
                            *ngIf="addDialogForm.get('phoneNo').hasError('required') && addDialogForm.get('phoneNo').touched">
                            Phone number is required
                        </mat-error>
                        <mat-error
                            *ngIf="addDialogForm.get('phoneNo').hasError('pattern') && addDialogForm.get('phoneNo').touched">
                            Invalid phone number
                        </mat-error>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col">
                        <label for="address" class="required">Address</label><br>
                        <textarea class="form-control" formControlName="address" style="height: 107px;"></textarea>
                        <mat-error
                            *ngIf="addDialogForm.get('address').hasError('required') && addDialogForm.get('address').touched">
                            Address is required.
                        </mat-error>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col">
                        <label class="required">City</label>
                        <input class="form-control" type="text" formControlName="city">
                        <mat-error
                            *ngIf="addDialogForm.get('city').hasError('required') && addDialogForm.get('city').touched">
                            City is required.
                        </mat-error>
                    </div>
                    <div class="col">
                        <label class="required">State</label>
                        <mat-select class="form-control" formControlName="state" placeholder="Select state">
                            <mat-option *ngFor="let state of states" [value]="state.id">{{ state.stateName
                                }}</mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="addDialogForm.get('state').hasError('required') && addDialogForm.get('state').touched">
                            State is required.
                        </mat-error>
                    </div>


                </div>
                <div class="row mt-2">
                    <div class="col">
                        <label for="pincode" class="required">Pincode</label><br>
                        <input class="form-control" formControlName="pincode" placeholder="620013">
                        <mat-error
                            *ngIf="addDialogForm.get('pincode').hasError('required') && addDialogForm.get('pincode').touched">
                            Pincode is required.
                        </mat-error>
                        <mat-error
                            *ngIf="addDialogForm.get('pincode').hasError('pattern') && addDialogForm.get('pincode').touched">
                            Enter valid pincode
                        </mat-error>
                    </div>
                    <div class="col">
                        <label>ERP ID</label>
                        <input class="form-control" type="text" formControlName="erpId">

                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col">
                        <label class="required">Address Code</label>
                        <input class="form-control no-spinner" type="number" formControlName="addressCode">
                        <mat-error
                            *ngIf="addDialogForm.get('addressCode').hasError('required') && addDialogForm.get('addressCode').touched">
                            Address code is required.
                        </mat-error>
                    </div>
                    <div class="col">
                        <label for="gstNo" class="required">GST </label><br>
                        <input class="form-control" formControlName="gst" placeholder="33AAAA0000A1Z3">
                        <mat-error
                            *ngIf="addDialogForm.get('gst').hasError('required') && addDialogForm.get('gst').touched">
                            GST is required.
                        </mat-error>
                        <mat-error
                            *ngIf="addDialogForm.get('gst').hasError('pattern') && addDialogForm.get('gst').touched">
                            Enter valid GST number
                        </mat-error>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-md-6">
                        <label for="pan" class="required">PAN </label><br>
                        <input class="form-control" formControlName="pan" placeholder="BWZPS3385L">
                        <mat-error
                            *ngIf="addDialogForm.get('pan').hasError('required') && addDialogForm.get('pan').touched">
                            PAN is required.
                        </mat-error>
                        <mat-error
                            *ngIf="addDialogForm.get('pan').hasError('pattern') && addDialogForm.get('pan').touched">
                            Enter valid PAN number
                        </mat-error>
                    </div>
                </div>
            </mat-expansion-panel>

            <!-- <div class="row mt-2 toggle-text">
                <mat-slide-toggle>API enabled Vendor</mat-slide-toggle>
            </div> -->
        </form>
        <div class="row mt-3 mb-2">
            <div class="col d-flex justify-content-center">
                <a class="btn btn-submit me-2" (click)="onSubmit()">Submit</a>
                <a (click)="closeDialog()" class="btn btn-cancel">Cancel</a>
            </div>
        </div>
    </div>
</div>

<!-- CUSTOMER -->
<div class="container-fluid scroll" *ngIf="data['component']==='customer'">
    <div class="main-content mt-2">
        <div class="row">
            <div class="col">
                <p mat-dialog-title class="title">Add Customer
                </p>
                <p class="sub-heading mr-3">Adding customers to the list</p>
            </div>
            <div class="col-auto"> <span class="close-icon d-flex justify-content-end"><img
                        src="../../../../assets/img/icons/close-icon.svg" (click)="closeDialog()"></span></div>
        </div>
        <form [formGroup]="addDialogForm">
            <div *ngIf="loader">
                <div class=" loader">
                    <mat-spinner [diameter]="25" [strokeWidth]="2" class="centre-align"></mat-spinner>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label for="custName" class="required">Customer Name</label><br>
                    <input type="text" formControlName="custName" class="form-control w-100" required>
                    <mat-error
                        *ngIf="addDialogForm.get('custName').hasError('required')  && addDialogForm.get('custName').touched">
                        Customer name is required.
                    </mat-error>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-sm-6">
                    <label for="emailId" class="required">Email Id</label><br>
                    <input type="email" class="form-control" formControlName="email" placeholder="info@gati.com">
                    <mat-error
                        *ngIf="addDialogForm.get('email').hasError('required') && addDialogForm.get('email').touched">
                        Email is required.
                    </mat-error>
                    <mat-error
                        *ngIf="addDialogForm.get('email').hasError('pattern') && addDialogForm.get('email').touched">
                        Valid email is required.
                    </mat-error>
                </div>
                <div class="col">
                    <label for="groupId">Group ID</label>
                    <input type="text" class="form-control" formControlName="groupId">

                </div>
            </div>
            <div class="row mt-2">
                <div class="col-sm-6">
                    <label for="activeStatus" class="required">Active Status</label>
                    <mat-select class="form-control" formControlName="activeStatus" placeholder="Active">
                        <mat-option value="1" class="custom-mat-option">Active</mat-option>
                        <mat-option value="0" class="custom-mat-option">Inactive</mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="addDialogForm.get('activeStatus').hasError('required') && addDialogForm.get('activeStatus').touched">
                        Status is required.
                    </mat-error>
                </div>
            </div>

            <!-- <p class="sub-heading mr-3">Branch details</p> -->
            <mat-expansion-panel hideToggle class="mt-2">
                <mat-expansion-panel-header>
                    <mat-panel-title class="pt-2">
                        <span class="sub-title col d-flex justify-content-start">Branch details</span>
                        <span class="d-flex justify-content-end col">
                            <img src="../../../../assets/img/icons/dropdown.svg">
                        </span>
                    </mat-panel-title>
                    <!-- <mat-panel-description>
                    enter valid branch details
                  </mat-panel-description> -->
                </mat-expansion-panel-header>
                <div class="row mt-2">
                    <div class="col">
                        <label class="required">Branch Name</label>
                        <input class="form-control" type="text" formControlName="branchName">
                        <mat-error
                            *ngIf="addDialogForm.get('branchName').hasError('required')  && addDialogForm.get('branchName').touched">
                            Branch name is required.
                        </mat-error>
                    </div>
                    <div class="col">
                        <label for="phoneNo" class="required">Phone No.</label>
                        <input class="form-control no-spinner" type="number" formControlName="phoneNo">
                        <mat-error
                            *ngIf="addDialogForm.get('phoneNo').hasError('required') && addDialogForm.get('phoneNo').touched">
                            Phone number is required
                        </mat-error>
                        <mat-error
                            *ngIf="addDialogForm.get('phoneNo').hasError('pattern') && addDialogForm.get('phoneNo').touched">
                            Invalid phone number
                        </mat-error>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col">
                        <label for="address" class="required">Address</label><br>
                        <textarea class="form-control" formControlName="address" style="height: 107px;"></textarea>
                        <mat-error
                            *ngIf="addDialogForm.get('address').hasError('required') && addDialogForm.get('address').touched">
                            Address is required.
                        </mat-error>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col">
                        <label class="required">City</label>
                        <input class="form-control" type="text" formControlName="city">
                        <mat-error
                            *ngIf="addDialogForm.get('city').hasError('required') && addDialogForm.get('city').touched">
                            City is required.
                        </mat-error>
                    </div>

                    <div class="col">
                        <label class="required">State</label>
                        <mat-select class="form-control" formControlName="state" placeholder="Select state">
                            <mat-option *ngFor="let state of states" [value]="state.id">{{ state.stateName
                                }}</mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="addDialogForm.get('state').hasError('required') && addDialogForm.get('state').touched">
                            State is required.
                        </mat-error>
                    </div>

                </div>

                <div class="row mt-2">
                    <div class="col">
                        <label for="pincode" class="required">Pincode</label><br>
                        <input class="form-control" formControlName="pincode" placeholder="620013">
                        <mat-error
                            *ngIf="addDialogForm.get('pincode').hasError('required') && addDialogForm.get('pincode').touched">
                            Pincode is required.
                        </mat-error>
                        <mat-error
                            *ngIf="addDialogForm.get('pincode').hasError('pattern') && addDialogForm.get('pincode').touched">
                            Enter valid pincode
                        </mat-error>
                    </div>
                    <div class="col">
                        <label>ERP ID</label>
                        <input class="form-control" type="text" formControlName="erpId">

                    </div>
                </div>
                <div class="row mt-2">

                    <div class="col">
                        <label class="required">Address Code</label>
                        <input class="form-control no-spinner" type="number" formControlName="addressCode">
                        <mat-error
                            *ngIf="addDialogForm.get('addressCode').hasError('required') && addDialogForm.get('addressCode').touched">
                            Address code is required.
                        </mat-error>
                    </div>
                    <div class="col">
                        <label for="gstNo" class="required">GST </label><br>
                        <input class="form-control" formControlName="gstNo" placeholder="33AAAA0000A1Z3">
                        <mat-error
                            *ngIf="addDialogForm.get('gstNo').hasError('required') && addDialogForm.get('gstNo').touched">
                            GST is required.
                        </mat-error>
                        <mat-error
                            *ngIf="addDialogForm.get('gstNo').hasError('pattern') && addDialogForm.get('gstNo').touched">
                            Enter valid GST number
                        </mat-error>
                    </div>
                </div>

                <div class="row mt-2">

                    <div class="col-md-6">
                        <label for="panNo" class="required">PAN </label><br>
                        <input class="form-control" formControlName="panNo" placeholder="BWZPS3385L">
                        <mat-error
                            *ngIf="addDialogForm.get('panNo').hasError('required') && addDialogForm.get('panNo').touched">
                            PAN is required.
                        </mat-error>
                        <mat-error
                            *ngIf="addDialogForm.get('panNo').hasError('pattern') && addDialogForm.get('panNo').touched">
                            Enter valid PAN number
                        </mat-error>
                    </div>
                </div>
            </mat-expansion-panel>

            <!-- <div class="row mt-2 toggle-text">
                <mat-slide-toggle>API enabled Vendor</mat-slide-toggle>
            </div> -->
        </form>
        <div class="row mt-3 mb-2">
            <div class="col d-flex justify-content-center">
                <a class="btn btn-submit me-2" (click)="onSubmit()">Submit</a>
                <a (click)="closeDialog()" class="btn btn-cancel">Cancel</a>
            </div>
        </div>
    </div>
</div>

<!-- Vendor Branch -->
<div class="container-fluid scroll" *ngIf="data['component']==='vendorBranch'">
    <div class="main-content mt-2">
        <div class="row">
            <div class="col">
                <p mat-dialog-title class="title">Add Vendors Branch
                </p>
                <p class="sub-heading mr-3">Adding vendor branch to the list</p>
            </div>
            <div class="col-auto"> <span class="close-icon d-flex justify-content-end"><img
                        src="../../../../assets/img/icons/close-icon.svg" (click)="closeDialog()"></span></div>
        </div>
        <form [formGroup]="addDialogForm">
            <div *ngIf="loader">
                <div class=" loader">
                    <mat-spinner [diameter]="25" [strokeWidth]="2" class="centre-align"></mat-spinner>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <label class="required">Branch Name</label>
                    <input class="form-control" type="text" formControlName="branchName">
                    <mat-error
                        *ngIf="addDialogForm.get('branchName').hasError('required')  && addDialogForm.get('branchName').touched">
                        Branch name is required.
                    </mat-error>
                </div>
                <div class="col">
                    <label for="phoneNo" class="required">Phone No.</label>
                    <input class="form-control no-spinner" type="number" formControlName="phoneNo">
                    <mat-error
                        *ngIf="addDialogForm.get('phoneNo').hasError('required') && addDialogForm.get('phoneNo').touched">
                        Phone number is required
                    </mat-error>
                    <mat-error
                        *ngIf="addDialogForm.get('phoneNo').hasError('pattern') && addDialogForm.get('phoneNo').touched">
                        Invalid phone number
                    </mat-error>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <label for="address" class="required">Address</label><br>
                    <textarea class="form-control" formControlName="address" style="height: 107px;"></textarea>
                    <mat-error
                        *ngIf="addDialogForm.get('address').hasError('required') && addDialogForm.get('address').touched">
                        Address is required.
                    </mat-error>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <label class="required">City</label>
                    <input class="form-control" type="text" formControlName="city">
                    <mat-error
                        *ngIf="addDialogForm.get('city').hasError('required') && addDialogForm.get('city').touched">
                        City is required.
                    </mat-error>
                </div>

                <div class="col">
                    <label class="required">State</label>
                    <mat-select class="form-control" formControlName="stateId" placeholder="Select state">
                        <mat-option *ngFor="let state of states" [value]="state.id">{{ state.stateName
                            }}</mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="addDialogForm.get('stateId').hasError('required') && addDialogForm.get('stateId').touched">
                        State is required.
                    </mat-error>
                </div>

            </div>

            <div class="row mt-2">
                <div class="col">
                    <label for="pincode" class="required">Pincode</label><br>
                    <input class="form-control" formControlName="pincode" placeholder="620013">
                    <mat-error
                        *ngIf="addDialogForm.get('pincode').hasError('required') && addDialogForm.get('pincode').touched">
                        Pincode is required.
                    </mat-error>
                    <mat-error
                        *ngIf="addDialogForm.get('pincode').hasError('pattern') && addDialogForm.get('pincode').touched">
                        Enter valid pincode
                    </mat-error>
                </div>
                <div class="col">
                    <label class="required">Branch Code</label>
                    <input class="form-control" type="text" formControlName="branchCode">
                    <mat-error
                        *ngIf="addDialogForm.get('branchCode').hasError('required') && addDialogForm.get('branchCode').touched">
                        Address code is required.
                    </mat-error>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col">
                    <label for="gstNo" class="required">GST </label><br>
                    <input class="form-control" formControlName="gst" placeholder="33AAAA0000A1Z3">
                    <mat-error
                        *ngIf="addDialogForm.get('gst').hasError('required') && addDialogForm.get('gst').touched">
                        GST is required.
                    </mat-error>
                    <mat-error *ngIf="addDialogForm.get('gst').hasError('pattern') && addDialogForm.get('gst').touched">
                        Enter valid GST number
                    </mat-error>
                </div>
                <div class="col">
                    <label for="pan" class="required">PAN </label><br>
                    <input class="form-control" formControlName="pan" placeholder="BWZPS3385L">
                    <mat-error
                        *ngIf="addDialogForm.get('pan').hasError('required') && addDialogForm.get('pan').touched">
                        PAN is required.
                    </mat-error>
                    <mat-error *ngIf="addDialogForm.get('pan').hasError('pattern') && addDialogForm.get('pan').touched">
                        Enter valid PAN number
                    </mat-error>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <label for="Status" class="required">Active Status</label>
                    <mat-select class="form-control" formControlName="status" placeholder="Active">
                        <mat-option value="1" class="custom-mat-option">Active</mat-option>
                        <mat-option value="0" class="custom-mat-option">Inactive</mat-option>
                    </mat-select>
                </div>
            </div>

            <!-- <div class="row mt-3 mb-2">
                    <div class="col d-flex justify-content-center">
                        <a class="btn btn-add-more me-2" (click)="addBranch()">Add More</a>
                    </div>
                </div> -->
        </form>
        <div class="row mt-3 mb-2">
            <div class="col d-flex justify-content-center">
                <a class="btn btn-submit me-2" (click)="onSubmit()">Submit</a>
                <a (click)="closeDialog()" class="btn btn-cancel">Cancel</a>
            </div>
        </div>
    </div>
</div>

<!-- CUSTOMER-BRANCH -->
<div class="container-fluid scroll" *ngIf="data['component']==='customerBranch'">
    <div class="main-content mt-2">
        <div class="row">
            <div class="col">
                <p mat-dialog-title class="title">Add Customer Branch
                </p>
                <p class="sub-heading mr-3">Adding customer branch to the list</p>
            </div>
            <div class="col-auto"> <span class="close-icon d-flex justify-content-end"><img
                        src="../../../../assets/img/icons/close-icon.svg" (click)="closeDialog()"></span></div>
        </div>
        <form [formGroup]="addDialogForm">
            <div *ngIf="loader">
                <div class=" loader">
                    <mat-spinner [diameter]="25" [strokeWidth]="2" class="centre-align"></mat-spinner>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <label class="required">Branch Name</label>
                    <input class="form-control" type="text" formControlName="branchName">
                    <mat-error
                        *ngIf="addDialogForm.get('branchName').hasError('required')  && addDialogForm.get('branchName').touched">
                        Branch name is required.
                    </mat-error>
                </div>
                <div class="col">
                    <label for="phoneNo" class="required">Phone No.</label>
                    <input class="form-control no-spinner" type="number" formControlName="phoneNo">
                    <mat-error
                        *ngIf="addDialogForm.get('phoneNo').hasError('required') && addDialogForm.get('phoneNo').touched">
                        Phone number is required
                    </mat-error>
                    <mat-error
                        *ngIf="addDialogForm.get('phoneNo').hasError('pattern') && addDialogForm.get('phoneNo').touched">
                        Invalid phone number
                    </mat-error>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <label for="address" class="required">Address</label><br>
                    <textarea class="form-control" formControlName="address" style="height: 107px;"></textarea>
                    <mat-error
                        *ngIf="addDialogForm.get('address').hasError('required') && addDialogForm.get('address').touched">
                        Address is required.
                    </mat-error>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <label class="required">City</label>
                    <input class="form-control" type="text" formControlName="city">
                    <mat-error
                        *ngIf="addDialogForm.get('city').hasError('required') && addDialogForm.get('city').touched">
                        City is required.
                    </mat-error>
                </div>

                <div class="col">
                    <label for="pincode" class="required">Pincode</label><br>
                    <input class="form-control" formControlName="pincode" placeholder="620013">
                    <mat-error
                        *ngIf="addDialogForm.get('pincode').hasError('required') && addDialogForm.get('pincode').touched">
                        Pincode is required.
                    </mat-error>
                    <mat-error
                        *ngIf="addDialogForm.get('pincode').hasError('pattern') && addDialogForm.get('pincode').touched">
                        Enter valid pincode
                    </mat-error>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col">
                    <label class="required">State</label>
                    <mat-select class="form-control" formControlName="stateId" placeholder="Select state">
                        <mat-option *ngFor="let state of states" [value]="state.id">{{ state.stateName
                            }}</mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="addDialogForm.get('stateId').hasError('required') && addDialogForm.get('stateId').touched">
                        State is required.
                    </mat-error>
                </div>

                <div class="col">
                    <label class="required">Branch Code</label>
                    <input class="form-control" type="text" formControlName="branchCode">
                    <mat-error
                        *ngIf="addDialogForm.get('branchCode').hasError('required') && addDialogForm.get('branchCode').touched">
                        Branch code is required.
                    </mat-error>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col">
                    <label for="gstNo" class="required">GST </label><br>
                    <input class="form-control" formControlName="gst" placeholder="33AAAA0000A1Z3">
                    <mat-error
                        *ngIf="addDialogForm.get('gst').hasError('required') && addDialogForm.get('gst').touched">
                        GST is required.
                    </mat-error>
                    <mat-error *ngIf="addDialogForm.get('gst').hasError('pattern') && addDialogForm.get('gst').touched">
                        Enter valid GST number
                    </mat-error>
                </div>
                <div class="col">
                    <label for="panNo" class="required">PAN </label><br>
                    <input class="form-control" formControlName="pan" placeholder="BWZPS3385L">
                    <mat-error
                        *ngIf="addDialogForm.get('pan').hasError('required') && addDialogForm.get('pan').touched">
                        PAN is required.
                    </mat-error>
                    <mat-error *ngIf="addDialogForm.get('pan').hasError('pattern') && addDialogForm.get('pan').touched">
                        Enter valid PAN number
                    </mat-error>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <label for="Status" class="required">Active Status</label>
                    <mat-select class="form-control" formControlName="status" placeholder="Active">
                        <mat-option value="1" class="custom-mat-option">Active</mat-option>
                        <mat-option value="0" class="custom-mat-option">Inactive</mat-option>
                    </mat-select>
                </div>
            </div>
        </form>
        <div class="row mt-3 mb-2">
            <div class="col d-flex justify-content-center">
                <a class="btn btn-submit me-2" (click)="onSubmit()">Submit</a>
                <a (click)="closeDialog()" class="btn btn-cancel">Cancel</a>
            </div>
        </div>
    </div>
</div>