<div class="container-fluid scroll" *ngIf="data['component']==='vendorBranch'">
    <div class="main-content mt-2">

        <div class="row">
            <div class="col">
                <p mat-dialog-title class="title">Edit Vendor Branch
                </p>
                <p class="sub-heading ">editing vendor branch to the list</p>
            </div>
            <div class="col-auto"> <span class="close-icon d-flex justify-content-end"><img
                        src="../../../../assets/img/icons/close-icon.svg" (click)="closeDialog()"></span></div>
        </div>

            <form [formGroup]="editDialogForm">
                <div *ngIf="loader">
                    <div class=" loader">
                        <mat-spinner [diameter]="25" [strokeWidth]="2" class="centre-align"></mat-spinner>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="required">Branch Name</label><br>
                        <input type="text" formControlName="vendorBranchName" class="form-control w-100 disabled" readonly>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col">
                        <label class="required">Address</label><br>
                        <textarea class="form-control" formControlName="vendorBranchAddress"></textarea>
                    </div>

                    <div class="col">
                        <label class="required">City</label><br>
                        <input type="text" class="form-control" formControlName="city">
                    </div>

                </div>

                <div class="row">
                    <div class="col">
                        <label class="required">State</label>
                        <mat-select class="form-control" formControlName="stateId">
                            <mat-option *ngFor="let state of states" [value]="state.id">{{ state.stateName
                                }}</mat-option>
                        </mat-select>
                       
                    </div>
                    <div class="col">
                        <label class="required">Mobile No.</label>
                        <input type="number" formControlName="mobileNo" class="form-control">
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <label class="required">Pincode</label><br>
                        <input type="number" formControlName="pincode" class="form-control">
                        <!-- <mat-error
                        *ngIf="editDialogForm.get('pincode').hasError('required') && editDialogForm.get('pincode').touched">
                        pincode is required.
                    </mat-error> -->
                    </div>

                    <div class="col">
                        <label class="required">Branch Code</label><br>
                        <input type="text" formControlName="cwCode" class="form-control">
                        <!-- <mat-error
                        *ngIf="editDialogForm.get('branchCode').hasError('required') && editDialogForm.get('branchCode').touched">
                        pincode is required.
                    </mat-error> -->
                    </div>

                </div>

                <div class="row">
                    <div class="col">
                        <label class="required">PAN No.</label>
                        <input type="text" formControlName="panNo" class="form-control">
                    </div>
                    <div class="col">
                        <label class="required">GST No.</label><br>
                        <input type="text" formControlName="gstNo" class="form-control">
                        <!-- <mat-error
                        *ngIf="editDialogForm.get('gstNo').hasError('required')  && editDialogForm.get('gstNo').touched">
                        GST number is required.
                    </mat-error> -->
                        <!-- <mat-error
                        *ngIf="editDialogForm.get('gstNo').hasError('pattern') && editDialogForm.get('gstNo').touched">
                        enter valid GST number
                    </mat-error> -->
                    </div>

                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="Status" class="required">Active Status</label>
                        <mat-select class="form-control" formControlName="status">
                            <mat-option [value]="1" class="custom-mat-option">Active</mat-option>
                            <mat-option [value]="0" class="custom-mat-option">Inactive</mat-option>
                        </mat-select>
                    </div>
                </div>
            </form>
        <div class="row mt-3 mb-2">
            <div class="col d-flex justify-content-start">
                <a class="btn btn-submit" (click)="onSave()">Save</a>
            </div>
        </div>
    </div>
</div>

<!-- CUSTOMER-BRANCH -->
<div class="container-fluid scroll" *ngIf="data['component']==='customerBranch'">
    <div class="main-content mt-2">
        <div class="row">
            <div class="col">
                <p mat-dialog-title class="title">Edit Customer Branch
                </p>
                <p class="sub-heading ">editing customer branch to the list</p>
            </div>
            <div class="col-auto"> <span class="close-icon d-flex justify-content-end"><img
                        src="../../../../assets/img/icons/close-icon.svg" (click)="closeDialog()"></span></div>
        </div>
        <form [formGroup]="editDialogForm">
            <div *ngIf="loader">
                <div class=" loader">
                    <mat-spinner [diameter]="25" [strokeWidth]="2" class="centre-align"></mat-spinner>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="required">Branch Name</label><br>
                    <input readonly type="text" formControlName="customerBranchName" class="form-control w-100 disabled">
                </div>
            </div>

            <div class="row mt-2">
                <div class="col">
                    <label class="required">Address</label><br>
                    <textarea class="form-control" formControlName="customerBranchAddress"></textarea>
                </div>

                <div class="col">
                    <label class="required">City</label><br>
                    <input type="text" class="form-control" formControlName="city">
                </div>

            </div>

            <div class="row">
                <div class="col">
                    <label class="required">State</label>
                    <mat-select class="form-control" formControlName="stateName">
                      <mat-option *ngFor="let state of states" [value]="state.id">
                        {{ state.stateName }}
                      </mat-option>
                    </mat-select>
                  </div>
                  
                <div class="col">
                    <label class="required">Mobile No.</label>
                    <input type="number" formControlName="mobileNo" class="form-control">
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <label class="required">Pincode</label><br>
                    <input type="number" formControlName="pincode" class="form-control">
                    <!-- <mat-error
                        *ngIf="editDialogForm.get('pincode').hasError('required') && editDialogForm.get('pincode').touched">
                        pincode is required.
                    </mat-error> -->
                </div>

                <div class="col">
                    <label class="required">Branch Code</label><br>
                    <input type="text" formControlName="cwCode" class="form-control">
                    <!-- <mat-error
                        *ngIf="editDialogForm.get('branchCode').hasError('required') && editDialogForm.get('branchCode').touched">
                        pincode is required.
                    </mat-error> -->
                </div>

            </div>

            <div class="row">
                <div class="col">
                    <label class="required">PAN No.</label>
                    <input type="text" formControlName="panNo" class="form-control">
                </div>
                <div class="col">
                    <label class="required">GST No.</label><br>
                    <input type="text" formControlName="gstNo" class="form-control">
                    <!-- <mat-error
                        *ngIf="editDialogForm.get('gstNo').hasError('required')  && editDialogForm.get('gstNo').touched">
                        GST number is required.
                    </mat-error> -->
                    <!-- <mat-error
                        *ngIf="editDialogForm.get('gstNo').hasError('pattern') && editDialogForm.get('gstNo').touched">
                        enter valid GST number
                    </mat-error> -->
                </div>

            </div>
            <div class="row">
                <div class="col-sm-6">
                    <label for="Status" class="required">Active Status</label>
                    <mat-select class="form-control" formControlName="status">
                        <mat-option [value]="1" class="custom-mat-option">Active</mat-option>
                        <mat-option [value]="0" class="custom-mat-option">Inactive</mat-option>
                    </mat-select>
                </div>
            </div>
        </form>
        <div class="row mt-3 mb-2">
            <div class="col d-flex justify-content-start">
                <a class="btn btn-submit" (click)="onSave()">Save</a>
            </div>
        </div>
    </div>
</div>