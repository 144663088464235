import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerMappingComponent } from './container/customer-mapping/customer-mapping.component';
import { UserGuard } from '../_guard/user.guard';
import { UploadCustomerMappingComponent } from './container/upload-customer-mapping/upload-customer-mapping.component';
import { DocketStatusComponent } from '../Track and Trace/docket-status/docket-status.component';
import { authGuard } from '../_guard/auth.guard';

const routes: Routes = [
  {
    path: 'customer-mapping',
    component: CustomerMappingComponent,
    canActivate: [UserGuard],
    data: { title: 'PTL - Customer Mapping' }
  },
  {
    path: 'customer-mapping/upload-customer-mapping',
    component: UploadCustomerMappingComponent,
    canActivate: [UserGuard],
    data: { title: 'PTL - Upload Customer Mapping' }
  },
  {
    path: 'docket-status',
    component: DocketStatusComponent,
    canActivate: [authGuard],
    data: { title: 'PTL - Docket Status' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JourneyManagementRoutingModule { 

}
