<div class="main-wrapper">
<ng-container>
    <app-header></app-header>  
</ng-container>
  
  <ng-container >
    <app-sidebar></app-sidebar>
    
  </ng-container>
  <router-outlet></router-outlet>
  <!-- <app-vendor></app-vendor> -->
</div>