import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const UserGuard: CanActivateFn = (route, state) => {
  let userRole: any;
  let _route = inject(Router)
  userRole = localStorage.getItem('role')
  if(userRole == 'ROLE_SUPERUSER'){
    return true
  }else{
    _route.navigate(['journey-management/docket-status']);
    return false;
  }
};