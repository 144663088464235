
<div class="page-wrapper">
	<div class="content">
		<div class="order-details">
			<div class="content p-0">
				<div class="page-header">
					<span [routerLink]="['/master/vendor']" class="arrow">
						<img src="../../../../assets/img/icons/left-arrow.svg" class="arrow-img" />
						<h4 >Vendor Branch</h4>
					</span>
				</div>

				<div class="card">
					<div class="card-body">
						<div class="table-top position-relative">
							<div class="search-set justify-content-between w-100">
								<div class="search-path page-header ">
									<a class="btn btn-added" (click)="addVendorBranchForm()">
										<svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
										Add Branch</a>
									<!-- <a class="btn btn-cancel ms-3">
										Add Tariff</a> -->

								</div>
								<!-- <div class="pagination search-path page-header d-flex justify-content-end">
                                    <button class="btn btn-added" (click)="downloadAllExcel()" matTooltip="export" [matTooltipPosition]="'above'">
                                       Export <img src="../../../../assets/img/dowload-excel.svg" class="ms-2">
									</button>
                                </div> -->
							</div>
						</div>

						<div class="table-responsive">
							<ul class="order-data">
								<li class="table-head">
                                    <span class="action-column d-flex justify-content-center">Action</span>
									<span >Branch Name</span>
									<span >Address</span>
									<span class="custom-field">City</span>
                                    <span class="custom-field">State</span>
                                    <span class="custom-field">Mobile No.</span>
                                    <span class="custom-field">Pincode</span>
                                    <span class="custom-field">PAN No.</span>
                                    <span class="custom-field">GST No.</span>
                                    <span class="custom-field">CW id</span>
									<span class="d-flex justify-content-center custom-field">Status</span>
								</li>

								<ng-container  *ngIf="loader" >
									<div class="container loader d-flex justify-content-center">
										<mat-spinner [diameter]="25" [strokeWidth]="2"
											class="centre-align"></mat-spinner>
									</div>
								</ng-container>
                            
								<ng-container *ngIf="!loader">
									<li *ngFor="let vendor of vendorBranchDetails; let i = index">
                                        <span class="action-column d-flex justify-content-center" matTooltip="edit" [matTooltipPosition]="'above'" (click)="editVendorBranchForm(vendor)">
                                            <img src="../../../../assets/img/icons/edit-5.svg" width="16px">
                                        </span>
										<span >{{vendor.vendorBranchName | camelcase}}</span>
										<span >{{vendor.vendorBranchAddress | camelcase}}</span>
										<span class="custom-field">{{vendor.city | camelcase}}</span>
										<span class="custom-field"> {{vendor?.stateMaster?.stateName}}</span>
                                        <span class="custom-field">{{vendor.mobileNo}}</span>
                                        <span class="custom-field">{{vendor.pincode}}</span>
                                        <span class="custom-field">{{vendor.panNo}}</span>
                                        <span class="custom-field">{{vendor.gstNo}}</span>
                                        <span class="custom-field">{{vendor.cwCode}}</span>
										<!-- <span class="d-flex justify-content-center custom-field">{{vendor.status?.toString() === '1' ? 'Active' : 'Inactive'}}</span> -->
										<span class="d-flex justify-content-center">
											<span class="badges bg-lightgreen" *ngIf="vendor?.status == 1">Active</span>
                                   			<span class="badges bg-lightred" *ngIf="vendor?.status == 0">Inactive</span>
										</span>
									</li>

									<ng-container *ngIf="vendorBranchDetails && vendorBranchDetails?.length == 0">
										<div class="no-order text-center pt-4 pb-4">
											<p class="margin-0">No vendor branch found</p>
											<!-- <a class="btn btn-primary" (click)="getVendor()">View all vendors</a> -->
										</div>
									</ng-container>
								</ng-container>
							</ul>

						</div>

					</div>
				</div>
				<!-- <div class="pagination search-set justify-content-end flex-end w-100">
					<mat-paginator #paginator class="demo-paginator" (page)="pageNavigations($event)" [length]="length"
						[pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
						[pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
						[pageIndex]="pageIndex" aria-label="Select page">
					</mat-paginator>
				</div> -->
			</div>
		</div>
	</div>
</div>
