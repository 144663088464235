import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileUploader } from 'ng2-file-upload';
import { SnackbarComponent } from 'src/app/shared/component/snackbar/snackbar.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import { FileUploadModule } from "ng2-file-upload";
import { Observable } from 'rxjs';
import * as feather from 'feather-icons';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { MasterService } from 'src/app/shared/services/master.service';



@Component({
  selector: 'app-upload-customer-mapping',
  templateUrl: './upload-customer-mapping.component.html',
  styleUrls: ['./upload-customer-mapping.component.css']
})
export class UploadCustomerMappingComponent {
  durationInSeconds = 3;
  selectedFile: any;
  uploadForm: FormGroup | any;
  uploader: FileUploader | any;
  hasBaseDropZoneOver: any;
  hasAnotherDropZoneOver: any;
  response: any;
  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  loader: boolean = false;
  fileName = 'Select File';

  constructor(private auth: AuthService, private http: HttpClient, private errorHandlingService: ErrorHandlingService, private cdr: ChangeDetectorRef,
    private snackbarService:SnackbarService, private masterService: MasterService) {
    this.uploadForm = new FormGroup({
      fileUpload: new FormControl('', [Validators.required])
    })

    // feather.replace();

  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }

  openSnackBar(message: string) {
    this.snackbarService.showSnackbar(message);
  }

  upload(): void {
    
    this.progress = 0;
    this.loader = true;
    if(this.uploadForm.valid){

      if (this.selectedFiles) {
        const file: File | null = this.selectedFiles.item(0);
  
        if (file) {
          this.currentFile = file;
  
          this.masterService.upload(this.currentFile).subscribe({
            next: (event: any) => {
              console.log(event)
              // if (event.type === HttpEventType.UploadProgress) {
              //   const isFileUpload = true;
              //   const snackbarMessage = isFileUpload ? 'File Uploaded Successfully' : 'error';
              //   this.openSnackBar(snackbarMessage);
                // this.progress = Math.round((100 * event.loaded) / event.total);
              // } else
               if (event instanceof HttpResponse) {
                this.loader = false;
                // const isFileUpload = true;
                const snackbarMessage = 'File Uploaded Successfully'
                this.openSnackBar(snackbarMessage);
                this.message = event.body.message;
                this.uploadForm.reset();
                // this.fileInfos = this.uploadService.getFiles();
              }
              this.cdr.detectChanges();
            },
            error: (err: any) => {
              console.log(err);
              this.loader = false;
              // this.progress = 0;
              // const isFileUpload = false;
              const snackbarMessage =  'File Upload Failed';
              this.openSnackBar(snackbarMessage);
              if (err.error && err.error.message) {
                this.message = err.error.message;
              } else {
                this.message = 'Could not upload the file!';
              }
              this.loader = false;
              this.currentFile = undefined;
            },
          });
        }
  
        this.selectedFiles = undefined;
      }
    }
    else {
      this.loader = false;
      this.markFormGroupTouched(this.uploadForm);
      const snackbarMessage = 'please upload file'
      this.openSnackBar(snackbarMessage);
    }
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  ngAfterViewInit() {
    feather.replace();
  }
}
