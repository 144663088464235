<div class="container-fluid">
    <div class="content">
        <div class="order-details">
            <div class="main-content">
                <div class="row">
                    <div class="col">
                        <p mat-dialog-title *ngIf="!editingMode" class="title">Create New User
                        </p>
                        <p *ngIf="editingMode" mat-dialog-title class="title">Edit User
                        </p>
                    </div>
                    <div class="col-auto"> <span class="close-icon d-flex justify-content-end"><img
                                src="../../../../assets/img/icons/close-icon.svg" (click)="closeDialog()"></span></div>
                </div>

                <!-- /product list -->
                <div class="card-body mt-2 scroll">
                    <form [formGroup]="createUserform" (ngSubmit)="onSubmit(createUserform)">

                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <label>First Name <span class="manitory">*</span></label>
                                    <input type="text" formControlName="firstName" class="form-control">
                                    <mat-error
                                        *ngIf="createUserform.get('firstName')?.hasError('required') && createUserform.get('firstName')?.touched">
                                        First name is required
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <label>Last Name</label>
                                    <input type="text" formControlName="lastName" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <label>User Name <span class="manitory">*</span></label>
                                    <input type="text" formControlName="user" class="form-control"
                                        (focusout)="checkUserExit()">
                                    <mat-error
                                        *ngIf="createUserform.get('user')?.hasError('required') && createUserform.get('user')?.touched">
                                        User name is required
                                    </mat-error>
                                    <mat-error *ngIf="checkUser">
                                        User already exit
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <label>Email <span class="manitory">*</span></label>
                                    <input type="text" formControlName="email" class="form-control">
                                    <mat-error
                                        *ngIf="createUserform.get('email')?.hasError('required') && createUserform.get('email')?.touched">
                                        Email is required
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <label>Phone <span class="manitory">*</span></label>
                                    <input class="form-control" type="number" formControlName="mobile">
                                    <mat-error
                                        *ngIf="createUserform.get('mobile')?.hasError('required') && createUserform.get('mobile')?.touched">
                                        Phone number is required
                                    </mat-error>
                                    <mat-error
                                    *ngIf="createUserform.get('mobile')?.hasError('pattern') && createUserform.get('mobile')?.touched">
                                    Invalid phone number
                                </mat-error>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <div class="form-group">
                                    <label>Role <span class="manitory">*</span></label>


                                    <mat-select class="form-control" placeholder="Select role" (selectionChange)="selectRole($event)"
                                        formControlName="userRole">
                                        <mat-option value="ROLE_SUPERUSER">Super user</mat-option>
                                        <mat-option value="ROLE_ADMIN">Admin</mat-option>
                                        <mat-option value="ROLE_USER">User</mat-option>
                                    </mat-select>

                                    <mat-error
                                        *ngIf="createUserform.get('userRole')?.hasError('required') && createUserform.get('userRole')?.touched">
                                        Role is required
                                    </mat-error>

                                    <mat-error *ngIf="createUserform.controls.userRole.value === ''">please select role to select customer</mat-error>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-12" *ngIf="!fieldHide">
                                <div class="form-group">
                                    <label>Status <span class="manitory">*</span></label>

                                    <!-- <mat-form-field class="custom-select-ui role"> -->
                                    <mat-select placeholder="Status" class="form-control" formControlName="status">
                                        <mat-option value="Active">Active</mat-option>
                                        <mat-option value="Inactive">Inactive</mat-option>
                                    </mat-select>
                                    <!-- </mat-form-field> -->
                                    <mat-error
                                        *ngIf="createUserform.get('status')?.hasError('required') && createUserform.get('status')?.touched">
                                        Status is required
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12" *ngIf="fieldHide">
                                <div class="form-group">
                                    <label>Password <span class="manitory">*</span></label>
                                    <div class="pass-group">
                                        <input type="password" class=" pass-input form-control"
                                            formControlName="password">
                                        <span class="fas toggle-password fa-eye-slash"></span>
                                    </div>
                                    <mat-error
                                        *ngIf="createUserform.get('password')?.hasError('required') && createUserform.get('password')?.touched">
                                        Password is required
                                    </mat-error>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-12" >
                                <div class="form-group" >
                                    <label>Select Customer<span class="manitory">*</span></label>
                                    <div [ngClass]="{disabled: createUserform.controls.userRole.value === '' || createUserform.controls.userRole.value === 'ROLE_SUPERUSER'} " >
                                        <mat-select  class="form-control" placeholder="Customer Name"  formControlName="selectedCustomer" (selectionChange)="Customer($event)">
                                          <mat-option *ngIf=" createUserform.controls.userRole.value === 'ROLE_SUPERUSER'" value="all">All</mat-option>
                                          <mat-option *ngFor="let customer of customerData"  [value]="customer.id">{{customer.customerName}}</mat-option>
                                        </mat-select>
                                    </div>
                                    <mat-error
                                    *ngIf="createUserform.get('selectedCustomer')?.hasError('required') && createUserform.get('selectedCustomer')?.touched">
                                    Customer is required
                                </mat-error>
                                </div>
                            </div>

                            <div class="row mt-3 mb-2">
                                <div class="col d-flex justify-content-center">
                                    <input *ngIf="!submittedloader" type="submit" value="Submit"
                                        class="btn btn-submit me-2" [disabled]="checkUser">
                                    <a class="btn btn-submit me-2" *ngIf="submittedloader">
                                        <div class="loader">
                                            <mat-spinner [diameter]="25" [strokeWidth]="2"
                                                class="centre-align"></mat-spinner>
                                        </div>
                                    </a>
                                    <!-- <a href="javascript:void(0);" class="btn btn-submit me-2">Submit</a> -->
                                    <a href="javascript:void(0);" class="btn btn-cancel"
                                        (click)="closeDialog()">Cancel</a>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>