import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from 'src/app/setting/change-password/change-password.component';
import { FormBuilder, FormGroup, Validators , AbstractControl} from '@angular/forms';

@Component({
  // standalone: true,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userInfo: any;
  firstName: any;
  lastName: any;
  role: any;
  passwordDoesNotMatch = false;
  changePasswordForm: FormGroup | any;
  constructor(private auth: AuthService, public dialog: MatDialog, private fb: FormBuilder,) {
   this.changePasswordForm = this.fb.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.firstName = localStorage.getItem('firstName');
    this.lastName = localStorage.getItem('lastName');
    this.role = localStorage.getItem('role');
  }
  toggleNav() {
    const toggleBtn: any = document.getElementById('toggle_btn');

    const body = document.body;
    if (body.classList.contains('mini-sidebar')) {
      body.classList.remove('mini-sidebar');
      // toggleBtn.classList.add('active');
      // localStorage.setItem(screenModeNightTokenState, 'night');
      setTimeout(() => {
        body.classList.remove('mini-sidebar');
        document.querySelector(".header-left")?.classList.add("active");
      }, 100);
    } else {
      body.classList.add('mini-sidebar');
      toggleBtn.classList.remove('active');
      // localStorage.removeItem(screenModeNightTokenState);
      setTimeout(() => {
        body.classList.add('mini-sidebar');
        document.querySelector(".header-left")?.classList.remove("active");
      }, 100);
    }
  }

  toggleNavMobile() {
    const toggleBtn: any = document.getElementById('mobile_btn');

    const body = document.body;
    // const wrapper = document.querySelector(".main-wrapper");

    if (document.querySelector(".slide-nav")) {
      document.querySelector(".main-wrapper")?.classList.remove("slide-nav");

      body.classList.remove('menu-opened');
      // toggleBtn.classList.add('active');
      // localStorage.setItem(screenModeNightTokenState, 'night');
      setTimeout(() => {
        body.classList.remove('mini-sidebar');
        document.querySelector(".header-left")?.classList.add("active");
      }, 100);
    } else {
      document.querySelector(".main-wrapper")?.classList.add("slide-nav");

      body.classList.add('menu-opened');
      toggleBtn.classList.remove('active');
      // localStorage.removeItem(screenModeNightTokenState);
      setTimeout(() => {
        body.classList.add('mini-sidebar');
        document.querySelector(".header-left")?.classList.remove("active");
      }, 100);
    }
  }
  
  changePassword(): void {
    let dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '500px',
      data: {
        "component": "changePassword",
        "changePasswordForm": this.changePasswordForm
      }
    })
  }


  logOut() {
    this.auth.logOut()
  }
}
