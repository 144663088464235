import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Date } from 'src/app/_pipe/date.pipe';
import { DatetimePipe } from 'src/app/_pipe/pipe/datetime.pipe';
import { PipeModule } from 'src/app/_pipe/pipe/pipe.module';
import { MasterService } from 'src/app/shared/services/master.service';
// import { PipeModule } from 'src/app/_pipe/pipe/pipe.module';
@Component({
  selector: 'app-tracking-popup',
  templateUrl: './tracking-popup.component.html',
  styleUrls: ['./tracking-popup.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackingPopupComponent {
  orderData: any;
  AllStatusList: any;
  // trackData: any
  loader: boolean = false;
  viewTracking: FormGroup | any;
  trackData:any = [];
  docket: any = [];
  constructor(@Inject(MAT_DIALOG_DATA) public docketDetails: any,
    private dialogRef: MatDialogRef<TrackingPopupComponent>, private router: Router,private auth: AuthService,private cdr: ChangeDetectorRef,
    private masterService: MasterService) { }


    list = [
      // {name: 'Creation Failed', value: '-1', icon:'Creation Failed'},
      // {name: 'Pending', value: '0', icon:'Pending'},
      // {name: 'Created', value: '1', icon:'Order created'},
      // {name: 'Assigned', value: '2', icon:'Assigned'},
      // {name: 'Picking', value: '3', icon:'Picking'},
      // {name: 'Picked', value: '4', icon:'Picked'},
      // {name: 'Ready-To-Pack', value: '5', icon:'Ready to Pack'},
      // {name: 'Packing', value: '6', icon:'Packing'},
      // {name: 'Packed', value: '7', icon:'Packed'},
      // {name: 'Preparing Shipment', value: '8', icon:'Preparing Shipment'},
      // {name: 'Ready-To-Ship', value: '9', icon:'Ready to ship'},
      // {name: 'Shipped', value: '10', icon:'Ready-To-Ship'},
      // {name: 'Picked Up & In-transit', value: '11', icon:'In-transit'},
      // {name: 'Out For Delivery', value: '12', icon:'Out for delivery'},
      // {name: 'Attempted', value: '14', icon:'Attempted'},
      // {name: 'Returned', value: '15', icon:'Return'},
      // {name: 'Exception', value: '16', icon:'Exception'},
      // {name: 'Cancellation Pending', value: '17', icon:'Cancellation Pending'},
      // {name: 'Cancelled', value: '18', icon:'Cancel'},
      // {name: 'Sorting', value: '19', icon:'sorting'},
      // {name: 'Ready-To-Sort', value: '20', icon:'Ready to Short'},
      // {name: 'Sorted', value: '21', icon:'Sorted'},
      // {name: 'Kitting', value: '22', icon:'Kitting'},
      // {name: 'Kitted', value: '23', icon:'Kitting'},
      // {name: 'Hold', value: '24', icon:'Hold'},
      // {name: 'Delivered', value: '50', icon:'Delivered'},
      // {name: 'Address Check Failed', value: '50', icon:'Address Check Failed'},
      // {name: 'Back Order', value: '50', icon:'Back Order'},
      // {name: 'Draft order', value: '50', icon:'draft order'},
      {name: 'Creation Failed', value: '-1', icon:'Creation Failed'},
      {name: 'Pending', value: '0', icon:'Pending'},
      {name: 'Docket Creation', value: '1', icon:'Order created'},
      {name: 'Assigned', value: '2', icon:'Assigned'},
      {name: 'Picking', value: '3', icon:'Picking'},
      {name: 'Picked', value: '4', icon:'Picked'},
      {name: 'Ready-To-Pack', value: '5', icon:'Ready to Pack'},
      {name: 'Packing', value: '6', icon:'Packing'},
      {name: 'Packed', value: '7', icon:'Packed'},
      {name: 'Preparing Shipment', value: '8', icon:'Preparing Shipment'},
      {name: 'Out From Origin', value: '9', icon:'Packed'},
      {name: 'Tc Acknowledgment At Enroute', value: '10', icon:'In-transit'},
      {name: 'Tc Sent From Enroute Ou', value: '11', icon:'In-transit'},
      {name: 'Out For Delivery', value: '12', icon:'Out for delivery'},
      {name: 'Arrived At Destination', value: '14', icon:'Ready-To-Ship'},
      {name: 'Returned', value: '15', icon:'Return'},
      {name: 'Exception', value: '16', icon:'Exception'},
      {name: 'Cancellation Pending', value: '17', icon:'Cancellation Pending'},
      {name: 'Cancelled', value: '18', icon:'Cancel'},
      {name: 'Sorting', value: '19', icon:'sorting'},
      {name: 'Ready-To-Sort', value: '20', icon:'Ready to Short'},
      {name: 'Sorted', value: '21', icon:'Sorted'},
      {name: 'Tc Acknowledgment At Enroute', value: '22', icon:'In-transit'},
      {name: 'Tc Sent From Enroute Ou', value: '23', icon:'In-transit'},
      {name: 'Picked Up & In-transit', value: '23', icon:'In-transit'},
      {name: 'Shipment has left the origin centre location', value: '23', icon:'In-transit'},
      {name: 'Shipment has been picked up from client location', value: '9', icon:'Packed'},
      {name: 'The complete shipment has been delivered successfully', value: '9', icon:'Delivered'},
      {name: 'Shipment is out for delivery', value: '9', icon:'Out for delivery'},
      {name: 'Shipment has reached the destination centre location', value: '9', icon:'Ready-To-Ship'},
      {name: 'Taken For Delivery', value: '24', icon:'Out for delivery'},
      {name: 'Delivered', value: '50', icon:'Delivered'},
      {name: 'Address Check Failed', value: '50', icon:'Address Check Failed'},
      {name: 'Back Order', value: '50', icon:'Back Order'},
      {name: 'Draft order', value: '50', icon:'draft order'},
      { name: 'Undelivered', value: '50', icon: 'Exception' },

      { name: 'Reverse Pickup Request is accepted', value: '50', icon: 'Back Order' },
      { name: 'Not Picked/Pickup Cancel', value: '50', icon: 'Exception' },
      { name: 'Reverse Pickup Shipment received from Shipper', value: '50', icon: 'Back Order' },
      // { name: 'COD Amount Remitted', value: '50', icon: 'Exception' },
      { name: 'Data Manifested', value: '50', icon: 'Order created' },
      { name: 'Shipment Damage', value: '50', icon: 'Back Order' },
      { name: 'Partial Shipment Delivered', value: '50', icon: 'draft order' },
      { name: 'Shipment Arrived at Delivery Location', value: '50', icon: 'Out for delivery' },
      // { name: 'Shipment marked for Re-booked', value: '50', icon: 'Exception' },
      { name: 'Shipment Short', value: '50', icon: 'Kitting' },
      { name: 'Shipment Reached at Booking OU', value: '50', icon: 'In-transit' },
      { name: 'Reverse Pickup Request Pending', value: '50', icon: 'Return' },
      { name: 'Reverse Pickup Customer requested for another date for pickup', value: '50', icon: 'Return' },
      { name: 'Shipment is not picked from the client location', value: '50', icon: 'Address Check Failed' },
      { name: 'Reverse Pickup Customer refuse to handover shipment', value: '50', icon: 'Exception' },
      { name: 'In-Transit', value: '23', icon: 'In-transit' },
      { name: 'Shipment has been returned and is in transit', value: '50', icon: 'In-transit' },
      { name: 'Arrived at Delivery Location', value: '50', icon: 'Attempted' },
      { name: 'Shipment arrived at Enroute HUB', value: '50', icon: 'Ready-To-Ship' },
      { name: 'Shipment Out from Booking Location', value: '50', icon: 'In-transit' },
      { name: 'Lost Shipment', value: '50', icon: 'Exception' },
      { name: 'The returned shipment is out for delivery', value: '50', icon: 'Out for delivery' },
      { name: 'The return shipment has been delivered', value: '50', icon: 'Delivered' },
      { name: 'Shipment delivery will be re-attempted', value: '50', icon: 'Attempted' },
      { name: 'Shipment Out from Booking Location', value: '50', icon: 'In-transit' },
  
    ]

    getIcon(commonStatusName: string): string {
      const status = this.list.find(item => item.name === commonStatusName);
      return status ? `assets/img/tracking/${status.icon}.svg` : ''; 
  }
  

    getDocket(){
      this.trackData = this.docketDetails.docket;
    }


    ngOnInit(): void {
     this.getDocket();
      
    }

    closeDialog() {
      this.dialogRef.close();
      // this.viewTracking.reset();
    }
}
