<div class="page-wrapper">
    <div class="content">
        <div class="order-details">
            <div class="content p-0">
                <div class="page-header">
                    <span [routerLink]="['/master/vendor']" class="arrow">
                        <img src="../../../../assets/img/icons/left-arrow.svg" class="arrow-img" />
                        <h4>Vendor Files</h4>
                    </span>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="table-top position-relative">
                            <div class="search-set justify-content-between w-100">
                                <div class="search-path page-header ">

                                    <!-- <a class="btn btn-added" >
										Search</a> -->

                                </div>
                                <!-- <div class="pagination search-path page-header d-flex justify-content-end">
                                    <button class="btn btn-added" (click)="downloadAllExcel()" matTooltip="export" [matTooltipPosition]="'above'">
                                       Export <img src="../../../../assets/img/dowload-excel.svg" class="ms-2">
									</button>
                                </div> -->
                            </div>
                        </div>

                        <div class="table-responsive">
                            <ul class="order-data">
                                <li class="table-head">
                                    <!-- <span class="selctorder">
										<ng-container *ngIf="vendorData?.result?.length > 0">
											<mat-checkbox [checked]="allChecked"
											(change)="toggleAllCheckboxes($event.checked)"></mat-checkbox>
										</ng-container>
									</span> -->
                                    <!-- <span >S.No.</span> -->
                                    <span class="action-column d-flex justify-content-center">Action</span>
                                    <span>Vendor Branch Name</span>
                                    <span>Customer Name</span>
                                    <span>Customer Branch Name</span>
                                    <span>File Name</span>
                                    <span class="action-column">File Type</span>
                                    <span class="action-column cursor">From Date <a (click)="toggleSortOrder()"><svg
                                                class="sort-icon" xmlns="http://www.w3.org/2000/svg" width="16"
                                                height="16" viewBox="0 0 16 16" id="sort">
                                                <path fill="#444" d="M11 7H5l3-4zM5 9h6l-3 4z"></path>
                                            </svg></a></span>
                                    <span class="action-column cursor">To Date <a (click)="toggleSortOrder()"><svg
                                                class="sort-icon" xmlns="http://www.w3.org/2000/svg" width="16"
                                                height="16" viewBox="0 0 16 16" id="sort">
                                                <path fill="#444" d="M11 7H5l3-4zM5 9h6l-3 4z"></path>
                                            </svg></a></span>
                                    <!-- <button (click)="sortByDate('asc')">From Date</button>
                                    <button (click)="sortByDate('desc')">To Date</button> -->
                                    <!-- <span class="report-mail">Report email</span>
									<span class="d-flex justify-content-center">Status</span> -->
                                </li>

                                <ng-container *ngIf="loader">
                                    <div class="container loader d-flex justify-content-center">
                                        <mat-spinner [diameter]="25" [strokeWidth]="2"
                                            class="centre-align"></mat-spinner>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="!loader" class="scroll">
                                    <li *ngFor="let vendorData of vendorFile" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }">
                                        <span class="action-column d-flex justify-content-center">
                                            <span class="download-icon ms-3" matTooltip="Download">
                                                <a download
                                                    [href]="'https://dev.apollosupplychain.com/' + replaceURL(vendorData.file)">
                                                    <img src="../../../../assets/img/icons/download.svg" width="16px"
                                                        alt="Download">
                                                </a>
                                            </span>
                                        </span>
                                        <!-- Vendor-branch -->
                                        <span>
                                            <span>
                                                <ng-container
                                                    *ngIf="vendorData.vendorName.length > 2; else fewerVendors">
                                                    <span
                                                        *ngFor="let item of vendorData.vendorName.slice(0, 2); let i = index">
                                                        {{ item }}<ng-container *ngIf="i < 1">, </ng-container>
                                                    </span>
                                                    <span class="more-record"
                                                        matTooltip="{{ vendorData.vendorName.join(', ') }}">
                                                        more
                                                    </span>
                                                </ng-container>
                                                <ng-template #fewerVendors>
                                                    <span *ngFor="let item of vendorData.vendorName; let i = index">
                                                        {{ item }}<ng-container
                                                            *ngIf="i < vendorData.vendorName.length - 1">,
                                                        </ng-container>
                                                    </span>
                                                </ng-template>
                                            </span>

                                        </span>
                                        <!-- Customer -->
                                        <!-- {{vendorFile|json}} -->
                                        <span>
                                            <!-- <span *ngFor="let vendor of vendorFile" > -->
                                                {{ vendorData.customerName }}
                                            <!-- </span> -->
                                        </span>
                                        <!-- Customer-branch -->
                                        <span>  
                                            <span>
                                                <ng-container
                                                    *ngIf="vendorData.customerBranchName.length > 2; else fewerBranches">
                                                    <span
                                                        *ngFor="let item of vendorData.customerBranchName.slice(0, 2); let i = index">
                                                        {{ item }}<ng-container *ngIf="i < 1">, </ng-container>
                                                    </span>
                                                    <span class="more-record"
                                                        matTooltip="{{ vendorData.customerBranchName.join(', ') }}">
                                                        more
                                                    </span>
                                                </ng-container>
                                                <ng-template #fewerBranches>
                                                    <span
                                                        *ngFor="let item of vendorData.customerBranchName; let i = index">
                                                        {{ item }}<ng-container
                                                            *ngIf="i < vendorData.customerBranchName.length - 1">,
                                                        </ng-container>
                                                    </span>
                                                </ng-template>
                                            </span>
                                        </span>
                                        <span [innerHTML]="replaceFileName(vendorData.file)"></span>

                                        <span class="action-column">{{vendorData.fileType | camelcase}}</span>
                                        <span class="action-column">{{vendorData.fromDate | date:'dd/MM/yy'}}</span>
                                        <span class="action-column">{{vendorData.toDate | date:'dd/MM/yy'}}</span>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="vendorFile && vendorFile?.length == 0">
                                    <div class="no-order text-center pt-4 pb-4">
                                        <p class="margin-0">No vendor files found</p>
                                        <a class="btn btn-primary">View
                                            all dockets</a>
                                    </div>
                                </ng-container>
                            </ul>

                        </div>

                    </div>
                </div>
                <div class="pagination search-set justify-content-end flex-end w-100">
                    <mat-paginator #paginator class="demo-paginator" (page)="pageNavigations($event)"
                        [length]="totalRecords" [pageSize]="pageSize"
                        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
                        [pageIndex]="pageIndex" [showFirstLastButtons]="showFirstLastButtons" aria-label="Select page">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>