<div class="main-wrapper">

    <div class="page-wrapper">
        <div class="content">
            <div class="page-header">
                <div class="page-title">
                    <span [routerLink]="['/master/vendor']" class="arrow">
                        <img src="../../../../assets/img/icons/left-arrow.svg" class="arrow-img" />
                        <h4>Upload Vendor Files</h4>
                    </span>
                </div>
            </div>

            <!-- /product list -->
            <div class="card">
                <div class="card-body">
                    <div>
                        <p class="title">Vendor: {{vendorName}}</p>
                    </div>
                    <form [formGroup]="uploadForm" enctype="multipart/form-data">
                        <div class="row w-50">
                            <div class="col-sm-6">
                                <label class="required">File Type</label>
                                <mat-select class="form-control mt-1" formControlName="fileType">
                                    <mat-option class="custom-mat-option" value="Contracts">Contracts</mat-option>
                                    <mat-option class="custom-mat-option" value="Extension">Extension</mat-option>
                                    <mat-option class="custom-mat-option" value="NDAs">NDAs</mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="uploadForm.get('fileType').hasError('required')  && uploadForm.get('fileType').touched">
                                    File type is required.
                                </mat-error>
                            </div>
                            <div class="col-sm-6">
                                <label class="required">Vendor Branch</label>
                                <!-- <mat-select class="form-control mt-1" formControlName="vendorBranch" [(ngModel)]="selectedVendorBranch">
                                    <mat-option class="custom-mat-option" *ngFor="let branch of vendorBranchDetails"
                                        [value]="branch.id" >
                                        {{ branch.branchName }}</mat-option>
                                </mat-select> -->
                                <mat-select class="form-control mt-1" formControlName="vendorBranch"
                                    [(ngModel)]="selectedVendorBranch" multiple
                                    (selectionChange)="onVendorBranchSelectionChange()">
                                    <mat-option class="custom-mat-option" [value]="0">All</mat-option>
                                    <mat-option class="custom-mat-option" *ngFor="let branch of vendorBranchDetails"
                                        [value]="branch.id">
                                        {{ branch.vendorBranchName }}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="uploadForm.get('vendorBranch').hasError('required')  && uploadForm.get('vendorBranch').touched">
                                    Vendor branch is required.
                                </mat-error>
                            </div>
                        </div>


                        <div class="row w-50 mt-3">
                            <div class="col-md-6">
                                <label class="required">Customer</label>
                                <mat-select class="form-control mt-1" formControlName="customer"
                                    [(ngModel)]="selectedCustomer" (ngModelChange)="onCustomerChange($event)">
                                    <mat-option class="custom-mat-option" [value]="0">All</mat-option>
                                    <mat-option *ngFor="let customer of customerData" [value]="customer.id">
                                        {{customer.customerName }}</mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="uploadForm.get('customer').hasError('required')  && uploadForm.get('customer').touched">
                                    Customer is required.
                                </mat-error>
                            </div>

                            <div class="col-md-6">
                                <label class="required">Customer Branch</label>
                                <mat-select class="form-control mt-1" formControlName="customerBranch"
                                    [(ngModel)]="selectedCustomerBranch" [multiple]=" true"
                                    (selectionChange)="onCustomerBranchSelectionChange()">
                                    <mat-option class="custom-mat-option" [value]="0">All</mat-option>
                                    <mat-option *ngFor="let branch of customerBranches" [value]="branch.id">
                                        {{branch.customerBranchName }}</mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="uploadForm.get('customerBranch').hasError('required')  && uploadForm.get('customerBranch').touched">
                                    Customer branch is required.
                                </mat-error>
                            </div>
                        </div>


                        <div class="row w-50 mt-3">
                            <div class="col-md-6">
                                <label class="required">Date</label><br>
                                <span class="date-width mt-1">
                                    <mat-form-field class="custom-select-ui date-range">
                                        <mat-select (selectionChange)="onChangeCondition($event.value)"
                                            placeholder="Select date" panelClass="filter-option order"
                                          formControlName="dateRange" >
                                            <ng-container *ngFor="let dateOption of dateOptionsList">
                                                <mat-option [value]="dateOption.value">
                                                    {{ dateOption.viewValue }}
                                                </mat-option>
                                            </ng-container>
                                            <mat-option value="custom" class="custom" disabled>
                                                Custom
                                                <div class="custom-date-rang">
                                                    <custom-date-range
                                                        (emitDates)="handleCustomDateRange($event.startDate, $event.endDate)"></custom-date-range>
                                                </div>
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-error
                                    *ngIf="uploadForm.get('dateRange').hasError('required')  && uploadForm.get('dateRange').touched">
                                    Date is required.
                                </mat-error>
                                    <!-- <mat-error
                                        *ngIf="uploadForm.get('dateRange').hasError('required')  && uploadForm.get('dateRange').touched">
                                        Daterange is required.
                                    </mat-error> -->
                                </span>
                            </div>
                        </div>
                        <div class="row mt-3">

                            <div class="col-lg-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label for="file" class="required">
                                        Upload File
                                    </label>
                                    <div class="image-upload mt-1">
                                        <div class="image-uploads">
                                            <img src="../../../../assets/img/icons/upload.svg" alt="img">
                                            <h4>Drag and drop a file to upload</h4>
                                            <input #fileUpload type="file" formControlName="fileUpload"
                                                (change)="selectFile($event)" [multiple]="true">
                                            <mat-error
                                                *ngIf="uploadForm.get('fileUpload').hasError('required')  && uploadForm.get('fileUpload').touched">
                                                File upload is required.
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-group mb-0">
                                    <a href="javascript: void(0);" id="alert-success"
                                        class="btn btn-submit waves-effect waves-light me-2"
                                        (click)="upload()">Submit</a>
                                    <a href="javascript:void(0);" class="btn btn-cancel"
                                        routerLink="/master/vendor">Cancel</a>
                                </div>
                            </div>
                            <ng-container *ngIf="loader">
                                <div>
                                    <div class="container loader d-flex justify-content-center">
                                        <mat-spinner [diameter]="25" [strokeWidth]="2"
                                            class="centre-align"></mat-spinner>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="loaders">
        <div class="container loaders ">
            <mat-spinner [diameter]="25" [strokeWidth]="2" class="centre-align"></mat-spinner>
        </div>
    </div>
</div>