import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { Configuration } from 'src/app/constants/constants/config';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  BASE_DOMAIN = environment.BASE_DOMAIN
  ACCESS_TOKEN = 'access_token';
  USER_DETAILS = 'user_details';

  constructor(private api: ApiService, private API_URL: Configuration, private http: HttpClient, private _route: Router
    , private cookieService: CookieService
  ) { }


  /**
 * @author Asim Pathan
 * @description sets user authentication token
 * @param {string} accessToken authentication token
 */
  // apiUrl = 'http://devbkptl.apollologisolutions.com/PTLServiceAPI/api/v1/login/validateUser';

  // validateUser(payload: any) {
  //   return this.http.post(this.apiUrl, payload);
  // }

  setAuth(accessToken: any) {
    console.log("token", accessToken)
    this.cookieService.set(this.ACCESS_TOKEN, accessToken, 3, '/', environment.COOKIE_DOMAIN, false, 'Lax');
  }
  userDetailsSet(userDetails: any) {
    console.log("userDetails", userDetails)
    this.cookieService.set(this.USER_DETAILS, JSON.stringify(userDetails), 3, '/', environment.COOKIE_DOMAIN, false, 'Lax');
  }

  removeAuthToken() {
    // Remove token from storage
    this.cookieService.delete(this.ACCESS_TOKEN);
  }

  /**
   * @author Anup Kumar
   * @description returns user authentication token
   * @return {string} null if token not present
   */
  getAuthToken() {
    if (this.cookieService.get(this.ACCESS_TOKEN) && this.cookieService.get(this.ACCESS_TOKEN) !== '') {
      return this.cookieService.get(this.ACCESS_TOKEN);
    } else {
      return null;
    }
  }

  getUserDetails() {
    if (this.cookieService.get(this.USER_DETAILS) && this.cookieService.get(this.USER_DETAILS) !== '') {
      return this.cookieService.get(this.USER_DETAILS);
    } else {
      return null;
    }
  }
  signUp(user: any) {
    return this.api.post(this.BASE_DOMAIN + `${this.API_URL.CONFIG_URL.SIGNUP}`, user);
  }
  getUser(getUser: any) {
    console.log("user", getUser)
    return this.api.post(this.BASE_DOMAIN + `${this.API_URL.CONFIG_URL.USER_LOGIN}`, getUser);
  }

  // PTL

  login(getUser: any) {
    const endpoint = `${environment.LOGIN_DOMAIN}auth-service/auth/login`;
    return this.api.post(endpoint, getUser)
  }

  getUserInfo() {
    if (this.cookieService.get(this.USER_DETAILS) && this.cookieService.get(this.USER_DETAILS) !== '') {
      return this.cookieService.get(this.USER_DETAILS);
    } else {
      return null;
    }
  }

  checkUser(user:any) {
    const url = `${environment.LOGIN_DOMAIN}${this.API_URL.CONFIG_URL.CHECK_USER}${user}`;
    return this.api.get(url);
  }
  
  createUser(form: any) {
    const url = `${environment.LOGIN_DOMAIN}${this.API_URL.CONFIG_URL.CREATE_USER}`;
    return this.api.post(url, form);
  }

  updateUser(form: any) {
    const url = `${environment.LOGIN_DOMAIN}${this.API_URL.CONFIG_URL.UPDATE_USER}`;
    return this.api.put(url, form);
  }
  
  changePassword(form:any){
    const url = `${environment.LOGIN_DOMAIN}${this.API_URL.CONFIG_URL.UPDATE_PASSWORD}`;
    return this.api.put(url, form);

  }

  getUserList(filter: any, pageNumber: number, pageSize: number) {
    const url = `${environment.LOGIN_DOMAIN}${this.API_URL.CONFIG_URL.USER_LIST}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.api.post(url, filter);
  }

  getUserDetail() {
    const endpoint = "getUserList";
    return this.api.getMethod(endpoint);
  }

  logOut() {
    this.cookieService.deleteAll('/', environment.COOKIE_DOMAIN);
    console.log('logout services')
    localStorage.clear()
    this._route.navigate([''])
  }
}
