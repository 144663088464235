import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelcase'
})
export class CamelcasePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return ''; // or whatever default behavior you want when value is undefined or null
    }
  
    // Split the string into words
    const words = value.split(' ');
  
    // Capitalize the first letter of each word and convert the rest to lowercase
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  
    // Join the words back together with a space
    return capitalizedWords.join(' ');
  }
  

}
