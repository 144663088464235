import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Configuration } from 'src/app/constants/constants/config';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Observable, filter } from 'rxjs';
import { toDate } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  /**
  * @author Asim Pathan
  * @description sets user authentication token
  * @param {string} accessToken authentication token
  */
  // BASE_DOMAIN = environment.BASE_DOMAIN;
  constructor(private api: ApiService, private API_URL: Configuration, private http: HttpClient, private _route: Router
    , private cookieService: CookieService) {

  }

  getVendorDetails(page: number, size: number, filter: any) {
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_VENDOR}?pageNumber=${page}&pageSize=${size}`;
    return this.http.post<any>(url, filter);
  }

  getCustomerDetails(page: number, size: number, filter: any) {
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_CUSTOMER}?pageNumber=${page}&pageSize=${size}`;
    return this.http.post<any>(url, filter);
  }



  addVendorDetails(formData: any) {
    const endpoint = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.ADD_VENDOR}`;
    const requestData = {
      vendorName: formData.vendorName,
      email: formData.email,
      reportsEmail: formData.reportsEmail,
      cwGroupCode: formData.groupId,
      status: formData.activeStatus,
      branchDetailsList: [
        {
          vendorBranchName: formData.branchName,
          vendorBranchAddress: formData.address,
          mobileNo: formData.phoneNo,
          stateMaster:{
            id: formData.state,
          },
          city: formData.city,
          pincode: formData.pincode,
          cwCode: formData.erpId,
          addressCode: formData.addressCode.toString() || "",
          panNo: formData.pan || "",
          gstNo: formData.gst || "",
          status: formData.activeStatus,
        }
      ]
    };
    return this.http.post(endpoint, requestData);
  }

  addCustomerDetails(formData: any) {
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.ADD_CUSTOMER}`;
    const requestData = {
      customerName: formData.custName,
      email: formData.email,
      cwGroupCode: formData.groupId,
      status: formData.activeStatus,

      branchDetailsList: [
        {
          customerBranchName: formData.branchName,
          customerBranchAddress: formData.address,
          mobileNo: formData.phoneNo,
          stateMaster:{
            id: formData.state,
          },
          city: formData.city,
          pincode: formData.pincode,
          cwCode: formData.erpId,
          addressCode: formData.addressCode || "",
          panNo: formData.panNo || "",
          gstNo: formData.gstNo || "",
          status: formData.activeStatus,
        }
      ]
    };
    return this.http.post(url, requestData);
  }

  addVendorBranchDetails(formData: any, vendorId: any) {
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.ADD_VENDOR_BRANCH}`;
    const requestData = {
      id: vendorId.toString(),
      branchDetailsList: [
        {
          vendorBranchName: formData.branchName,
          vendorBranchAddress: formData.address,
          mobileNo: formData.phoneNo,
          stateMaster: {
            id: formData.stateId,
          },
          city: formData.city,
          pincode: formData.pincode,
          cwCode: formData.branchCode,
          panNo: formData.pan || "",
          gstNo: formData.gst || "",
          status: formData.status
        }
      ]
    };
    return this.http.post(url, requestData);
  }

  addCustomerBranchDetails(formData: any, custId: any) {
    // debugger
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.ADD_CUSTOMER_BRANCH}`;
    // const endpoint = `${environment.BASE_DOMAIN}addCustomerBranches`;
    const requestData = {
      id: custId.toString(),
      branchDetailsList: [
        {
          customerBranchName: formData.branchName,
          customerBranchAddress: formData.address,
          mobileNo: formData.phoneNo,
          stateMaster:{
            id: formData.stateId,
          },
          city: formData.city,
          pincode: formData.pincode,
          // erpId: formData.erpId,
          cwCode: formData.branchCode,
          panNo: formData.pan || "",
          gstNo: formData.gst || "",
          status: formData.status
        }
      ]
    };
    return this.http.post(url, requestData);
  }

  getStateDetails() {
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_STATE}`;
    return this.http.get(url);
  }

  getDocketDetails(page: number, size: number, filter: any) {
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_DOCKET_STATUS_BY_NO}?pageNumber=${page}&pageSize=${size}`;
  
    const userId = localStorage.getItem('id');
    const userRole = localStorage.getItem('role');
    const userListString = localStorage.getItem('userlist');
  
    if (userListString) {
      const userList = JSON.parse(userListString);
      let customerId: number | null = null;
  
      // Find the matching user and extract clientId(s)
      const matchingUser = userList.find((user: any) => user.id.toString() === userId);
      if (matchingUser && matchingUser.clientList && matchingUser.clientList.length > 0) {
        // Here we assume you want the first clientId if there are multiple
        customerId = matchingUser.clientList[0].clientId;
      }
  
      if (userRole === 'ROLE_SUPERUSER') {
        filter = [{ key: "DOCKET_NO", value: filter }];
      } else if (userRole === 'ROLE_ADMIN' || userRole === 'ROLE_USER') {
        if (customerId !== null) {
          filter = [
            { key: "DOCKET_NO", value: filter },
            { key: "CUSTOMER_ID", "value": customerId }
          ];
        } else {
          console.error('No clientId found for the matching user');
        }
      }
    } else {
      console.error('No user list found in localStorage');
    }
  
    return this.http.post<any>(url, filter);
  }
  

  getDocketList(startDate: string, endDate: string, page: number, size: number, ids: any, docketNo: any): Observable<any> {
    // debugger
    let url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_DOCKET_STATUS_BY_NO}?pageNumber=${page}&pageSize=${size}`;
    let filter: any;
    filter = [
      { "key": "FROM_DATE", "dateValue": startDate },
      { "key": "TO_DATE", "dateValue": endDate },
    ]

    if (filter?.length > 0) {
      filter = [
        { "key": "FROM_DATE", "dateValue": startDate },
        { "key": "TO_DATE", "dateValue": endDate },
      ]

      if (ids && ids !== "") {
        filter = [{ "key": "CUSTOMER_ID_IN", value: ids },
        { "key": "FROM_DATE", "dateValue": startDate },
        { "key": "TO_DATE", "dateValue": endDate }
        ]
      }

      else if (docketNo?.length > 0) {
        // if (typeof docketNo === 'string' && docketNo.includes(',')) {
        //   filter = [{ "key": "DOCKET_NO_IN", "value": docketNo }];
        // } else {
        filter = [{ "key": "DOCKET_NO_IN", "value": docketNo }];
        // }
        // }
      }
    }
    return this.http.post<any>(url, filter);
  }

  updateCustomer(formData: any) {
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.ADD_CUSTOMER}`;

    return this.http.post(url, formData);
  }

  updateVendor(formData: any) {
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.ADD_VENDOR}`;

    return this.http.post(url, formData);
  }

  updateCustomerBranch(formData: any) {
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.ADD_CUSTOMER}`;

    return this.http.post(url, formData);
  }

  updateVendorBranch(formData: any) {
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.ADD_VENDOR}`;

    return this.http.post(url, formData);
  }

  getUserDetail() {
    const url = `${environment.BASE_DOMAIN}${this.API_URL.CONFIG_URL.GET_USER_DETAILS}`;
    // const endpoint = "getUserList";
    return this.api.getMethod(url);
  }


  addUserDetails(formData: any) {
    // debugger
    const url = `${environment.BASE_DOMAIN}${this.API_URL.CONFIG_URL.ADD_USER_DETAILS}`;
    // const endpoint = `${environment.BASE_DOMAIN}addUser`;
    const requestData = {
      FirstName: formData.FirstName,
      LastName: formData.LastName,
      UserName: formData.UserName,
      Email: formData.Email,
      Contact: formData.Contact,
      Status: formData.Status,
      UserType: formData.UserType,
      OrganizationId: "0",
      RoleId: formData.RoleId,
      CreaterId: localStorage.getItem('userId')
    }
    return this.http.post(url, requestData);
  }

  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders({
      'VER': '1',
      'DEVICE-TYPE': 'Web'
    });
    headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const req = new HttpRequest('POST', `${environment.BASE_URL}${this.API_URL.CONFIG_URL.UPLOAD_CUSTOMER_MAPPING}`, formData, {
      headers,
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }


  sendMailToCustomer(startDate: any, endDate: any, custIds: any, docketNo: any) {
    // debugger
    console.log(startDate + endDate)
    let apiUrl = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.SEND_MIS_TO_CUSTOMER}`;
    let filter: any[] = [];
    // filter = [
    //   { "key": "DOCKET_NO", "value": docketNo }
    // ]
    // const requestData = {
    //   startDate: startDate,
    //   endDate: endDate,
    //   custIds: custIds
    // };

    if (docketNo?.length > 0) {
      filter = [
        { "key": "DOCKET_NO", "value": docketNo }
      ]
      // return this.http.post(apiUrl, filter);
    }

    else if (custIds && custIds != "") {
      filter = [
        { "key": "CUSTOMER_ID_IN", "value": custIds },
        { "key": "FROM_DATE", "dateValue": startDate },
        { "key": "TO_DATE", "dateValue": endDate },
      ]
      // return this.http.post(apiUrl, filter);
    }
    else {
      filter = [
        { "key": "FROM_DATE", "dateValue": startDate },
        { "key": "TO_DATE", "dateValue": endDate },
      ]
    }
    return this.http.post(apiUrl, filter);
  }

  uploadVendorFiles(formData: any, cutomersId: any, vendorsId: any, customerBranchesId: any, vendorBrachesId: any, toDate: any, fromDate: any, fileType: any) {
    const headers = new HttpHeaders();

    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.UPLOAD_VENDOR_FILE}?cutomersId=${cutomersId}&vendorsId=${vendorsId}&customerBranchesId=${customerBranchesId}&vendorBrachesId=${vendorBrachesId}&toDate=${toDate}&fromDate=${fromDate}&fileType=${fileType}&fileFor=Extension`;
    console.log(formData);
    return this.http.post(url, formData, { headers });
  }

  getVendorFiles(vendorId: number, page: number, size: number) {
    const url = `${environment.BASE_URL}${this.API_URL.CONFIG_URL.GET_VENDOR_FILE}?pageNumber=${page}&pageSize=${size}`
    let filter = [];
    filter = [{ "key": "VENDOR_ID", "value": vendorId }];
    return this.http.post(url, filter)
  }
}
