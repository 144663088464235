import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { environment } from 'src/environments/environment';
import { SnackbarService } from '../../services/snackbar.service';
import { AuthService } from '../../services/auth.service';
import { MasterService } from '../../services/master.service';
import { param } from 'jquery';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-edit-branch-details',
  templateUrl: './edit-branch-details.component.html',
  styleUrls: ['./edit-branch-details.component.css']
})
export class EditBranchDetailsComponent {
  editDialogForm: FormGroup | any;
  viewEdit: number = 1;
  enableEdit: boolean = false;
  setForm: FormGroup | any;

  loader: boolean = false;
  durationInSeconds = 3;

  pageIndex = 0;
  pageSize = 10;
  vendorData: any = [];
  customerData: any = [];
  vendorBranchDetails: any[] | any;
  customerBranchDetails: any[] | any;

  states: any[] = [];
  custId: any;
  vendorId: any;
  constructor(private dialog: MatDialogRef<EditBranchDetailsComponent>,
    private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient,
    private snackbarService: SnackbarService, private auth: AuthService, private masterService: MasterService,
    private route: ActivatedRoute) { }

  openSnackBar(message: string) {
    this.snackbarService.showSnackbar(message);
  }

  onEditBranch() {

  }

  closeDialog() {
    this.dialog.close();
    this.editDialogForm.reset();
  }

  onSave() {
    this.loader = true;
    // VENDOR-BRANCH
    if (this.data['component'] === 'vendorBranch') {

      this.setForm = this.formBuilder.group(this.data['vendorBranchForm'].setValue);

      const requestBody = {
        id: this.vendorId,
        branchDetailsList: [{
          id: this.setForm.value.id,
          vendorBranchName: this.setForm.value.vendorBranchName,
          vendorBranchAddress: this.editDialogForm.value.vendorBranchAddress,
          mobileNo: this.editDialogForm.value.mobileNo,
          // stateId: this.editDialogForm.value.stateId,
          stateMaster: {
            id: this.editDialogForm.value.stateId,
          },
          city: this.editDialogForm.value.city,
          pincode: this.editDialogForm.value.pincode,
          cwCode: this.editDialogForm.value.cwCode,
          panNo: this.editDialogForm.value.panNo,
          gstNo: this.editDialogForm.value.gstNo,
          status: this.editDialogForm.value.status,
        }]
      };

      this.masterService.updateVendorBranch(requestBody).subscribe(response => {
        if (response) {
          const snackbarMessage = 'Vendor Branch Edit Successfully'
          this.openSnackBar(snackbarMessage);
          this.loader = false;
          this.dialog.close();
        }
        else {
          const snackbarMessage = 'Error editing vendor branch'
          this.openSnackBar(snackbarMessage);
          this.loader = false;
          // this.dialog.close();
        }
      },
        (error) => {
          const snackbarMessage = 'Error editing vendor branch'
          this.openSnackBar(snackbarMessage);
          this.loader = false;
          this.dialog.close();
          console.error('Error editing vendor branch:', error);
        });
    }

    // CUSTOMER-BRANCH
    if (this.data['component'] === 'customerBranch') {

      this.setForm = this.formBuilder.group(this.data['customerBranchForm'].setValue);

      const requestBody = {
        id: this.custId,
        branchDetailsList: [
          {
            id: this.setForm.value.id,
            customerBranchName: this.setForm.value.customerBranchName,
            customerBranchAddress: this.editDialogForm.value.customerBranchAddress,
            mobileNo: this.editDialogForm.value.mobileNo,
            stateMaster: {
              id: this.editDialogForm.value.stateName,
            },
            city: this.editDialogForm.value.city,
            pincode: this.editDialogForm.value.pincode,
            // erpId: this.setForm.value.branchDetailsList.cwId,
            cwCode: this.editDialogForm.value.cwCode,
            panNo: this.editDialogForm.value.panNo,
            gstNo: this.editDialogForm.value.gstNo,
            status: this.editDialogForm.value.status,
          }
        ]
      };

      this.masterService.updateCustomerBranch(requestBody).subscribe(response => {
        if (response) {
          const snackbarMessage = 'Customer Branch Edit Successfully'
          this.openSnackBar(snackbarMessage);
          this.loader = false;
          this.dialog.close();
        }
        else {
          const snackbarMessage = 'Error editing customer branch'
          this.openSnackBar(snackbarMessage);
          this.loader = false;
          // this.dialog.close();
        }
      },
        (error) => {
          const snackbarMessage = 'Error editing customer branch'
          this.openSnackBar(snackbarMessage);
          this.loader = false;
          this.dialog.close();
          console.error('Error editing customer branch:', error);
        });
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      const custId = params['id'];
    })

    this.masterService.getStateDetails().subscribe((response: any) => {
      if (response && response.responseObject) {
        this.states = response?.responseObject?.filter((state: any) => state.id);
      }
    });

    //  VENDOR-BRANCH
    if (this.data['component'] === 'vendorBranch') {
      const vendorBranchDetails = this.data['vendorBranchForm'].setValue;
      this.states = this.data.states;
      this.vendorId = this.data.vendorId;

      const stateMasterId = vendorBranchDetails.stateMaster?.id;
      const state = this.states.find((s: any) => s.id === stateMasterId);
      const stateName = state ? state.stateName : '';

      this.editDialogForm = this.formBuilder.group({
        vendorBranchName:[vendorBranchDetails.vendorBranchName || ''],
        vendorBranchAddress: [vendorBranchDetails.vendorBranchAddress || ''],
        city:[vendorBranchDetails.city || ''],
        stateId: [vendorBranchDetails.stateMaster?.id],
        mobileNo: [vendorBranchDetails.mobileNo || ''],
        pincode: [vendorBranchDetails.pincode || ''],
        panNo: [vendorBranchDetails.panNo || ''],
        gstNo: [vendorBranchDetails.gstNo || ''],
        cwCode: [vendorBranchDetails.cwCode || ''],
        status: [vendorBranchDetails.status ]
      })
    }

    // CUSTOMER-BRANCH
    if (this.data['component'] === 'customerBranch') {
      const customerBranchFormValues = this.data['customerBranchForm'].setValue;
      this.states = this.data.states;
      const stateMasterId = customerBranchFormValues.stateMaster?.id;
      const state = this.states.find((s: any) => s.id === stateMasterId);
      const stateName = state ? state.stateName : '';
    
      this.custId = this.data.custId
      this.editDialogForm = this.formBuilder.group({
        customerBranchName: [customerBranchFormValues.customerBranchName || ''],
        customerBranchAddress: [customerBranchFormValues.customerBranchAddress || ''],
        city: [customerBranchFormValues.city || ''],
        stateName: [customerBranchFormValues.stateMaster?.id],
        mobileNo: [customerBranchFormValues.mobileNo || ''],
        pincode: [customerBranchFormValues.pincode || ''],
        panNo: [customerBranchFormValues.panNo || ''],
        gstNo: [customerBranchFormValues.gstNo || ''],
        cwCode: [customerBranchFormValues.cwCode || ''],
        status: [customerBranchFormValues.status ]
      });
    }
  }
}
