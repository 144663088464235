import { HttpClient, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileUploader } from 'ng2-file-upload';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ErrorHandlingService } from 'src/app/shared/services/error-handling.service';
import * as feather from 'feather-icons';
import { SnackbarComponent } from 'src/app/shared/component/snackbar/snackbar.component';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { param } from 'jquery';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-add-customer-files',
  templateUrl: './add-customer-files.component.html',
  styleUrls: ['./add-customer-files.component.css']
})
export class AddCustomerFilesComponent {
  durationInSeconds = 3;
  selectedFile: any;
  uploadForm: FormGroup | any;
  uploader: FileUploader | any;
  hasBaseDropZoneOver: any;
  hasAnotherDropZoneOver: any;
  response: any;
  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  loader: boolean = false;
  fileName = 'Select File';

  startDate: any;
  endDate: any;
  customStartDate: any | null = null;
  customEndDate: any | null = null;

  fromDateObj: any = {};
  toDateObj: any = {};
  customDate: any;

  vendorId: any;
  createdBy: any;
  constructor(private auth: AuthService, private http: HttpClient, private snackbarService: SnackbarService,
    private errorHandlingService: ErrorHandlingService, private cdr: ChangeDetectorRef, private datePipe: DatePipe,
    private route: ActivatedRoute) {
    feather.replace();
    this.uploadForm = new FormGroup({
      dateRange: new FormControl('', [Validators.required]),
      fileType: new FormControl('', [Validators.required]),
      fileUpload: new FormControl('', [Validators.required])
    });


  }

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }

  openSnackBar(message: string) {
    this.snackbarService.showSnackbar(message);
  }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.vendorId = + params['id'];
      // this.createdBy = + params['createdBy'];

    });
  }

  upload(): void {
    this.createdBy = localStorage.getItem('createdBy');
    // debugger
    this.progress = 0;
    this.loader = true;
    const formData: FormData = new FormData();
    // Append form values to FormData
    formData.append('filesFor', this.uploadForm.value.fileType);
    formData.append('vendorId', this.vendorId); // Replace with appropriate value
    formData.append('createdBy', this.createdBy); // Replace with appropriate value
    console.log("upload dataaaaaaaaaaaaaa" + formData)

    if (this.selectedFiles && this.uploadForm.valid) {

      // Array.from(this.selectedFiles).forEach((file: File) => {
      //   this.auth.uploadVendorFile({ file: file, formData: formData }).subscribe({
      //     next: (event: any) => {
      //       console.log(event);
      //       if (event instanceof HttpResponse) {
      //         this.loader = false;
      //         const snackbarMessage = 'File Uploaded Successfully';
      //         this.openSnackBar(snackbarMessage);
      //         this.message = event.body.message;
      //       }
      //       this.cdr.detectChanges();
      //     },
      //     error: (err: any) => {
      //       console.log(err);
      //       const snackbarMessage = 'File Upload Failed';
      //       this.openSnackBar(snackbarMessage);
      //       if (err.error && err.error.message) {
      //         this.message = err.error.message;
      //       } else {
      //         this.message = 'Could not upload the file!';
      //       }
      //       this.loader = false;
      //       this.currentFile = undefined;
      //     },
      //   });
      // });
    }
  }



  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  ngAfterViewInit() {

  }

  dateOptionsList = [
    {
      viewValue: 'Today',
      value: 'today'
    },
    {
      viewValue: 'Yesterday',
      value: 'yesterday'
    },
    {
      viewValue: 'Last 7 days',
      value: 'last7days'
    },
    {
      viewValue: 'Last 30 days',
      value: 'last30days'
    },
    {
      viewValue: 'This month',
      value: 'thismonth'
    },
    {
      viewValue: 'Last month',
      value: 'lastmonth'
    }
  ];

  /**@description Method To Autofill Dates*/
  public onChangeCondition(dateKey: any) {
    console.log('Selected value:', dateKey);

    let fromDate: any;
    let toDate: any;
    // this.filterOptionsList.filter(x => x.value === filterCondition)[0].customDates = {};
    switch (dateKey) {
      case 'today':
        fromDate = new Date();
        toDate = new Date(); // Assign current date to both fromDate and toDate
        break;
      case 'yesterday':
        fromDate = new Date(
          moment()
            .subtract(1, 'days')
            .startOf('day')
            .valueOf()
        );
        toDate = new Date(
          moment()
            .subtract(1, 'days')
            .startOf('day')
            .valueOf()
        );
        break;
      case 'last7days':
        fromDate = new Date(
          moment()
            .subtract(6, 'days')
            .startOf('day')
            .valueOf()
        );
        toDate = new Date();
        break;
      case 'last30days':
        fromDate = new Date(
          moment()
            .subtract(29, 'days')
            .startOf('day')
            .valueOf()
        );
        toDate = new Date();
        break;
      case 'thismonth':
        fromDate = new Date(
          moment()
            .startOf('month')
            .valueOf()
        );
        toDate = new Date();
        break;
      case 'lastmonth':
        fromDate = new Date(
          moment()
            .subtract(1, 'months')
            .startOf('month')
            .valueOf()
        );
        toDate = new Date(
          moment()
            .subtract(1, 'months')
            .endOf('month')
            .valueOf()
        );
        break;

      case 'custom': // Added case for custom date range
        // Call handleCustomDateRange
        this.handleCustomDateRange(this.startDate, this.endDate);
        return;
    }

    // Transform dates to the desired format
    const FROM_DATE = this.datePipe.transform(fromDate, 'dd-MM-yyyy Z', '+0530');
    const TO_DATE = this.datePipe.transform(toDate, 'dd-MM-yyyy Z', '+0530');

    // Update fromDateObj and toDateObj
    this.fromDateObj = FROM_DATE;
    this.toDateObj = TO_DATE;

    // Call handleCustomDateRange to handle custom date range
    this.handleCustomDateRange(fromDate, toDate);

    console.log("FROM_DATE", FROM_DATE, "TO_DATE", TO_DATE);
    console.log("FROM_DATE", this.fromDateObj, "TO_DATE", this.toDateObj);
  }

  handleCustomDateRange(startDate: any, endDate: any) {
    // Set custom start and end dates
    this.customStartDate = startDate;
    this.customEndDate = endDate;

    console.log(this.customStartDate, this.customEndDate)
    // Call getDocketData with formatted dates
    // this.getDocketData(this.pageIndex, this.pageSize);
  }


  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy') || '';
  }


}
