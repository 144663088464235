<!-- Main Wrapper -->
<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="content">
            <div class="page-header">
                <div class="page-title">
                    <span [routerLink]="['/journey-management/customer-mapping']" class="arrow">
						<img src="../../../../assets/img/icons/left-arrow.svg" class="arrow-img" />
						<h4 >Upload Customer Mapping</h4>
					</span>
                </div>
            </div>

            <!-- /product list -->
            <div class="card">
                <div class="card-body">
                    <div class="requiredfield">
                        <h4>Field must be in .xlsx format</h4>
                    </div>
                    <form [formGroup]="uploadForm">
                        <div *ngIf="loader">
                            <div class=" loader">
                                <mat-spinner [diameter]="25" [strokeWidth]="2" class="centre-align"></mat-spinner>
                            </div>
                        </div>
                        <div class="row">
                            
                                <div class="col-lg-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="file">
                                            Upload Xlsx File
                                            <div class="image-upload mt-1">
                                                <div class="image-uploads">
                                                    <img src="../../../../assets/img/icons/upload.svg" alt="img">
                                                    <h4>Drag and drop a file to upload</h4>
                                                    <input #fileUpload type="file" formControlName="fileUpload"
                                                        (change)="selectFile($event)" [multiple]="true" accept=".xlsx">
                                                    <div *ngIf="uploadForm.get('fileUpload').hasError('required') && uploadForm.get('fileUpload').touched"
                                                        class="error-message text-danger">&nbsp;File upload is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>

                            <div class="col-lg-12">
                                <div class="form-group mb-0">
                                    <a href="javascript: void(0);" id="alert-success"
                                        class="btn btn-submit ms-2"
                                        (click)="upload()">Submit</a>
                                    <a href="javascript:void(0);" class="btn btn-cancel ms-2"
                                        routerLink="/journey-management/customer-mapping">Cancel</a>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>