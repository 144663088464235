import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MasterRoutingModule } from '../../master-routing.module';
import * as feather from 'feather-icons';
// import { saveAs } from 'file-saver';
import { PageEvent } from '@angular/material/paginator';
import { MasterService } from 'src/app/shared/services/master.service';

@Component({
  selector: 'app-vendor-files',
  templateUrl: './vendor-files.component.html',
  styleUrls: ['./vendor-files.component.css']
})
export class VendorFilesComponent {
isDuplicate(arg0: any) {
throw new Error('Method not implemented.');
}
  loader: boolean = false;
  activeClass: boolean = false;
  orderData: any = [];
  showFilter: any;

  length = 50;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = false;
  showFirstLastButtons = false;
  disabled = false;

  pageEvent: any;

  vendorFile: any = [];
  vendorId: any;
  file: any
  totalCount: number = 0;

  allChecked: boolean = false;

  //Customer
  customerFilter: any = [];
  customerData: any = [];

  //Vendor
  vendorData: any = [];
  vendorFilter: any = [];

  totalRecords: any;
  constructor(public dialog: MatDialog, private renderer: Renderer2, private auth: AuthService, private datePipe: DatePipe,
    private route: ActivatedRoute, private fb: FormBuilder, private http: HttpClient, private router: MasterRoutingModule,
    private masterService: MasterService) {
    feather.replace();

  }

  filterForm = new FormGroup({
    vendorBranchName: new FormControl(''),
    customerName: new FormControl(''),
    customerBranchName: new FormControl(''),
    dateRange: new FormControl('')
  });

  ngOnInit(): void {
    this.vendorData = JSON.parse(localStorage.getItem('vendor')!);
    this.customerData = JSON.parse(localStorage.getItem('customer')!);
    this.route.params.subscribe(params => {
      this.vendorId = +params['id'];
      this.getVendorFiles();
    });

    // this.getCustomer(this.pageIndex, this.pageSize);
    // this.getVendor(this.pageIndex, this.pageSize);
  }

  replaceURL(url: any): string {
    const basePath = "/var/www/html/asc/";
    if (url.includes(basePath)) {
      return url.replace(basePath, "");
    }
    return url;
  }

  replaceFileName(url:any):string{
    const basePath = "/var/www/html/asc/file-uploads/vendors/";
    if(url.includes(basePath)){
      return url.replace(basePath,"");
    }
    return url;
  }

  getVendorFiles(): void {

    this.loader = true;
    this.masterService.getVendorFiles(this.vendorId, this.pageIndex, this.pageSize)
      .subscribe((data: any) => {
        if (data != null && data?.responseObject != null) {
          this.vendorFile = data?.responseObject?.content || [];
          this.totalRecords = data?.responseObject?.totalElements;
          this.vendorFile.sort((a: any, b: any) => {
            const dateA = new Date(a.fromDate).getTime();
            const dateB = new Date(b.fromDate).getTime();
            return dateB - dateA;
          });

          // Vendor-branch
          this.vendorFile.forEach((el:any)=>{
            el['vendorName'] = []
            if(el.vendorsBranchId){
              el.vendorsBranchId = el.vendorsBranchId.split(',')
            }
          })
          this.vendorFile.forEach((el:any)=>{
            this.vendorData.forEach((vData:any)=>{
              if(el.vendorId == vData.id){
                if(vData?.branchDetailsList){
                  vData.branchDetailsList.forEach((bdList:any)=>{
                    if(el.vendorsBranchId.length>0){
                      el.vendorsBranchId.forEach((data:any)=>{
                        if(bdList.id == data){
                          el.vendorName.push(bdList.vendorBranchName)
                        }
                      })
                    }
                  })
                }
              }
            });
            if (el.vendorName.length === 0) {
              el.vendorName = ['All'];
            }
          });

          // Customer
          this.vendorFile.forEach((cl:any) => {
            console.log(this.customerData)
            this.customerData.forEach((cData:any) =>{
             if(cData.id == cl.customersId){
              cl['customerName'] = cData.customerName
             }
            })
          });

          // Customer-Branch
          this.vendorFile.forEach((cb:any) => {
            cb['customerBranchName'] = []
            if(cb.customerBranchsId){
              cb.customerBranchsId = cb.customerBranchsId.split(',')
            }
          });
          this.vendorFile.forEach((cl:any) => {
            console.log(this.customerData)
            this.customerData.forEach((cData:any) =>{
             if(cData.id == cl.customersId){
              if(cData?.branchDetailsList){
                cData.branchDetailsList.forEach((cbList:any) => {
                  if(cl.customerBranchsId.length > 0){
                    cl.customerBranchsId.forEach((data:any) => {
                      if(cbList.id == data){
                        cl.customerBranchName.push(cbList.customerBranchName)
                      }
                    })
                  }
                })
              }
              // cl['customerName'] = cData.customerName
             }
            });
            if (cl.customerBranchName.length === 0) {
              cl.customerBranchName = ['All'];
            }
          });
          
        }
        this.loader = false
      }, error => {
        // Handle error here
        console.error('Error fetching files:', error);
      });
  }


  pageNavigations(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.allChecked = false;
    this.getVendorFiles();
  }
  sortOrder: 'asc' | 'desc' = 'asc';

  toggleSortOrder() {
    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    this.sortByDate(this.sortOrder);
  }

  sortByDate(order: 'asc' | 'desc' = 'asc') {
    this.vendorFile.sort((a: any, b: any) => {
      const dateA = new Date(a.fromDate).getTime();
      const dateB = new Date(b.fromDate).getTime();
      if (order === 'asc') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });
  }


}
